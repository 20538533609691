import { Cell } from "react-table";
import { ContractManageList } from "src/api/contract/contract-types";
import { BaseTooltip } from "src/components";
import { BaseContractStatus } from "src/components/BaseContractStatus";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import ProductTypeChangeLabel from "src/components/ProductTypeChangeLabel";
import { PagePath } from "src/pages/product/details";

export const rvContractColumns: any = [
  {
    Header: "신청/계약 id",
    accessor: "contractId",
    width: 100,
    Cell: ({ value }: Cell<ContractManageList>) => <BaseNewTabLink path={PagePath.contract.detail.replace(":id", value)} value={value} />,
  },
  {
    Header: "상태",
    accessor: "contractStep",
    width: 80,
    Cell: ({ value }: any) => {
      return <BaseContractStatus status={value} />;
    },
  },
  {
    Header: "계약명",
    accessor: "contractName",
    width: 240,
    Cell: ({ value }: Cell<ContractManageList>) => {
      return (
        <div className="w-100">
          <BaseTooltip contents={value || "-"}></BaseTooltip>
        </div>
      );
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 200,
    Cell: ({ value }: Cell<ContractManageList>) => {
      return (
        <div className="w-100">
          <BaseTooltip contents={value || "-"}></BaseTooltip>
        </div>
      );
    },
  },
  {
    Header: "상품유형",
    accessor: "spaceProductType",
    width: 80,
    Cell: ({ value }: Cell<ContractManageList>) => {
      return <ProductTypeChangeLabel productType={value} />;
    },
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 190,
    Cell: (props: Cell<ContractManageList>) => (
      <div className="w-100">
        <BaseTooltip contents={props.value ? props.value : "-"}></BaseTooltip>
      </div>
    ),
  },
];
