import { ko } from "date-fns/locale";
import React, { forwardRef, useCallback, useRef } from "react";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface BaseDatePickerState {
  selectedDate?: Date | null;
  setDate: Function;
  type?: "full" | "date" | "month" | "time" | "";
  className?: string;
  disabled?: boolean;
  errorText?: string;
  timeIntervals?: number;
  data?: any;
  setData?: Function;
  name?: string;
  validation?: boolean;
  readonly?: boolean;
  placeholder?: string;
  filteredTime?: Date | undefined;
  useFilterdTime?: boolean;
  excludeTimes?: any[];
  maxDate?: Date;
  minDate?: Date;
  filterdReverse?: Boolean;
  dateFormat?: string;
  icon?: boolean;
  filterTime?: (time: Date) => boolean;
  injectTimes?: Date[];
  minTime?: Date;
  maxTime?: Date;
  nonMarkedDate?: boolean;
}

export const BaseDatePicker = (props: BaseDatePickerState) => {
  const getDateString = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = ("0" + (date && date.getMonth() + 1)).slice(-2);
    const day = ("0" + date?.getDate()).slice(-2);

    let selectedDate = `${year}${month}${day}`;

    return selectedDate;
  };
  const getDateTimeString = (date: Date | null) => {
    let selectedDate = date?.toISOString;
    return selectedDate;
  };

  const getTimeValue = useCallback(
    (date: Date | null) => {
      props.setDate(date);
    },
    [props],
  );

  const getDateValue = useCallback(
    (date: Date | null) => {
      props.setDate(date);
      const obj = { ...props.data };
      obj.completionYmd = getDateString(date);
      if (props.setData) {
        props.setData(obj);
      }
    },
    [props],
  );

  const getFullDateTimeValue = useCallback(
    (date: Date | null) => {
      props.setDate(date);
      // props.setDate(getDateTimeString(date))
    },
    [props],
  );

  const CalendarContainer: React.FC = ({ children }: any) => {
    const el = document.getElementById("calendar");

    if (el === null) return null;

    return createPortal(<>{children}</>, el!);
  };

  const filterPassedTime = (time: Date) => {
    let currentDate = new Date();
    if (props.filteredTime) {
      currentDate = new Date(props.filteredTime);
    }
    const selectedDate = new Date(time);

    let flag = true;
    if (props.useFilterdTime && (props.timeIntervals === undefined || Number(props.timeIntervals) === 10)) {
      // console.log("여기 탄다 10");

      // console.log("currentDate", currentDate, "selectedDate", selectedDate);
      if (selectedDate.getDate() === new Date().getDate()) {
        if (currentDate.getTime() > selectedDate.getTime()) {
          flag = false;
        } else {
          flag = true;
        }
      }
    }

    if (Number(props.timeIntervals) === 30 && props.useFilterdTime) {
      if (selectedDate.getDate() === new Date().getDate()) {
        if (new Date().getTime() >= selectedDate.getTime()) {
          flag = false;
        } else {
          flag = true;
        }
      }
    }
    if (props.filterdReverse) {
      flag = !flag;
    }

    return flag;
  };

  const DatePinkerInput = forwardRef<HTMLInputElement, any>(({ value, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(ref as any);

    const handleClickIcon = () => inputRef.current?.focus();

    return (
      <div className={`datepicker-input`}>
        <input value={value} {...props} disabled={props.disabled ? true : false} ref={inputRef} />
        <div className="icon" onClick={handleClickIcon} />
      </div>
    );
  });

  return (
    <>
      {props?.type === "full" ? (
        <>
          <div className={`base-input  ${props.className ? props.className : ""}`}>
            <DatePicker
              selected={props.selectedDate}
              closeOnScroll={true}
              onChange={(date: Date) => getFullDateTimeValue(date)}
              locale={ko}
              dateFormat="yyyy.MM.dd aa h:mm"
              showPopperArrow={false}
              // calendarClassName="base-datepicker full-datepicker "
              calendarClassName={`base-datepicker full-datepicker ${props.nonMarkedDate ? "end-date-picker" : ""}`} // v1,21 harry 수정 > 추후 필요할수도
              placeholderText={props.placeholder ? props.placeholder : "날짜와 시간을 선택해주세요"}
              showTimeSelect
              filterTime={props.useFilterdTime ? filterPassedTime : undefined}
              excludeTimes={props.excludeTimes ? props.excludeTimes : []}
              maxDate={props.maxDate}
              minDate={props.minDate}
              minTime={props.minTime}
              maxTime={props.maxTime}
              disabled={props.disabled}
              readOnly={props.readonly ? true : false}
              popperContainer={CalendarContainer}
            />

            {props.errorText && !props.selectedDate && <p className="validation-text">{props.errorText}</p>}
          </div>
        </>
      ) : props?.type === "time" ? (
        <>
          <div className={`base-input ${props.className ? props.className : ""}`}>
            <DatePicker
              selected={props.selectedDate}
              onChange={(date) => getTimeValue(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={props.timeIntervals ? props.timeIntervals : 10}
              timeCaption="Time"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              disabled={props.disabled}
              filterTime={props.filterTime ? props.filterTime : filterPassedTime}
              excludeTimes={props.excludeTimes}
              calendarClassName="base-datepicker "
              placeholderText={props.placeholder ? props.placeholder : "시간을 선택해주세요"}
              readOnly={props.readonly ? true : false}
              popperContainer={CalendarContainer}
              injectTimes={props.injectTimes}
              minTime={props.minTime}
              maxTime={props.minTime && moment().set({ hour: 23, minute: 59 }).toDate()}
            />
            {props.errorText && !props.selectedDate && <p className="validation-text">{props.errorText}</p>}
          </div>
        </>
      ) : props?.type === "month" ? (
        <>
          <div className={`base-input ${props.className ? props.className : ""} `}>
            <DatePicker
              selected={props.selectedDate}
              closeOnScroll={true}
              onChange={(date: Date) => getDateValue(date)}
              locale={ko}
              // dateFormat={props.dateFormat ? props.dateFormat : "yyyy.MM.dd"}
              dateFormat={props.dateFormat ? props.dateFormat : "yyyy.MM"}
              showPopperArrow={false}
              calendarClassName="base-datepicker"
              placeholderText={props.placeholder ? props.placeholder : "날짜를 선택해주세요"}
              readOnly={props.readonly ? true : false}
              maxDate={props.maxDate}
              minDate={props.minDate}
              disabled={props.disabled}
              popperContainer={CalendarContainer}
              showMonthYearPicker
              showFullMonthYearPicker
              showFourColumnMonthYearPicker
            />
            {props.errorText && !props.selectedDate && <p className="validation-text">{props.errorText}</p>}
          </div>
        </>
      ) : (
        <>
          <div className={` ${props.icon ? "" : "base-input"} ${props.className ? props.className : ""}`}>
            <DatePicker
              selected={props.selectedDate}
              closeOnScroll={true}
              onChange={(date: Date) => getDateValue(date)}
              locale={ko}
              dateFormat={props.dateFormat ? props.dateFormat : "yyyy.MM.dd"}
              showPopperArrow={false}
              calendarClassName="base-datepicker"
              placeholderText={props.placeholder ? props.placeholder : "날짜를 선택해주세요"}
              readOnly={props.readonly ? true : false}
              maxDate={props.maxDate}
              minDate={props.minDate}
              popperContainer={CalendarContainer}
              disabled={props.disabled}
              customInput={props.icon ? <DatePinkerInput /> : null}
            />
            {props.errorText && !props.selectedDate && <p className="validation-text">{props.errorText}</p>}
          </div>
        </>
      )}
    </>
  );
};
