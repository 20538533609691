import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import {
  Ac2ProductAccessGroupResponse,
  ProductAccessPageQueryParams,
  ProductAccessUpdateData,
  ProductAccessResponseData,
  ProductAccessGroupModel,
  Ac2ProductAccessGroupModel,
} from "./productac-types";

// 공간상품 출입그룹 상세 목록
export async function getProductAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    productId: number;
  },
): Promise<AxiosResponse<ApiResponse<ProductAccessResponseData>>> {
  if (!params?.productId) {
    throw Error("Please check productId");
  }
  return await axios.get(`/api/ctrl/partners/{partnerId}/pr/products/${params.productId}/productAccessGroup`, { params });
}

// 출입그룹 등록
export async function postProductAccessAsync(
  axios: AxiosInstance,
  params?: ProductAccessUpdateData,
): Promise<AxiosResponse<ApiResponse<{ content: ProductAccessGroupModel }>>> {
  return axios.post(`/api/ctrl/partners/{partnerId}/pr/products/${params?.productId}/productAccessGroup`, { accessGroupId: params?.accessGroupId });
}

// RAD21. 출입그룹 페이지 조회 (출입그룹 추가 팝업)

export async function getAccessGroupListAsync(
  axios: AxiosInstance,
  params?: ProductAccessPageQueryParams,
): Promise<AxiosResponse<ApiResponse<Ac2ProductAccessGroupResponse>>> {
  return axios.get(`/api/ctrl/partners/{partnerId}/ac2/access-groups`, { params });
}

// //RAD22. 출입그룹 상세 조회
// export async function getAccessGroupDetailAsync(
//   axios: AxiosInstance,
//   params?: {
//     id: number;
//   },
// ): Promise<AxiosResponse<ApiResponse<{ accessGroup: Ac2ProductAccessGroupModel }>>> {
//   return axios.get(`/api/ctrl/partners/{partnerId}/ac2/access-groups/${params?.id}`, { params });
// }

// RPP21. 공간상품 출입그룹 삭제

export async function deleteProductAccessAsync(
  axios: AxiosInstance,
  params?: {
    ids: string[];
  },
) {
  return axios.delete(`/api/ctrl/partners/{partnerId}/pr/products/productAccessGroup`, { data: { ids: params?.ids } });
}

// RAD11. 디바이스 페이지 조회
export async function getAccessGroupDetailAsync(
  axios: AxiosInstance,
  params?: {
    ids?: string;
    accessGroupId?: string;
    includeDeviceStatusInfo?: boolean;
    cacheable?: boolean;
  },
): Promise<AxiosResponse<ApiResponse<Ac2ProductAccessGroupResponse>>> {
  return axios.get(`/api/ctrl/partners/{partnerId}/ac2/devices`, { params });
}
