import { useEffect, useRef, useState } from "react";
import { getEslTempProductDetailAsync } from "src/api/esl/esl-api";
import { useApiOperation } from "src/api/hooks";
import { BaseTooltip } from "src/components";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";

const Esl = ({ row }: any) => {
  const { executeAsync: getEslTempProductDetail } = useApiOperation(getEslTempProductDetailAsync, {
    noHandleError: true,
    noLoading: true,
  });
  const [labelCode, setLabelCode] = useState<string | undefined>();
  const target = useRef(null);

  const { observe } = useIntersectionObserver(
    async () => {
      const locationCode = row?.original?.locationCode;

      if (locationCode) {
        const result = await getEslTempProductDetail({ productId: locationCode, serverId: "1" });
        const assignedLabelCode = result?.data?.data?.product?.assignedLabelCodes?.join(",");

        if (assignedLabelCode) {
          setLabelCode(assignedLabelCode);
        }
      }
    },
    { threshold: 0.3 }, //0.3 퍼센트만 노출되면 콜백 실행
  );

  useEffect(() => {
    observe(target.current);
  }, []);

  return (
    <div className="ellipsis2" ref={target}>
      <BaseTooltip contents={labelCode || "-"} />
    </div>
  );
};

export default Esl;
