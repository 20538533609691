import moment from "moment";
import { reservationColumns } from "./list/columns";

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

// 공용공간 예약 status 한글 변환
export const getStatusText = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

// 공용공간 예약 status 한글 변환
export const getReservationStatusDetail = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

// 예약 경로 타입
// export const ReservationStatus = {
//   ENABLED: "RESERVATION_ENABLED", // 공개
//   CANCELED: "RESERVATION_CANCELED", // 관리자가 취소
//   EXPIRED: "RESERVATION_EXPIRED", // 예약취소
//   INCOMPLETED_FACILITY: "RESERVATION_INCOMPLETED_FACILITY", // 불완전예약-회의실사용불가
//   INCOMPLETED_QUOTA_DAYLY: "RESERVATION_INCOMPLETED_QUOTA_DAYLY", //불완전예약-일사용시간초과
//   INCOMPLETED_QUOTA_MONTHLY: "RESERVATION_INCOMPLETED_QUOTA_MONTHLY", //불완전예약-월사용시간초과
// } as const;

export const ReservationState = {
  ACCEPTED: "ACCEPTED", // 접수
  ACKNOWLEDGED: "ACKNOWLEDGED", // 점유
  INCOMPLETE: "INCOMPLETE", // 불완전
  CANCELLED: "CANCELLED", // 취소
} as const;

export type UnionReservationStatus = (typeof ReservationState)[keyof typeof ReservationState];

// 공간예약 목록 조회하는 모든 상태
export const SEARCH_STATUSES = [ReservationState.ACCEPTED, ReservationState.ACKNOWLEDGED, ReservationState.INCOMPLETE, ReservationState.CANCELLED];

export type ReservationExternalTypes = "TAAP" | "GC" | "OC";

// 예약 경로 타입
export const ExternalServiceType = {
  EXTERNALSERVICE_UNRECOGNIZED: "EXTERNALSERVICE_UNRECOGNIZED", //정의되지 않은 타입
  TAAP: "TAAP",
  GC: "GC", // Google Calendar
  OC: "OC", //Outlook Calendar
  CTRL_ROOM: "CTRL_ROOM",
  TAAP_SPACE: "TAAP_SPACE",
} as const;

export type UnionExternalServiceType = (typeof ExternalServiceType)[keyof typeof ExternalServiceType];

const USER_TYPES = {
  SYSTEM: "SYSTEM",
  TAAP: "TAAP",
  CENTRAL: "CENTRAL",
  CTRL_ROOM: "CTRL_ROOM",
  CALENDAR: "CALENDAR",
  TAAP_SPACE: "TAAP_SPACE",
} as const;

// Union 타입
export type UserType = (typeof USER_TYPES)[keyof typeof USER_TYPES];

//  예약초대 상태
export const ReservationAttendeeResponseStatus = {
  NONE: "NONE", //응답없음
  DECLINED: "DECLINED", //거부
  TENTATIVE: "TENTATIVE", // 임시적 수락
  ACCEPTED: "ACCEPTED", //수락
};

// 공용공간예약 목록 엑셀 다운로드 헤더
export const reservationHeaders = [
  { label: "id", key: "id" },
  { label: "group id", key: "reservationGroupId" },
  { label: "상품 id", key: "productId" },
  { label: "신청/계약 번호", key: "contractApplyNumber" },
  { label: "예약경로", key: "externalServiceType" },
  { label: "건물명", key: "buildingName" },
  { label: "위치 (층)", key: "buildingCommonFacilityFloorNum" },
  { label: "위치 (지상여부)", key: "buildingCommonFacilityIsGround" },
  { label: "위치 (id)", key: "buildingCommonFacilityId" },
  { label: "위치 (LocationCode)", key: "buildingCommonFacilityLocationCode" },
  { label: "공간타입", key: "commonFacilityType" },
  { label: "공간명", key: "buildingCommonFacilityName" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "memberNo" },
  { label: "예약자 (이메일)", key: "memberEmail" },
  { label: "예약명", key: "summary" },
  { label: "생성일", key: "createdDate" },
  { label: "상태", key: "status" },
];

// 공용공간예약 소진시간 엑셀 다운로드 헤더
export const reservationUsageHeaders = [
  { label: "id", key: "reservationId" },
  { label: "신청/계약 번호", key: "contract.applyNumber" },
  { label: "건물명", key: "facility.building.name" },
  { label: "공간타입", key: "facility.type" },
  { label: "공간id", key: "facility.id" },
  { label: "공간명", key: "facility.name" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "소진시간", key: "duration" },
  { label: "예약자 (회원번호)", key: "organizer.memberNo" },
  { label: "예약자 (휴대폰번호)", key: "organizer.phoneNumber" },
  { label: "예약자 (이메일)", key: "organizer.userEmail" },
];

// 검색 대상
export const reservationSearchTypes = [
  { value: "ALL", label: "전체" },
  { value: "containsFacilityName", label: "공간 명" },
  { value: "containsBuildingName", label: "건물 명" },
  { value: "containsOrganizerMemberNo", label: "회원번호" },
  { value: "id", label: "예약 ID" },
  { value: "parentId", label: "그룹 ID" },
  { value: "startsWithOrganizerDisplayName", label: "주최자 닉네임" },
  { value: "startsWithOrganizerUserEmail", label: "주최자 이메일" },
  { value: "startsWithSummary", label: "예약명" },
];

export const reservationSearchTypeKey = [
  //검색조건
  "id",
  "parentId",
  "containsFacilityName",
  "containsBuildingName",
  "containsOrganizerMemberNo",
  "containsContractApplyNumber",
  "startsWithOrganizerDisplayName",
  "startsWithOrganizerUserEmail",
  "startsWithSummary",
];

// 공용공간 유형
export const reservationFacilityType = [
  { value: "", label: "전체" },
  { value: "MEETING_ROOM", label: "회의실" },
  { value: "DESK", label: "좌석" },
  { value: "REFRESH_ROOM", label: "편의시설" },
];

// 상태 - 예약구분이 정상일 경우
export const reservationStatuses = [
  { value: "", label: "전체" },
  { value: ReservationState.ACCEPTED, label: "접수" },
  { value: ReservationState.ACKNOWLEDGED, label: "점유" },
  { value: ReservationState.INCOMPLETE, label: "불완전" },
  { value: ReservationState.CANCELLED, label: "취소" },
];

export const inboundChannelType = {
  [ExternalServiceType.TAAP]: "Taap",
  [ExternalServiceType.GC]: "Google Calendar",
  [ExternalServiceType.OC]: "Outlook Calendar",
  [ExternalServiceType.TAAP_SPACE]: "TaapSPACE",
  [ExternalServiceType.CTRL_ROOM]: "Ctrl.room",
} as const;

// 상태
export const reservationInboundChannel = [
  { value: "", label: "전체" },
  { value: ExternalServiceType.TAAP, label: inboundChannelType[ExternalServiceType.TAAP] },
  { value: ExternalServiceType.GC, label: inboundChannelType[ExternalServiceType.GC] },
  { value: ExternalServiceType.OC, label: inboundChannelType[ExternalServiceType.OC] },
  { value: ExternalServiceType.TAAP_SPACE, label: inboundChannelType[ExternalServiceType.TAAP_SPACE] },
  { value: ExternalServiceType.CTRL_ROOM, label: inboundChannelType[ExternalServiceType.CTRL_ROOM] },
];
