import { useState } from "react";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { Tab } from "src/components";
import { DeskSpace, facilitySubTabs } from "src/pages/building/building-types";
import { useModal } from "src/recoil/modalState/hook";
import FacilityAccessGroupForm from "../components/FacilityAccessGroupForm";
import DeskBasicInfo from "./DeskBasicInfo";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";

type Props = {
  building?: BuildingModel;
  onClose: () => void;
  handleConfirm: (passData?: CommonFacilityModel) => void;
  facility?: DeskSpace;
  readonly?: boolean;
  deskSpaces: Array<DeskSpace>;
  openDeskGroupFormModal: (facility?: CommonFacilityModel) => void;
  isAccessFeature: boolean;
  activeTab: Tab;
  onChangeTab: (tab: Tab) => void;
  deskGroupId?: number;
};

const DeskFormModal = ({
  building,
  onClose,
  handleConfirm,
  facility,
  readonly,
  deskSpaces,
  openDeskGroupFormModal,
  isAccessFeature,
  activeTab,
  onChangeTab,
  deskGroupId,
}: Props) => {
  // 좌석별 이미지
  const [isReadOnly, setIsReadOnly] = useState<boolean>(readonly || false);
  const { setBaseModal } = useModal();

  return (
    <BaseAbstractModal isOpen={true}>
      <section className="base-abstract-modal__contents">
        <div className="base-abstract-modal__title">
          <h1>{`좌석 ${isReadOnly ? "" : facility?.deskId ? `수정` : "등록"}`}</h1>
        </div>
        <div className="px30">
          <div className="contents-title__tab border-bottom-gray">
            {facilitySubTabs
              .filter((tab: Tab, idx) => (isAccessFeature ? true : idx === 0))
              .map((tab: Tab, i: number) => {
                return (
                  <div
                    key={i}
                    className={`${activeTab?.value === tab.value ? "active" : ""}`}
                    onClick={() => {
                      if (!facility) {
                        setBaseModal({
                          isOpen: true,
                          title: "기본정보를 먼저 작성해주세요.",
                          children: <p>기본 정보를 입력해야 출입그룹으로 이동할 수 있습니다.</p>,
                          onClick: () => setBaseModal({ isOpen: false }),
                          btnRightTitle: "확인",
                        });
                      } else {
                        onChangeTab(tab);
                      }
                    }}
                    onMouseUp={(event) => {
                      if (event.button === 1) {
                        // 마우스의 휠 버튼 클릭 여부 확인
                        event.currentTarget.click();
                      }
                    }}
                  >
                    <span>{tab.label}</span>
                  </div>
                );
              })}
          </div>

          <h2 className="font18 font-weight-semibold my30">{activeTab.value === "basicInfo" ? "기본정보" : "출입그룹"}</h2>
        </div>

        {activeTab.value === "basicInfo" && (
          <DeskBasicInfo
            building={building} //
            onClose={onClose}
            handleConfirm={handleConfirm}
            facility={facility}
            readonly={isReadOnly}
            onChageReadOnly={() => setIsReadOnly(!isReadOnly)}
            deskSpaces={deskSpaces || []}
            openDeskGroupFormModal={openDeskGroupFormModal}
            deskGroupId={deskGroupId}
          />
        )}
        {activeTab.value === "accessGroup" && (
          <FacilityAccessGroupForm
            onClose={onClose} //
            readonly={isReadOnly}
            onChageReadOnly={() => setIsReadOnly(!isReadOnly)}
            facilityId={String(facility?.deskId)}
          />
        )}
      </section>
    </BaseAbstractModal>
  );
};

export default DeskFormModal;
