import { Dispatch, SetStateAction } from "react";
import { Cell } from "react-table";
import {
  BuildingDeviceControl,
  ContractDeviceControl,
  DeviceControlMethod,
  DeviceControlParams,
  UnionDeviceControlMethod,
} from "src/api/iot/iot-types";
import { BaseButton, BaseTooltip } from "src/components";
import BaseRichTooltip from "src/components/BaseRichTooltip";
import { UnionPartnerPrivilegeCode } from "src/types/partner";

const DeviceControlColumns = [
  {
    Header: "id",
    accessor: "control.id",
    width: 70,
  },
  {
    Header: "제어 구분",
    accessor: "control.method",
    width: 100,
    Cell: ({ value }: Cell<BuildingDeviceControl>) => {
      let method = "";
      switch (value as UnionDeviceControlMethod) {
        case DeviceControlMethod.READ_STATUS: {
          method = "상태 조회";
          break;
        }
        case DeviceControlMethod.EXEC_COMMANDS: {
          method = "명령";
          break;
        }
      }
      return <span>{method}</span>;
    },
  },
  {
    Header: "제어명",
    accessor: "control.displayName",
    width: 130,
    Cell: ({ row, value }: Cell<BuildingDeviceControl>) => <BaseTooltip className="text-left w-100" contents={value || "-"} />,
  },
  {
    Header: "Component ID",
    accessor: "control.componentId",
    width: 120,
    Cell: ({ row, value }: Cell<BuildingDeviceControl>) => <BaseTooltip className="text-left w-100" contents={value || "-"} />,
  },
  {
    Header: "Capabiliity ID",
    accessor: "control.capabilityId",
    width: 180,
    Cell: ({ row, value }: Cell<BuildingDeviceControl>) => <BaseTooltip className="text-left w-100" contents={value || "-"} />,
  },
  {
    Header: "Command",
    accessor: "control.commandPart.command",
    width: 120,
    Cell: ({ row, value }: Cell<BuildingDeviceControl>) => <BaseTooltip className="text-left w-100" contents={value || "-"} />,
  },
  {
    Header: "Arguments",
    accessor: "control.commandPart.arguments",
    width: 120,
    Cell: ({ row, value }: Cell<BuildingDeviceControl & { arguments: string }> & { setArgumentsValue: Dispatch<SetStateAction<string>> }) => {
      if (row.original.arguments !== undefined) {
        return <span className="text-left w-100">{row.original.arguments === "" ? "-" : row.original.arguments}</span>;
      } else {
        return <span className="text-left w-100">{value && value.length > 0 ? value : "-"}</span>;
      }
    },
  },
  {
    Header: "실행",
    accessor: "",
    width: 100,
    Cell: (
      props: Cell<any> & {
        excuteDevice: (passData: DeviceControlParams) => Promise<void>; //
        openControlModal: (data: any) => void;
        setArgumentsValue: Dispatch<SetStateAction<string>>;
        checkExecuteAuthority: (privilegeCode: UnionPartnerPrivilegeCode) => boolean;
      },
    ) => {
      const controlId = props.row.original.control.id;
      const buildingDeviceId = props.row.original.buildingDeviceId; // 건물 iot 탭 에서 실행,
      const productDeviceId = props.row.original.productDeviceId; // 상품 iot 탭 에서 실행
      const contractDeviceId = props.row.original.contractDeviceId;
      const controlMethod = props.row.original.control.method;
      const componentId = props.row.original.control.componentId;
      let argumentsValue = "";

      if (props.row.original?.arguments !== undefined) {
        argumentsValue = props.row.original.arguments;
      } else if (props.row.original.control.commandPart) {
        argumentsValue = props.row.original.control.commandPart.arguments !== null ? props.row.original.control.commandPart.arguments[0] : "";
      } else {
        argumentsValue = "";
      }

      let deviceId = "";
      let deviceType = "";

      if (buildingDeviceId) {
        deviceId = buildingDeviceId;
        deviceType = "building";
      } else if (productDeviceId) {
        deviceId = productDeviceId;
        deviceType = "product";
      } else {
        deviceId = contractDeviceId;
        deviceType = "contract";
      }

      return (
        <>
          {props.checkExecuteAuthority(deviceType as UnionPartnerPrivilegeCode) && controlMethod === DeviceControlMethod.EXEC_COMMANDS && (
            <BaseButton
              title={"실행"}
              className="size-small color-white"
              onClick={() => {
                const passData = {
                  controlId,
                  buildingDeviceId,
                  productDeviceId,
                  contractDeviceId,
                  controlMethod,
                  componentId,
                  deviceId,
                  deviceType,
                };
                props.setArgumentsValue(argumentsValue);
                props.openControlModal(passData);
              }}
            />
          )}

          {controlMethod === DeviceControlMethod.READ_STATUS && (
            <BaseButton
              title={"실행"}
              className="size-small color-white"
              onClick={() => {
                props.setArgumentsValue(argumentsValue);
                props.excuteDevice({ deviceType, deviceId, controlId, controlMethod, arguments: "" });
              }}
            />
          )}
          {!props.checkExecuteAuthority(deviceType as UnionPartnerPrivilegeCode) && controlMethod === DeviceControlMethod.EXEC_COMMANDS && "-"}
        </>
      );
    },
  },
  {
    Header: "실행결과",
    accessor: "executed",
    width: 120,
    Cell: ({ row }: Cell<any>) => {
      const executedMessage = row.original.executed;
      const isParsed = row.original.isParsed;

      return (
        <>
          {row.original.executed ? (
            <>
              <p className="w-100 text-left ellipsis2">{executedMessage}</p>
              <BaseRichTooltip
                touchIcon="INFO"
                rightLabel={<p className="font14"></p>}
                children={JSON.stringify(isParsed ? JSON.parse(executedMessage) : executedMessage, null, 4)}
              />
            </>
          ) : (
            // <BaseTooltip
            //   className="size-large"
            //   preformatted
            //   contents={JSON.stringify(isParsed ? JSON.parse(executedMessage) : executedMessage, null, 4)}
            // />
            <span>-</span>
          )}
        </>
      );
    },
  },
  {
    Header: "Labels",
    accessor: "",
    width: 60,
    Cell: ({ row }: Cell<any>) => {
      return (
        <>
          <BaseRichTooltip
            touchIcon="INFO"
            firstActionLabel="상세보기"
            rightLabel={<p className="font14"></p>}
            children={<p>{JSON.stringify(row.original.labels, null, 4)}</p>}
          />
        </>
      );
    },
  },
  {
    Header: "실행권한",
    accessor: "",
    width: 120,
    Cell: (
      props: Cell<any> & {
        onClickControl: (item: ContractDeviceControl) => void;
        checkExecuteAuthority: (privilegeCode: UnionPartnerPrivilegeCode) => boolean;
      },
    ) => {
      const method = props.row.original.control.method;
      return (
        (method === DeviceControlMethod.EXEC_COMMANDS && ( //
          <BaseButton title={"설정"} className="size-small color-white" onClick={() => props.onClickControl(props.row.original)} />
        )) ||
        "-"
      );
    },
  },
];
export default DeviceControlColumns;
