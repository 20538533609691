import { AxiosInstance, AxiosResponse } from "axios";
import ApiPath from "src/api/apiPath.json";
import { ApiResponse, MediaFile } from "../public-types";
import {
  BuildingCommonFacility,
  BuildingCommonFacilityDeskGroupModel,
  BuildingCommonFacilityDetailResponseData,
  BuildingParkingLotModel,
  BuildingUpdateResponseData,
  CommonFacilityModel,
} from "./building-types";
import { FacilityAccessGroupModel, FacilityAccessGroupRequest } from "./commonfacility-types";

/* 
  CPB11. 건물 공용공간 관리(등록/수정/삭제)
*/
export async function postCommonfacilityAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: number;
    parkingLotList?: Array<BuildingParkingLotModel>; // 주차장
    meetingRoomList?: Array<CommonFacilityModel>; // 회의실
    deskSpace?: {
      // 좌석
      deskGroupList?: Array<BuildingCommonFacilityDeskGroupModel>; // 좌석
      mediaList?: Array<MediaFile>; // 좌석 배치도 이미지
    };
    refreshRoomList?: Array<CommonFacilityModel>; // 편의시설
  },
): Promise<AxiosResponse<ApiResponse<BuildingUpdateResponseData>>> {
  const { buildingId, parkingLotList, meetingRoomList, deskSpace, refreshRoomList } = params || {};
  if (!buildingId) {
    throw Error("buildingId is not found.");
  }

  const path = ApiPath.building.commonfacility.replace("{id}", buildingId.toString());
  const buildingCommonFacility: BuildingCommonFacility = {
    parkingLotList: parkingLotList || [],
    meetingRoomList: meetingRoomList || [],
    deskSpace: {
      deskGroupList: deskSpace?.deskGroupList || [],
      mediaList: deskSpace?.mediaList || [],
    },
    refreshRoomList: refreshRoomList || [],
  };
  return await axios.post(path, {
    building: {
      buildingCommonFacility,
    },
  });
  // const buildingCommonFacility: BuildingCommonFacility = {
  //   parkingLotList: parkingLotList || [],
  //   meetingRoomList: meetingRoomList || [],
  //   deskSpace: {
  //     deskGroupList: deskSpace?.deskGroupList || [],
  //     mediaList: deskSpace?.mediaList || [],
  //   },
  //   refreshRoomList: refreshRoomList || [],
  // };
  // return await axios.post(path, {
  //   building: {
  //     buildingCommonFacility,
  //   },
  // });
}
/**
 * RPB13. 건물 공용공간 상세 조회
 */
export async function getCommonfacilityAsync(
  axios: AxiosInstance,
  params?: {
    buildingId: string;
    facilityId: string;
  },
): Promise<AxiosResponse<ApiResponse<BuildingCommonFacilityDetailResponseData>>> {
  const { buildingId, facilityId } = params || {};
  if (!buildingId) {
    throw Error("buildingId is not found.");
  }
  if (!facilityId) {
    throw Error("facilityId is not found.");
  }
  const path = ApiPath.building.commonfacilityDetail.replace("{id}", buildingId).replace("{facilityId}", facilityId);
  return await axios.get(path);
}

/** 
RAD72. 건물 공용 시설 출입 설정 조회
*/
export async function getFacilityAccessGroupAsync(
  axios: AxiosInstance,
  params?: {
    facilityId: string;
  },
): Promise<AxiosResponse<ApiResponse<{ facility: FacilityAccessGroupModel }>>> {
  const { facilityId } = params || {};
  if (!facilityId) {
    throw Error("facilityId is not found.");
  }
  return await axios.get(ApiPath.building.accessGroup.detail.replace("{facilityId}", String(facilityId)));
}

/** 
RAD73. 건물 공용 시설 출입 설정 저장
*/
export async function addFacilityAccessGroupAsync(
  axios: AxiosInstance,
  params?: FacilityAccessGroupRequest,
): Promise<AxiosResponse<ApiResponse<{ facility: { facilityId: number } }>>> {
  return await axios.put(ApiPath.building.accessGroup.add.replace("{facilityId}", String(params?.facilityId)), params);
}
