import { Order, UnionCmdType } from "../public-types";

export interface CctvEventData {
  /** Cctv 이벤트 수신 데이터 ID */
  id?: number;
  /** 이벤트 발생 시각 */
  eventTime?: string;
  /** 카메라 그룹 명칭 */
  cameraGroup?: string;
  /** 카메라 ID */
  cameraId?: string;
  /** 이벤트 유형 */
  eventType?: string;
  /** 발생한 이벤트에 대한 세부 데이터 ( Json ) */
  eventData?: string | { count: number };
  /** 생성자 */
  createBy?: string;
  /** 수정자 */
  modifiedBy?: string;
  /** 생성일 */
  createdDate?: string;
  /** 수정일 */
  modifiedDate?: string;
}

export interface CctvDevicePartner {
  /** Cctv장치 파트너 ID수정/삭제 시 필수 */
  id: number;
  /** Cctv장치 ID */
  cctvDeviceId: number;
  /** 파트너 ID */
  partnerId?: number;
  /** 건물 ID */
  buildingId?: number;
  /** 건물 층 ID */
  buildingFloorId?: number;
  /** 혼잡도 CAPA */
  congestionCapacity?: number;
  /** C, D 사용 */
  cmdType?: string;
  /** 삭제여부 */
  isDeleted?: boolean;
  /** 생성일 */
  createdDate?: string;
  /** 생성자 */
  createBy?: string;
  /** 수정일 */
  modifiedDate?: string;
  /** 수정자 */
  modifiedBy?: string;
  /** FE 전용 타입 건물명 */
  buildingName?: string;
  /** FE 전용 타입 층명 */
  floorNum?: number;
}

export interface CctvDevice {
  /** Cctv장치 ID */
  id?: number;
  /** 카메라 그룹 명칭 */
  cameraGroup?: string;
  /** 카메라 IDVMS에서 관리하는 카메라 장치 ID 값 */
  cameraId?: string;
  /** 장치명 */
  name?: string;
  /** Cctv 장치 파트너 정보 조회용 */
  cctvDevicePartnerList?: CctvDevicePartner[];
  /** Cctv 이벤트 데이터 조회용 ( 파트너ID 가 있을 경우 제공 가능. ) */
  cctvEventDataList?: CctvEventData[];
  /** 삭제여부 */
  isDeleted?: boolean;
  /** 생성일 */
  createdDate?: string;
  /** 생성자 */
  createBy?: string;
  /** 수정일 */
  modifiedDate?: string;
  /** 수정자 */
  modifiedBy?: string;
}

export interface CctvDeviceQueryParams {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
  searchType?: string;
  keyword?: string;
  buildingId?: string; //여러 건 조회 시 콤마 구분자 사용
  buildingFloorId?: string; //여러 건 조회 시 콤마 구분자 사용
  id?: string; //여러 건 조회 시 콤마 구분자 사용
}

// 카메라 그룹 스트리밍 정보
export const CctvStreamingDataList = [
  {
    cameraGroup: "FT314",
    cameraList: [
      {
        cameraId: "1_94",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_94/SourceEndpoint.video:0:0?exp=20250110T152132&nonce=69&hmac=bFYcVf98CVXKmNBLWEucXY3nXrA",
      },
      {
        cameraId: "1_95",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_95/SourceEndpoint.video:0:0?exp=20250110T152211&nonce=70&hmac=YTvytpuLpHkjy7QxFNJISII-C94",
      },
      {
        cameraId: "1_96",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_96/SourceEndpoint.video:0:0?exp=20250110T152249&nonce=71&hmac=qGjWTLWyN3MefjusAuDZ-GBW_3w",
      },
      {
        cameraId: "1_165",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_165/SourceEndpoint.video:0:0?exp=20250110T152515&nonce=72&hmac=mkHfkQ1r4k4jrfiCbrbzwPZsvPg",
      },
      {
        cameraId: "1_166",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_166/SourceEndpoint.video:0:0?exp=20250114T191520&nonce=1&hmac=-kQ2TfJ8hEfzw38nMkcx4Wdm2CQ",
      },
      {
        cameraId: "1_168",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_168/SourceEndpoint.video:0:0?exp=20250110T152557&nonce=74&hmac=UGxR_16fvasrxH8fs73eXzI034k",
      },
      {
        cameraId: "1_169",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_169/SourceEndpoint.video:0:0?exp=20250110T152620&nonce=75&hmac=gZXyh2lylvLMtEEDZdG_yRbomBI",
      },
      {
        cameraId: "1_170",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_170/SourceEndpoint.video:0:0?exp=20250110T152752&nonce=76&hmac=QybI9NBUIxYltJ9D63g5LGUbV5Q",
      },
      {
        cameraId: "1_171",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_171/SourceEndpoint.video:0:0?exp=20250110T152811&nonce=77&hmac=3v6KapEnihqwyuZYK0WM2amwDQ4",
      },
      {
        cameraId: "1_172",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_172/SourceEndpoint.video:0:0?exp=20250110T152832&nonce=78&hmac=DvQpFaplmLUCLDMEVjPEllXLcXs",
      },
      {
        cameraId: "1_85",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_85/SourceEndpoint.video:0:0?exp=20250110T152855&nonce=79&hmac=h0BJEvGw8KdBVjbHsLpe7tHsTvM",
      },
      {
        cameraId: "1_86",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_86/SourceEndpoint.video:0:0?exp=20250110T152914&nonce=80&hmac=DTrTJHtsG1VUHi7mqyNCCgvy6Vc",
      },
      {
        cameraId: "1_87",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_87/SourceEndpoint.video:0:0?exp=20250110T152934&nonce=81&hmac=HAE6a_seLq1sC8XxXi6KX4HDohg",
      },
      {
        cameraId: "1_88",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_88/SourceEndpoint.video:0:0?exp=20250110T153636&nonce=97&hmac=Zx3w2ZizvoR5R-MISlzh2Vgx2z4",
      },
      {
        cameraId: "1_89",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_89/SourceEndpoint.video:0:0?exp=20250110T153029&nonce=83&hmac=NMhzO3wdzifAPPoSgsY1X5k7e1I",
      },
      {
        cameraId: "1_90",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_90/SourceEndpoint.video:0:0?exp=20250110T153048&nonce=84&hmac=sFObS-jqAk_VZRWn5DDkvke_YyQ",
      },
      {
        cameraId: "1_91",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_91/SourceEndpoint.video:0:0?exp=20250110T153105&nonce=85&hmac=UJSwJ25gZv_H5SwgzJ0m1IuNukw",
      },
      {
        cameraId: "1_162",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_162/SourceEndpoint.video:0:0?exp=20250110T153125&nonce=86&hmac=24x3Lib8rJheNUkF8IErZPJm10s",
      },
      {
        cameraId: "1_63",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_63/SourceEndpoint.video:0:0?exp=20250110T153148&nonce=87&hmac=3vDembpRCd_KDWUT2Lx86FUdk7Q",
      },
      // ===
      {
        cameraId: "1_50",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_50/SourceEndpoint.video:0:0?exp=20250110T153213&nonce=88&hmac=RYS47UD0uhIzmlZA7w0Sl_bv0JY",
      },
      {
        cameraId: "1_51",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_51/SourceEndpoint.video:0:0?exp=20250110T153229&nonce=89&hmac=MKmCSbRWHbzLxiJVpkWf3w65YbM",
      },
      {
        cameraId: "1_151",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_151/SourceEndpoint.video:0:0?exp=20250110T153249&nonce=90&hmac=8kkCAqQLrC9jaiq6RFAqG_GMGCo",
      },
      {
        cameraId: "1_52",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_52/SourceEndpoint.video:0:0?exp=20250110T153309&nonce=92&hmac=tknkToKxqvYnfWb0WrgbBMS02iQ",
      },
      {
        cameraId: "1_53",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_53/SourceEndpoint.video:0:0?exp=20250110T153331&nonce=93&hmac=Wrop-aWf6j8I4aUmBArjNT87XiI",
      },
      {
        cameraId: "1_54",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_54/SourceEndpoint.video:0:0?exp=20250110T153352&nonce=94&hmac=7Lk8fIcnTR0WWT5sDIiciQvsAs0",
      },
      {
        cameraId: "1_55",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_55/SourceEndpoint.video:0:0?exp=20250110T153414&nonce=95&hmac=p4vfPyNk9GVQ1qHH-EhkGsaFDhw",
      },
      {
        cameraId: "1_152",
        streamingUrl:
          "https://211.32.11.231:8443/live/media/DESKTOP-7GEROE9/DeviceIpint.1_152/SourceEndpoint.video:0:0?exp=20250110T153432&nonce=96&hmac=6E2nX7ESbk3pHOoZ0klvGsm1Ml8",
      },
    ],
  },
];
