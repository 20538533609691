import { AxiosInstance, AxiosResponse } from "axios";
import { ProductListData, ProductListParams } from "../product/product-types";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  QuestionAddType,
  QuestionAnswerAddType,
  QuestionAnswerModel,
  QuestionConnectedInfoListQuery,
  QuestionConnectedInfoListRequest,
  QuestionConnectedInfoListResponse,
  QuestionDetailModel,
  QuestionListResponse,
  QuestionNotifyMessageType,
  QuestionQueryParams,
  QuestionStatusUnionType,
  OpenAIThreadRequest,
  OpenAIMessageRequest,
  OpenAIMessage,
  OpenAIRunRequest,
  OpenAIRun,
  OpenAIRunStatus,
  OpenAIMessageListQuery,
  OpenAIMessageList,
} from "./question-type";

// RPP01. 공간상품 기본정보 목록
export async function getQuestionListAsync(
  axios: AxiosInstance,
  params?: QuestionQueryParams,
): Promise<AxiosResponse<ApiResponse<QuestionListResponse>>> {
  return await axios.get<ApiResponse<QuestionListResponse>>(ApiPath.question.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * REQ17. 상담관리 상세 목록
 * https://pnpt.atlassian.net/wiki/x/AYAnJQ
 */
export async function getQuestionDetailListAsync(
  axios: AxiosInstance,
  params?: QuestionQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel[] }>>> {
  return await axios.get(ApiPath.question.details, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
  - REQ02. 상담관리 상세 목록
  - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/315228180/REQ02.
 */ export async function getQuestionDetailAsync(
  axios: AxiosInstance,
  params?: { id: number },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.get(ApiPath.question.detail.replace("{id}", String(params?.id)));
}

/**
- REQ03. 상담관리 등록
- https: export async function postQuestionAsync(
 */
export async function postQuestionAsync(
  axios: AxiosInstance,
  params?: { question: QuestionAddType },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.post(ApiPath.question.add.form, { question: params?.question });
}

/**
- REQ04. 상담관리 수정
- https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/315260998/REQ04. */

// 상담관리 수정
export async function editQuestionAsync(
  axios: AxiosInstance,
  params?: { id: number; question: QuestionAddType },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionDetailModel }>>> {
  return await axios.put(ApiPath.question.edit.form.replace("{id}", String(params?.id)), { question: params?.question });
}

/** 
  - REQ05. 상담관리 삭제
  - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/290881713/REQ05.
  */

export async function deleteQuestionAsync(axios: AxiosInstance, parmas?: { ids: Array<string> }): Promise<AxiosResponse<ApiResponse<any>>> {
  return await axios.delete(ApiPath.question.delete, { params: parmas?.ids?.join(",") });
}

/** 
  - REQ06. 상담관리 답변 등록
  - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/290881713/REQ05.
  */
export async function postAnswerQuestionAsync(
  axios: AxiosInstance,
  params?: { id: string; data: QuestionAnswerAddType },
): Promise<AxiosResponse<ApiResponse<{ content: QuestionAnswerModel }>>> {
  return await axios.post(ApiPath.question.add.answer.replace("{id}", String(params?.id)), { answer: params?.data });
}

/**
 - REQ07. 상담관리 알림 발송 요청 
 - https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/315261032/REQ07.
 */

export const questionNotifyAsync = (
  axios: AxiosInstance,
  params?: {
    id: number;
    answerId: string;
    messageType: QuestionNotifyMessageType;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  return axios.get(`/api/ctrl/partners/{partnerId}/et/question/${String(params?.id)}/${String(params?.answerId)}/${params?.messageType}`);
};

/** 
 * REQ08. 상담관리 상태 변경
https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/316243969/REQ08.
 * */
export async function editQuestionStatusAsync(
  axios: AxiosInstance,
  params?: { id: number; status: QuestionStatusUnionType },
): Promise<AxiosResponse<ApiResponse<QuestionDetailModel>>> {
  return await axios.patch(ApiPath.question.edit.status.replace("{id}", String(params?.id)), { question: { status: params?.status } });
}

/**
 * REQ15. 상담관리 연결정보 조회
 * https://pnpt.atlassian.net/wiki/x/AYBlGw
 */
export async function getConnectedInfoListAsync(
  axios: AxiosInstance,
  params?: QuestionConnectedInfoListQuery,
): Promise<AxiosResponse<ApiResponse<QuestionConnectedInfoListResponse>>> {
  return await axios.get<ApiResponse<QuestionConnectedInfoListResponse>>(ApiPath.question.connectedInfo.get, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/**
 * REQ14. 상담관리 연결정보 등록/삭제
 * https://pnpt.atlassian.net/wiki/x/AgBiGw
 */
export async function postConnectedInfoListAsync(
  axios: AxiosInstance,
  params?: QuestionConnectedInfoListRequest["connectedInfoList"],
): Promise<AxiosResponse<ApiResponse<QuestionConnectedInfoListResponse>>> {
  return await axios.post(ApiPath.question.connectedInfo.post, { connectedInfoList: params });
}

/**
 * RUT01. OpenAI 파일 업로드
 * https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/880771311/RUT01.+OpenAI
 */
export async function postOpenAIFileUploadAsync(axios: AxiosInstance, params?: { file: File }): Promise<AxiosResponse<ApiResponse<{ id: string }>>> {
  const formData = new FormData();
  formData.append("file", params?.file as File);

  return await axios.post("/api/ctrl/partners/{partnerId}/util/openai/v1/files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * RUT02. OpenAI Thread 생성
 * https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/881361102/RUT02.+OpenAI+Thread
 */
export async function postOpenAIThreadAsync(
  axios: AxiosInstance,
  params: {
    fileId: string;
    message: string;
  },
): Promise<AxiosResponse<ApiResponse<{ id: string }>>> {
  const requestBody: OpenAIThreadRequest = {
    messages: [
      {
        role: "user",
        content: params.message,
      },
    ],
    toolResources: {
      codeInterpreter: {
        fileIds: [params.fileId], // 단일 파일 ID를 배열로 전달
      },
    },
  };

  return await axios.post("/api/ctrl/partners/{partnerId}/util/openai/v1/threads", requestBody);
}

/**
 * RUT03. OpenAI 메시지 생성
 * @description 특정 Thread에 새로운 메시지를 생성합니다.
 * @see https://pnpt.atlassian.net/wiki/x/6YCIN
 *
 * @param axios - Axios 인스턴스
 * @param params.threadId - 대상 Thread ID
 * @param params.content - 메시지 내용
 * @param params.attachments - 첨부 파일 정보 (선택사항)
 * @returns Promise<AxiosResponse<ApiResponse<OpenAIMessageResponse>>>
 */
export async function postOpenAIMessageAsync(
  axios: AxiosInstance,
  params: {
    threadId: string;
    content: string;
    attachments: {
      fileId: string;
      tools: { type: string }[];
    }[];
  },
): Promise<AxiosResponse<ApiResponse<OpenAIMessage>>> {
  const requestBody: OpenAIMessageRequest = {
    content: params.content,
    attachments: params.attachments || [],
  };

  return await axios.post(`/api/ctrl/partners/{partnerId}/util/openai/v1/threads/${params.threadId}/messages`, requestBody);
}

/**
 * RUT04. OpenAI 실행 생성
 * @description Thread에 대한 새로운 실행을 생성합니다.
 * @see https://pnpt.atlassian.net/wiki/x/ngF-N
 *
 * @param axios - Axios 인스턴스
 * @param params.threadId - 대상 Thread ID
 * @param params.assistantId - 어시스턴트 ID
 * @param params.tools - 도구 목록
 * @returns Promise<AxiosResponse<ApiResponse<OpenAIRun>>>
 */
export async function postOpenAIExecutionAsync(
  axios: AxiosInstance,
  params: {
    threadId: string;
    assistantId: string;
    tools: { type: string }[];
  },
): Promise<AxiosResponse<ApiResponse<OpenAIRun>>> {
  const requestBody: OpenAIRunRequest = {
    assistantId: params.assistantId,
    tools: params.tools,
  };

  return await axios.post(`/api/ctrl/partners/{partnerId}/util/openai/v1/threads/${params.threadId}/runs`, requestBody);
}

/**
 * RUT05. OpenAI 실행 조회
 * @description Thread의 특정 실행 상태를 조회합니다.
 * @see https://pnpt.atlassian.net/wiki/x/xIGCN
 *
 * @param axios - Axios 인스턴스
 * @param params.threadId - 대상 Thread ID
 * @param params.runId - 조회할 실행 ID
 * @returns Promise<AxiosResponse<ApiResponse<OpenAIRunStatus>>>
 */
export async function getOpenAIExecutionAsync(
  axios: AxiosInstance,
  params: {
    threadId: string;
    runId: string;
  },
): Promise<AxiosResponse<ApiResponse<OpenAIRunStatus>>> {
  return await axios.get(`/api/ctrl/partners/{partnerId}/util/openai/v1/threads/${params.threadId}/runs/${params.runId}`);
}

/**
 * RUT06. OpenAI 메시지 목록 조회
 * @description Thread의 메시지 목록을 조회합니다.
 * @see https://pnpt.atlassian.net/wiki/x/L4GHN
 *
 * @param axios - Axios 인스턴스
 * @param params.threadId - 대상 Thread ID
 * @param params.query - 조회 옵션 (limit, after, before, runId)
 * @returns Promise<AxiosResponse<ApiResponse<OpenAIMessageList>>>
 */
export async function getOpenAIMessagesAsync(
  axios: AxiosInstance,
  params: {
    threadId: string;
    query?: OpenAIMessageListQuery;
  },
): Promise<AxiosResponse<ApiResponse<OpenAIMessageList>>> {
  return await axios.get(`/api/ctrl/partners/{partnerId}/util/openai/v1/threads/${params.threadId}/messages`, {
    params: params.query,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}
