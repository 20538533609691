import { useCallback, useEffect, useState } from "react";
import { getAccessGroupDetailAsync } from "src/api/access/productac-api";
import { Ac2ProductAccessGroupModel, AccessGroupDeviceModel } from "src/api/access/productac-types";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { Modal } from "../product-types";

type Props = {
  accessGroupId: number;
  showModal: Modal;
  setShowModal: Function;
};

const AccessGroupDetailModal = ({ accessGroupId, showModal, setShowModal }: Props) => {
  const [accessGroup, setAccessGroup] = useState<Ac2ProductAccessGroupModel[]>();
  // 액세스그룹 상세 조회
  const { executeAsync: getAccessDetail } = useApiOperation(getAccessGroupDetailAsync);
  const getAccessGroupDetail = useCallback(
    async (accessGroupId: number) => {
      const { data } = await getAccessDetail({ accessGroupId: String(accessGroupId) });
      const access = data.data.content;
      setAccessGroup(access);
    },
    [getAccessDetail],
  );
  useEffect(() => {
    if (showModal) {
      getAccessGroupDetail(accessGroupId);
    }
  }, [accessGroupId, getAccessGroupDetail, showModal]);
  return (
    <BaseAbstractModal isOpen={true} size="medium" className="accessgroup-divice-modal">
      <section className="base-abstract-modal__title border-bottom">
        <h1>{showModal.payload}</h1>
      </section>

      <section className="base-abstract-modal__contents px30">
        <section className="base-abstract-modal__contents-subtitle">
          <h2>출입장치 정보</h2>
        </section>
        <table className="inner-table" width="100%">
          <thead>
            <tr>
              <th>장치 id</th>
              <th>장치 이름</th>
            </tr>
          </thead>
          <tbody>
            {accessGroup && accessGroup.length > 0 ? (
              <>
                {accessGroup?.map((item: AccessGroupDeviceModel, index) => {
                  return (
                    <tr key={item.id}>
                      <td width={180} className="text-center">
                        {item?.externalDeviceId}
                      </td>
                      <td className="text-left">{item.deviceName}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={2} className="text-gray600 text-center">
                  -
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"확인"} onClick={() => setShowModal(false)} />
      </section>
    </BaseAbstractModal>
  );
};

export default AccessGroupDetailModal;
