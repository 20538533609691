import { useEffect, useMemo, useState } from "react";
import { BuildingModel, BuildingParkingLotModel } from "src/api/building/building-types";
import { BaseButton, BaseModal, BaseToggle } from "src/components";
import { FacilityModal } from "src/pages/building/building-types";
import ParkingFormModal from "src/pages/building/details/facilityDetail/parking/ParkingFormModal";
import useFacility from "src/pages/building/hooks/useFacility";
import ParkingSectionDetail from "./ParkingSectionDetail";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";

type Props = {
  building: BuildingModel;
  fetchBuilding: (buildingId: number) => void;
};

const ParkingSection = ({ building, fetchBuilding }: Props) => {
  const [isUsedFacility, setIsUsedFacility] = useState(false);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [confirmModal, setConfirmModal] = useState<FacilityModal>({ isOpen: false });
  const parkingLots = useMemo(() => building?.buildingCommonFacility?.parkingLotList || [], [building?.buildingCommonFacility]);

  const { isAuthority, fetchAuthorityReadonly } = usePartnerAuthority();

  const { onUpdateParking, onRemoveParking } = useFacility(building);

  const handleConfirm = async () => {
    if (confirmModal.type === "FACILITY_DELETE") {
      await onRemoveParking();
      fetchBuilding(building.id);
    } else {
      await onUpdateParking(confirmModal.payload as BuildingParkingLotModel);
      fetchBuilding(building.id);
    }

    setConfirmModal({ isOpen: false });
    setIsOpenForm(false);
  };

  useEffect(() => {
    setIsUsedFacility(parkingLots.length > 0 ? true : false);
  }, [parkingLots]);

  // 등록/수정 모달 닫기
  const onClose = () => setIsOpenForm(false);

  const onSaveParking = (data: BuildingParkingLotModel) => {
    setConfirmModal({ isOpen: true, title: "저장하시겠습니까?", payload: data });
  };

  return (
    <>
      <div className="contents-container__sub-title">
        <div className="flex-center">
          <h2 className="minmax80">설정</h2>
          <BaseToggle
            checked={isUsedFacility}
            onChange={(checked: boolean) => {
              if (!isAuthority("w")) {
                return fetchAuthorityReadonly(true);
              }
              if (parkingLots.length > 0 && !checked) {
                setConfirmModal({ title: "정보가 초기화되어 저장됩니다.", isOpen: true, type: "FACILITY_DELETE" });
              } else {
                setIsUsedFacility(checked);
              }
            }}
          />
        </div>
      </div>
      {isUsedFacility && (
        <>
          <div className="flex-center-between mb30" style={{ minHeight: 40 }}>
            <h2>주차정보</h2>
            {isAuthority("w") && <BaseButton title="주차 설정" onClick={() => setIsOpenForm(true)} />}
          </div>
          {isOpenForm && (
            <ParkingFormModal //
              parkingLots={parkingLots}
              onClose={onClose}
              onSaveParking={onSaveParking}
              fetchBuilding={() => {}}
            />
          )}

          <ParkingSectionDetail parkingLots={parkingLots} />
        </>
      )}

      <BaseModal
        title={confirmModal.title}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={() => {
          handleConfirm();
        }}
        onClose={() => {
          setConfirmModal({ isOpen: false });
        }}
        isOpen={confirmModal.isOpen}
      />
    </>
  );
};

export default ParkingSection;
