import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { BaseButton, BaseInput, BaseTooltip } from "src/components";
import { BaseSelectWithButton } from "src/components/BaseSelectWithButton";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { DeskSpace, SelectOption } from "src/pages/building/building-types";

type Props = {
  building?: BuildingModel;
  onClose: () => void;
  handleConfirm: (passData?: CommonFacilityModel, type?: "GROUP" | "DESK") => void;
  facility?: DeskSpace;
  readonly?: boolean;
  onChageReadOnly: () => void;
  deskSpaces: Array<DeskSpace>;
  deskGroupId?: number;
  openDeskGroupFormModal: (facility?: CommonFacilityModel) => void;
};

const DeskBasicInfo = ({
  building,
  onClose,
  handleConfirm,
  facility,
  readonly,
  onChageReadOnly,
  deskSpaces,
  openDeskGroupFormModal,
  deskGroupId,
}: Props) => {
  const { isAuthority } = usePartnerAuthority();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,

    watch,
    formState: { errors },
  } = useForm<CommonFacilityModel>({
    defaultValues: {
      id: undefined,
      buildingId: undefined,
      commonFacilityType: "DESK",
      locationCode: "",
      buildingCommonFacilityDeskGroupId: undefined,
    },
  });

  useEffect(() => {
    if (deskGroupId) {
      setValue("buildingCommonFacilityDeskGroupId", deskGroupId);
    }

    if (facility) {
      setValue("id", facility.deskId);
      setValue("facilityName", facility.deskName);
      setValue("locationCode", facility.locationCode);
      setValue("buildingCommonFacilityDeskGroupId", facility.deskGroupId);
      setValue("buildingId", building?.id);
      setValue("commonFacilityType", "DESK");
    }
  }, [facility, deskGroupId]);

  useEffect(() => {
    register("buildingCommonFacilityDeskGroupId", {
      required: "좌석그룹은 필수선택 항목입니다.",
    });
    register("facilityName", {
      required: "좌석 명은 필수입력 항목입니다.",
    });

    register("locationCode", {
      required: "Location code 는 필수입력 항목입니다.",
    });
  }, [register, getValues]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: CommonFacilityModel, e?: any) => {
    e.preventDefault();
    console.log("submitdata", data);
    handleConfirm(data, "DESK");
    e.target.reset();
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    console.log("onError errors", errors);
  };

  const deskGroupIdOptions: Array<SelectOption> = useMemo(() => {
    return (deskSpaces || [])
      .filter((deskSpace: DeskSpace) => deskSpace.type === "GROUP")
      .map((deskSpace: DeskSpace) => ({
        value: `${deskSpace.deskGroupId}`,
        label: `${deskSpace.deskGroupName} (${deskSpace.deskGroupId})`,
      }))
      .sort((a, b) => {
        const nameA = a.label.split(" (")[0];
        const nameB = b.label.split(" (")[0];

        // 첫 글자의 유니코드 값을 확인
        const isKoreanA = nameA.charCodeAt(0) >= 0xac00 && nameA.charCodeAt(0) <= 0xd7a3;
        const isKoreanB = nameB.charCodeAt(0) >= 0xac00 && nameB.charCodeAt(0) <= 0xd7a3;

        // 둘 다 한글이거나 둘 다 영어일 경우
        if ((isKoreanA && isKoreanB) || (!isKoreanA && !isKoreanB)) {
          return nameA.localeCompare(nameB);
        }

        // 한글이 영어보다 먼저 오도록 정렬
        return isKoreanA ? -1 : 1;
      });
  }, [deskSpaces]);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {/* <div className="base-abstract-modal__title"> */}
      <div className="contents-container__wrap px30">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <div className="flex-center">
              <p className={!readonly ? "required" : ""}>좌석그룹</p>
              <BaseTooltip touchIcon={"QUESTION"} className="ml4 mt1" size={16}>
                <p className="mb5">좌석 그룹은 위치별로 좌석을 구분하여 관리하기 위해 필수 설정입니다.</p>
                <p>{`예) '1층 좌석' '2층 좌석'처럼 좌석 그룹을 설정하면 사용자가 손쉽게 원하는 위치의 좌석을 선택할 수 있습니다.`}</p>
              </BaseTooltip>
            </div>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{watch("buildingCommonFacilityDeskGroupId")}</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="buildingCommonFacilityDeskGroupId"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseSelectWithButton
                      className=""
                      stateOptions={deskGroupIdOptions}
                      setStateValue={onChange}
                      value={value}
                      name={name}
                      errorText={errors.buildingCommonFacilityDeskGroupId?.message}
                      onButtonClick={() => {
                        openDeskGroupFormModal();
                      }}
                      menuPlacement="auto"
                      buttonText="좌석그룹 등록"
                      noOptionsMessage="등록된 좌석 그룹이 없습니다"
                      onSettingClick={(optionValue: string) => {
                        const findDeskGroup = deskSpaces.find((desk) => Number(desk.deskGroupId) === Number(optionValue));
                        openDeskGroupFormModal(findDeskGroup);
                      }}
                    />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>좌석 명</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{watch("facilityName")}</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="facilityName"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseInput
                      type="text" //
                      value={value}
                      name={name}
                      onChange={onChange}
                      placeholder="입력해주세요"
                      errorText={errors.facilityName?.message}
                    />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>Location code</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{watch("locationCode")}</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="locationCode"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseInput value={value} name={name} onChange={onChange} placeholder="입력해주세요" errorText={errors.locationCode?.message} />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>
      </div>

      <div className="flex-center-end pa30">
        <BaseButton type="button" title="취소" className="color-white mr5" onClick={onClose} />
        {isAuthority("w") && (
          <>
            {readonly ? (
              <BaseButton type="button" title={"수정"} onClick={() => onChageReadOnly()} /> //
            ) : (
              <BaseButton type="submit" title={"저장"} />
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default DeskBasicInfo;
