import { range } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BuildingCommonFacilityDeskGroupModel, BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { MediaFile } from "src/api/file/file-types";
import { BaseButton, BaseInput, BaseSelect } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import PublicImageUpdate from "src/components/PublicImageUpdate";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { DeskSpace, floorOptions, SelectOption } from "src/pages/building/building-types";

type Props = {
  building?: BuildingModel;
  onClose: () => void;
  handleConfirm: (passData?: CommonFacilityModel, type?: "GROUP" | "DESK") => void;
  facility?: DeskSpace;
  readonly?: boolean;
  deskSpaces: Array<DeskSpace>;
};

const DeskGroupFormModal = ({ building, onClose, handleConfirm, facility, readonly, deskSpaces }: Props) => {
  const { isAuthority } = usePartnerAuthority();

  const [isReadOnly, setIsReadOnly] = useState<boolean>(readonly || false);

  const rangeFloorOptions: Array<SelectOption> = useMemo(() => {
    // 건물 > 기본정보 > 규모 범위
    const floorRange = range(
      Number(building?.undergroundFloorNums || 0) === 0 ? 0 : Number(building?.undergroundFloorNums || 0) * -1,
      Number(building?.floorNums || 0) + 1,
    );
    const _rangeFloorOptions: Array<SelectOption> = [];
    for (let i = 0; i < floorRange.length; i++) {
      const floor: number = floorRange[i];
      if (floor !== 0) {
        _rangeFloorOptions.push({
          value: String(floor || ""),
          label: floorOptions.find((v: SelectOption) => v.value === String(floor || ""))?.label || "",
        });
      }
    }
    if (_rangeFloorOptions.length === 0) {
      return floorOptions;
    } else {
      return _rangeFloorOptions.sort((a: SelectOption, b: SelectOption) => Number(b.value || 0) - Number(a.value || 0));
    }
  }, [building]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<BuildingCommonFacilityDeskGroupModel>({
    defaultValues: {
      id: undefined,
      groupName: undefined,
      floorNum: undefined,
      description: undefined,
      buildingId: undefined,
      deskList: [],
      mediaList: [],
    },
  });

  useEffect(() => {
    register("floorNum", {
      required: "위치는 필수입력항목입니다.",
    });
    register("groupName", {
      required: "좌석 그룹 명은 필수입력항목입니다.",
    });
  }, [register, deskSpaces]);

  useEffect(() => {
    setValue("id", facility?.deskGroupId);
    setValue("floorNum", Number(facility?.floor));
    setValue("groupName", facility?.deskGroupName);
    setValue("description", facility?.deskGroupDescription);
    setValue("mediaList", facility?.mediaList);
  }, [facility]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: CommonFacilityModel, e?: any) => {
    e.preventDefault();
    console.log("submitdata", data);
    handleConfirm(data, "GROUP");
    e.target.reset();
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    console.log("onError errors", errors);
  };

  return (
    <BaseAbstractModal isOpen={true}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="base-abstract-modal__title">
          <h1>{`좌석 그룹 ${isReadOnly ? "" : facility?.deskGroupId ? "수정" : "등록"}`}</h1>
        </div>
        <div className="contents-container__wrap px30">
          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className={!isReadOnly ? "required" : ""}>위치</p>
            </div>
            <div className="contents-container__grid-contents">
              {isReadOnly ? (
                <div className="minmax240">
                  <span>{floorOptions.find((option: SelectOption) => Number(option.value) === Number(watch("floorNum")))?.label}</span>
                </div>
              ) : (
                <div className="minmax240">
                  <Controller
                    control={control}
                    name="floorNum"
                    render={({ field: { value, name, onChange }, formState: { errors } }) => (
                      <BaseSelect
                        className=""
                        stateOptions={rangeFloorOptions}
                        setStateValue={onChange}
                        value={value}
                        name={name}
                        errorText={errors.floorNum?.message}
                      />
                    )}
                  ></Controller>
                </div>
              )}
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p className={!isReadOnly ? "required" : ""}>좌석그룹 명</p>
            </div>
            <div className="contents-container__grid-contents">
              {isReadOnly ? (
                <div className="minmax240">
                  <span>{watch("groupName")}</span>
                </div>
              ) : (
                <div className="minmax240">
                  <Controller
                    control={control}
                    name="groupName"
                    render={({ field: { value, name, onChange }, formState: { errors } }) => (
                      <BaseInput
                        type="text" //
                        value={value}
                        name={name}
                        onChange={onChange}
                        placeholder="입력해주세요"
                        errorText={errors.groupName?.message}
                      />
                    )}
                  ></Controller>
                </div>
              )}
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>설명</p>
            </div>
            <div className="contents-container__grid-contents">
              {isReadOnly ? (
                <div>
                  <span>{watch("description")}</span>
                </div>
              ) : (
                <div>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { value, name, onChange }, formState: { errors } }) => (
                      <BaseInput value={value} name={name} onChange={onChange} placeholder="입력해주세요" errorText={errors.description?.message} />
                    )}
                  ></Controller>
                </div>
              )}
            </div>
          </section>

          <section className="contents-container__grid">
            <div className="contents-container__grid-index">
              <p>이미지 {isReadOnly ? "" : `(${watch("mediaList")?.length || 0}/10)`}</p>
            </div>
            <div className="contents-container__grid-contents">
              <div style={{ width: 795 }}>
                <Controller
                  control={control}
                  name="mediaList"
                  render={({ field: { value, name, onChange } }) => (
                    <>
                      {isReadOnly && value?.length === 0 ? (
                        <span>-</span>
                      ) : (
                        <PublicImageUpdate
                          className="w-100"
                          fileMaxLength={10}
                          mediaFiles={value}
                          isOnlyView={isReadOnly}
                          categoryName={"desk"}
                          mediaServiceType="MEDIA_BUILDING_DESK_GROUP"
                          isUsedDescription
                          isUsedRepresentative
                          onChangeMediaFiles={(mediaFiles: MediaFile[]) => {
                            const hasPrimaryImage = mediaFiles.some((file) => file.isPrimary);

                            const medias = mediaFiles.map((file: MediaFile, index: number) => {
                              const additionalMediaFile: MediaFile = {
                                ...file,
                                ...{
                                  mediaServiceType: "MEDIA_BUILDING_DESK_GROUP", // 좌석
                                  category1: "",
                                  isPrimary: hasPrimaryImage ? file.isPrimary : index === 0,
                                },
                              };
                              return additionalMediaFile;
                            });
                            onChange(medias);
                            // setMediaList(medias);
                          }}
                        />
                      )}
                    </>
                  )}
                ></Controller>
              </div>
            </div>
          </section>
        </div>

        <div className="flex-center-end pa30">
          <BaseButton type="button" title="취소" className="color-white mr5" onClick={onClose} />

          {isAuthority("w") && (
            <>
              {isReadOnly ? (
                <BaseButton type="button" title={"수정"} onClick={() => setIsReadOnly(!isReadOnly)} /> //
              ) : (
                <BaseButton type="submit" title={"저장"} />
              )}
            </>
          )}
        </div>
      </form>
    </BaseAbstractModal>
  );
};

export default DeskGroupFormModal;
