import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Cell } from "react-table";
import { useRecoilValue } from "recoil";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, BaseToggle, BaseTooltip, Tab } from "src/components";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { SelectOption, facilitySubTabs, floorOptions, sortingMeetingRoomList, spaceStyleOptions } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import useFacility from "src/pages/building/hooks/useFacility";
import { globalPartnerState } from "src/recoil/partners/atom";
import { PaPartner } from "src/types/partner";
import Esl from "../components/Esl";
import RefreshRoomFormModal from "./RefreshRoomFormModal";

type Props = {
  building: BuildingModel;
  fetchBuilding: (buildingId: number) => void;
};

/* 
  편의시설 컴포넌트
 */
const RefreshRoomSection = ({ building, fetchBuilding }: Props) => {
  const [isUsedFacility, setIsUsedFacility] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(facilitySubTabs[0]);

  // 이미지 목록
  const partner = useRecoilValue(globalPartnerState);

  // 출입그룹 기능 활성 권한 여부
  const isAccessFeature = partner?.features?.some((feature: PaPartner) => feature.name === "FEATURE_RESERVATION_ACCESS" && feature.active) || false;

  const { isAuthority, fetchAuthorityReadonly } = usePartnerAuthority();

  const { onUpdateRefreshRoom, onRemoveRefreshRoom } = useFacility(building);

  const refreshRooms = useMemo(() => sortingMeetingRoomList(building?.buildingCommonFacility?.refreshRoomList || []), [building]);

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });

  const [openFormModal, setOpenFormModal] = useState<Modal & { readonly?: boolean }>({ isOpen: false });

  const [newFacility, setNewFacility] = useState<CommonFacilityModel>();

  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  const handleResetStates = () => {
    setNewFacility(undefined);
    setActiveTab(facilitySubTabs[0]);
  };

  useEffect(() => {
    setIsUsedFacility(refreshRooms.length > 0 ? true : false);
  }, [refreshRooms]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
        Cell: ({ row, value }: Cell<CommonFacilityModel>) => {
          return (
            <div
              className="text-underline "
              style={{ cursor: "pointer", color: "#007df0" }} //
              onClick={() => {
                handleResetStates();
                setOpenFormModal({ isOpen: true, payload: row?.original, readonly: true });
              }}
            >
              <BaseTooltip contents={value} isSingleLine={true} />
            </div>
          );
        },
      },
      {
        Header: "위치",
        accessor: "floorNum",
        width: 120,
        Cell: ({ row }: Cell<CommonFacilityModel>) => {
          const isGround = row?.original?.isGround; // 지상여부
          const floorNum = row?.original?.floorNum; // 위치
          const floor = floorOptions.find((option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`);
          return floor?.label || "";
        },
      },
      {
        Header: "편의시설 명",
        accessor: "facilityName",
        width: 240,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} className="w-100 text-left" />;
        },
      },
      {
        Header: "편의시설 유형",
        accessor: "spaceStyle",
        width: 120,
        Cell: ({ value }: any) => {
          const findOption = spaceStyleOptions.find((option: SelectOption) => option.value === value);
          if (findOption && findOption.value !== "SPACE_STYLE_UNRECOGNIZED") {
            return <BaseTooltip contents={findOption.label} />;
          }
          return "";
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 160,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} className="w-100 text-left" />;
        },
      },
      {
        Header: "정원",
        accessor: "facilityMaxPeopleNums",
        width: 80,
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 120,
        Cell: ({ row }: any) => <Esl row={row} />,
      },
      {
        Header: "이미지",
        accessor: "mediaList",
        width: 80,
        Cell: ({ row }: any) => {
          const imageCount = (row?.original?.mediaList || []).length;
          return <div>{imageCount}</div>;
        },
      },
      {
        Header: "수정",
        accessor: "edit",
        width: 80,
        Cell: ({ row }: Cell<CommonFacilityModel>) => (
          <BaseButton
            title="수정"
            className="color-white"
            onClick={() => {
              handleResetStates();
              setOpenFormModal({ isOpen: true, payload: row?.original });
            }}
          />
        ),
      },
      {
        Header: "삭제",
        accessor: "delete",
        width: 40,
        Cell: ({ row }: Cell<CommonFacilityModel>) => (
          <button
            className="base-trash-btn color-gray"
            onClick={(e) => {
              e.preventDefault();
              setConfirmModal({ isOpen: true, title: "삭제 하시겠습니까?", type: "FACILITY_DELETE", payload: row?.original.id });
            }}
          ></button>
        ),
      },
    ],
    [],
  );

  // 새로 추가된 facility 찾기
  const getNewFacility = async (buildingId: number) => {
    const { data: newBuildingData } = await getBuilding({ buildingId });
    const newRefreshRoomList = newBuildingData.data.content.building.buildingCommonFacility?.refreshRoomList || [];
    const prevRefreshRoomList = building.buildingCommonFacility?.refreshRoomList || [];
    const addedFacility = _.differenceBy(newRefreshRoomList, prevRefreshRoomList, "id");
    if (addedFacility.length > 0) {
      setNewFacility(addedFacility[0]);
      setConfirmModal({
        isOpen: true,
        title: "저장이 완료되었습니다",
        message: "이어서 출입그룹 설정을 할 수 있습니다.\n 이동하시겠습니까?",
        type: "MOVED_ACCESS_GROUP",
      });
    }
  };

  // 편의시설 전체 삭제
  const handleRemoveRefreshRoom = async (id?: number) => {
    const { status } = id ? await onRemoveRefreshRoom(id) : await onRemoveRefreshRoom();
    if (status >= 200 && status <= 299) {
      fetchBuilding(building.id);
      setConfirmModal({ isOpen: false });
      setOpenFormModal({ isOpen: false });
    }
  };

  // 편의시설 저장
  const handleSaveRefreshRoom = async (passData: CommonFacilityModel) => {
    const commonFacilityModel: CommonFacilityModel = {
      ...(passData.id && { id: passData?.id }),
      buildingId: building.id,
      commonFacilityType: "MEETING_ROOM",
      isGround: Number(passData.floorNum) > 0,
      floorNum: Math.abs(Number(passData.floorNum)),
      facilityName: passData.facilityName,
      facilityList: passData.equipments?.split(",") || [],
      facilityMaxPeopleNums: passData.facilityMaxPeopleNums,
      locationCode: passData.locationCode,
      description: passData.description,
      spaceStyle: passData.spaceStyle,
      mediaList: passData.mediaList,
    };
    const { data, status } = await onUpdateRefreshRoom(commonFacilityModel);
    if (status >= 200 && status <= 299) {
      setConfirmModal({ isOpen: false });

      const buildingId = data.data.content.id;

      if (isAccessFeature) {
        await getNewFacility(buildingId);
      } else {
        setOpenFormModal({ isOpen: false });
      }
      passData.id && setOpenFormModal({ isOpen: false });

      fetchBuilding(buildingId);
    }
  };

  const handleConfirm = async (passData?: CommonFacilityModel) => {
    if (!passData) {
      setConfirmModal({ isOpen: true, title: "정보가 초기화되어 저장됩니다.", type: "FACILITY_DELETE" });
    } else {
      setConfirmModal({
        isOpen: true,
        title: "저장하시겠습니까?",
        payload: passData,
      });
    }
  };

  // 저장 모달 확인 클릭시 실행
  const executeConfirm = async () => {
    // 삭제
    if (confirmModal.type === "FACILITY_DELETE") {
      const id = confirmModal.payload as number;
      id ? handleRemoveRefreshRoom(id) : handleRemoveRefreshRoom();
    }
    // 출입그룹 이동
    else if (confirmModal.type === "MOVED_ACCESS_GROUP") {
      setActiveTab(facilitySubTabs[1]);
      setConfirmModal({ isOpen: false });
    }
    // 저장
    else {
      handleSaveRefreshRoom(confirmModal.payload as CommonFacilityModel);
    }
  };

  return (
    <section className="">
      <div className="contents-container__sub-title">
        <div className="flex-center">
          <h2 className="minmax80">설정</h2>
          <BaseToggle
            checked={isUsedFacility}
            onChange={(checked: boolean) => {
              if (!isAuthority("w")) {
                return fetchAuthorityReadonly(true);
              }

              if (refreshRooms.length > 0 && !checked) {
                handleConfirm();
              } else {
                setIsUsedFacility(checked);
              }
            }}
          />
        </div>
      </div>
      {isUsedFacility && (
        <>
          <div className="flex-center-between mb30" style={{ minHeight: 40 }}>
            <h2>편의시설 목록</h2>
            {isAuthority("w") && (
              <BaseButton
                title="편의시설 등록"
                onClick={() => {
                  handleResetStates();
                  setOpenFormModal({ isOpen: true });
                }}
              />
            )}
          </div>

          <ViewDataTable
            columns={columns.filter((column) => {
              if (!isAuthority("w") && column.accessor === "edit") return false;
              if (!isAuthority("d") && column.accessor === "delete") return false;
              return true;
            })}
            data={refreshRooms}
          />
        </>
      )}
      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          title={confirmModal.title}
          onClick={() => {
            executeConfirm();
          }}
          onClose={() => {
            if (confirmModal.type === "MOVED_ACCESS_GROUP") {
              handleResetStates();
              setOpenFormModal({ isOpen: false });
            }
            setConfirmModal({ isOpen: false });
          }}
          btnLeftTitle="취소"
          btnRightTitle={confirmModal.type === "MOVED_ACCESS_GROUP" ? "확인" : "저장"}
        >
          {confirmModal.message && <p>{confirmModal.message}</p>}
        </BaseModal>
      )}

      {openFormModal.isOpen && (
        <RefreshRoomFormModal
          onClose={() => setOpenFormModal({ isOpen: false })}
          handleConfirm={handleConfirm}
          facility={newFacility ? newFacility : openFormModal.payload}
          readonly={openFormModal.readonly}
          building={building}
          isAccessFeature={isAccessFeature}
          activeTab={activeTab}
          onChangeTab={setActiveTab}
        />
      )}
    </section>
  );
};

export default RefreshRoomSection;
