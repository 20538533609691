import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { BaseButton, BaseModal, BaseTooltip } from "src/components";
import GoToListButton from "src/components/GoToListButton";
import useApiLoading from "src/hooks/useApiLoading";
import useNavigate from "src/hooks/usePartnerNavigate";
import pagePath from "src/pagePath.json";
import ScheduleInfoColumns from "src/pages/billing/columns/ScheduleInfoColumns";
import OverdueInfo from "./OverdueInfo";
import moment from "moment";
import { useToast } from "src/recoil/toast/hook";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import StopModal from "src/pages/billing/modal/StopModal";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import S2Columns from "src/pages/billing/columns/S2Columns";
import { ContractStep } from "src/api/contract/contract-types";
import { Modal } from "src/api/public-types";
import { useApiOperation } from "src/api/hooks";
import { providerDetailAsync } from "src/api/provider/provider-api";
import MakeBillPopup from "src/pages/billing/components/MakeBillPopup";
import { getScheduleRoundNOverdueList, mntBillAdd, mntBillUpdate } from "src/api/billingSchedule/billingSchedule-api";
import ScrollShadowProvider from "src/components/ScrollShadowProvider";
interface Bill {
  billId?: number;
  billStartTime: string;
  billEndTime: string;
  billIssueDate: string;
  billDueDate: string;
  isConfirmed: boolean;
  billDescription: string;
  amountDescription: string;
  buildingId?: number;
  billDetailList: Array<any>;
  [key: string]: any;
}
const initBill = {
  billId: 0,
  billStartTime: "",
  billEndTime: "",
  billIssueDate: "",
  billDueDate: "",
  isConfirmed: false,
  billDescription: "",
  amountDescription: "",
  isDeleted: false,
  buildingId: 0,
  billDetailList: [],
  pgcode: "",
};
const ScheduleInfo = ({ basicInfo, callList, contractBaseInfo, roundList, overdueList: ol, stopObj, setStopObj }: any) => {
  const scheduleId = useMemo(() => {
    return contractBaseInfo?.scheduleList?.find((sc: any) => sc.supplyType === "RENTAL")!.scheduleId!;
  }, [contractBaseInfo]);
  // 청구서 저장
  const { executeAsync: addBill } = useApiOperation(mntBillAdd);
  const { executeAsync: getProviderDetail } = useApiOperation(providerDetailAsync);
  const { executeAsync: getRoundList } = useApiOperation(getScheduleRoundNOverdueList);
  const { executeAsync: updateBill } = useApiOperation(mntBillUpdate);
  const [tableData, setTableData] = useState<any>(roundList);
  const [overdueList, setOverdueList] = useState<any>(ol);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isApiLoading } = useApiLoading();
  const tableRef = useRef<HTMLTableElement>(null);

  const [cols, setCols] = useState<any>([]);

  const { openToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [provider, setProvider] = useState<any>();
  const [showMakeBill, setShowMakeBill] = useState(false);
  const [bill, setBill] = useState<Bill>({
    billId: 0,
    billStartTime: "",
    billEndTime: "",
    billIssueDate: "",
    billDueDate: "",
    isConfirmed: false,
    billDescription: "",
    amountDescription: "",
    buildingId: 0,
    taxInvoiceIssueDate: null,
    billDetailList: [],
    pgcode: "",
  });
  const [messageModal, setMessageModal] = useState<Modal>({
    isOpen: false,
  });
  const onSubmit = useCallback(
    async (e?: any) => {
      setIsOpen(false);
      console.log("e", e);
      if (setStopObj) {
        setStopObj({});
        setStopObj(e);
      }
      await callList();
    },
    [callList, setStopObj],
  );
  const checkOverdue = useCallback(() => {
    const today = moment().format(ViewYmdFormat.FULL);
    const checkList = _.cloneDeep(roundList);
    const checkList2 = _.cloneDeep(overdueList);
    let flag = false;
    for (let i = 0; i < checkList.length; i++) {
      const check = checkList[i];
      const formatted = moment(check.bill.billDueDate).format(ViewYmdFormat.FULL);

      // console.log("check", check);
      if (moment(formatted).isSameOrBefore(moment(today))) {
        if (check.bill.billPayStatus === "PAYMENT_READY") {
          flag = true;
          break;
        }
      }
    }
    for (let i = 0; i < checkList2.length; i++) {
      const check2 = checkList2[i];
      const formatted = moment(check2.bill.billIssueDate).format(ViewYmdFormat.FULL);

      // console.log("check", check);
      if (moment(formatted).isSameOrBefore(moment(today))) {
        if (check2.bill.billPayStatus === "PAYMENT_READY") {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }, [roundList, overdueList]);
  const getRounds = useCallback(
    async (scheduleId: string) => {
      if (scheduleId) {
        setTableData([]);
        setOverdueList([]);
        const rounds = await getRoundList({ scheduleId: Number(scheduleId) });

        if (rounds && rounds.status >= 200 && rounds.status <= 299) {
          const sorted: any = rounds.data.data.content;

          const normal_unsorted = sorted.filter((sort: any) => {
            if (sort.bill.isOverdueBill === false) {
              return sort;
            }
          });

          const overdues_unsorted = sorted.filter((sort: any) => {
            if (sort.bill.isOverdueBill) {
              return sort;
            }
          });
          let normal = _.sortBy(normal_unsorted, ["bill.billIssueDate"]);
          let overdues = _.sortBy(overdues_unsorted, ["bill.billIssueDate"]);

          const ovs: any = [];
          normal.forEach((sh: any) => {
            const find = overdues.find((ov: any) => ov.bill.parentBillId === sh.bill.billId);
            if (find) {
              ovs.push(find);
            }
          });
          await setTableData(normal);
          await setOverdueList(ovs);
        }
      }
    },
    [getRoundList],
  );

  const openPop = useCallback(() => {
    const result = checkOverdue();
    if (result) {
      openToast({ content: "연체/수납 대기 중인 청구서가 있습니다. 중지처리 전에 수납을 완료 해주세요", isSystemToast: true });
      return;
    } else {
      setIsOpen(true);
    }
  }, [checkOverdue, openToast]);
  const showBtn = useMemo(() => {
    let flag = true;

    if (location.pathname.indexOf("/detail/") > -1) {
      flag = false;
    }

    return flag;
  }, [location]);

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, page, rows, prepareRow } = useTable(
    {
      columns: cols,
      data: tableData,
      initialState: { pageSize: 1000 },
      contractBaseInfo,
      callList: getRounds,
      basicInfo,
      stopObj,
      scheduleId,
      showBtn,
      type: "schedule",
      messageModal,
      setMessageModal,
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useSticky,
    usePagination,
    useRowSelect,
  );
  const getMntProvider = useCallback(
    async (providerId: string) => {
      if (providerId) {
        const mntPd: any = await getProviderDetail({ providerId });
        if (mntPd && mntPd.data && mntPd.data.data) {
          setProvider(mntPd.data.data);
        }
      }
    },
    [getProviderDetail],
  );
  const onClosePopup = useCallback(async () => {
    setShowMakeBill(false);
    // setSelectedBuildingId(buildingId);
    // setSelectedScheduleId(scheduleId);
    await getRounds(scheduleId);
    setBill(initBill);
  }, [getRounds, scheduleId]);

  /**
   * 저장 버튼 클릭
   */
  const saveBill = useCallback(
    async (detailList: any) => {
      const temp = _.cloneDeep(bill);
      console.log("temp", temp);
      // if (temp.buildingId === 0) {
      // temp.buildingId = buildingId;
      // }

      if (temp.scheduleId === 0) {
        temp.scheduleId = scheduleId;
      }
      if (temp.billStartTime !== "") {
        temp.billStartTime = moment(temp.billStartTime).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.billEndTime !== "") {
        temp.billEndTime = moment(temp.billEndTime).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.billIssueDate !== "") {
        temp.billIssueDate = moment(temp.billIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.billDueDate !== "") {
        temp.billDueDate = moment(temp.billDueDate).format(YmdFormat.WITH_TIME_ZONE);
      }
      if (temp.pgcode !== "creditcard" && temp.taxInvoiceIssueDate !== null) {
        temp.taxInvoiceIssueDate = moment(temp.taxInvoiceIssueDate).format(YmdFormat.WITH_TIME_ZONE);
      } else {
        delete temp.taxInvoiceIssueDate;
      }
      temp.billDetailList = detailList;
      delete temp.buildingId;

      setBill(temp);
      console.log("temp", temp);
      // 저장 기능 처리
      return await addBill({ scheduleId: scheduleId, body: temp });
    },
    [addBill, bill, scheduleId],
  );

  const onConfirmBill = useCallback(async () => {
    const temp = _.cloneDeep(bill);
    const send: any = { billId: temp.billId };
    send.isConfirmed = !temp.isConfirmed;
    if (scheduleId) {
      const rnt = await updateBill({ scheduleId: scheduleId, body: send });
      if (rnt.status >= 200 && rnt.status < 400) {
        setBill({ ...temp, ...send });
      }
      return rnt;
    }
  }, [bill, scheduleId, updateBill]);
  const checkProviderData = useCallback(() => {
    let check = true;

    if (basicInfo?.product?.productType !== "TIME_COURT") {
      if (
        Array.isArray(provider!.providerAccountList) &&
        (provider!.providerAccountList!.length === 0 ||
          provider!.providerAccountList!.filter((data: any) => data.accountType === "RENT_ACCOUNT")!.length === 0)
      ) {
        openToast({ content: "해당 정산정보의 연동정보에 보증금 계좌정보를 등록해 주세요.", isSystemToast: true });
        check = false;
        return check;
      }
    }
    if (contractBaseInfo!.contractManage!.mbOrganizationId === "0") {
      openToast({ content: "해당 계약에 사업자 정보를 등록해 주세요.", isSystemToast: true });
      check = false;
      return check;
    }
    // console.log("check", check);
    return check;
  }, [basicInfo?.product?.productType, contractBaseInfo, openToast, provider]);

  const handleAddBill = useCallback(() => {
    const flag = checkProviderData();
    if (flag) {
      setShowMakeBill(true);
    }
  }, [checkProviderData]);
  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);
  const checkAuto = useMemo(() => {
    let rtnBool = { isVerifiedPaymentKey: false, isVerifiedSearchKey: false };
    const payletterList = contractBaseInfo?.provider?.payletterList;
    if (payletterList && payletterList.length > 0) {
      const finded: any = payletterList.find((payletter: any) => payletter.clientType === "AUTO");
      if (finded) {
        rtnBool.isVerifiedPaymentKey = finded.isVerifiedPaymentKey;
        rtnBool.isVerifiedSearchKey = finded.isVerifiedSearchKey;
      }
    }
    return rtnBool;
  }, [contractBaseInfo]);
  useEffect(() => {
    let list = _.cloneDeep(ScheduleInfoColumns);

    if (basicInfo?.product?.productType === "TIME_COURT") {
      list = _.cloneDeep(S2Columns);
    }
    if (!provider) {
      getMntProvider(basicInfo?.provider?.provider?.providerId!);
    }
    if (tableData.length === 0) {
      getRounds(scheduleId);
    }

    // console.log("bill", bill);
    if (bill.pgcode === "" && basicInfo?.scheduleList) {
      const temp = _.cloneDeep(bill);
      const sd: any = basicInfo?.scheduleList?.find((schedule: any) => schedule.supplyType === "RENTAL");
      temp.pgcode = sd.regularPgcode;
      setBill(temp);
    }

    setCols(list);
  }, [basicInfo, getMntProvider, scheduleId, getRounds, bill, setBill, tableData.length]);
  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article" style={{ maxWidth: "unset" }}>
            <div className="flex-center-between mb10">
              <div className="left-area">
                <BaseSectionTitle title={"이용료 빌링 현황"} />
                <div className="contents-container__sub-title flex-center-start gap10">
                  {basicInfo?.product?.productType !== "TIME_COURT" && (
                    <>
                      <BaseButton
                        title={"빌링스케줄 중지 설정"}
                        className="color-white size-small"
                        disabled={contractBaseInfo?.contract?.contractStep !== "TERMINATE_RECEIVED"}
                        onClick={openPop}
                      />
                      <BaseTooltip
                        contents={""}
                        tooltip={
                          "대기/연체 청구서가 있을 시 빌링스케줄을 중지할 수 없습니다. 계약의 상태가 ‘해지접수’ 상태일 때 빌링 중지 가능합니다."
                        }
                        type={"normal"}
                        className={"tooltip__trigger-icon"}
                      />
                      <p className="font14 ml5">
                        스케줄 상태 :{" "}
                        {stopObj && stopObj?.isContractCancel === true
                          ? "중지 (사유: " +
                            (stopObj?.contractCancelReason !== "" && stopObj?.contractCancelReason !== null
                              ? stopObj?.contractCancelReason
                              : "미입력") +
                            ") " +
                            (stopObj?.contractCancelDate !== null ? moment(stopObj?.contractCancelDate).format(ViewYmdFormat.FULL) : "미입력")
                          : "정상"}
                      </p>
                    </>
                  )}
                  {isOpen && (
                    <StopModal
                      showBtn={true}
                      setIsOpen={setIsOpen}
                      round={roundList.billOrder}
                      scheduleId={scheduleId}
                      totalRound={rows}
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      onClick={onSubmit}
                    />
                  )}
                </div>
              </div>
              <div className="right-area">
                {contractBaseInfo?.product?.productType !== "TIME_COURT" && (
                  <BaseButton
                    title="청구서 생성"
                    onClick={handleAddBill}
                    disabled={contractBaseInfo?.contract?.contractStep === ContractStep.APPLY_CANCEL}
                  />
                )}
                {showMakeBill && (
                  <MakeBillPopup
                    isChange={showMakeBill}
                    contractDetail={contractBaseInfo}
                    showBtn={showBtn}
                    bill={_.cloneDeep(bill)}
                    setBill={setBill}
                    onClose={onClosePopup}
                    onConfirm={onConfirmBill}
                    onClick={saveBill}
                    messageModal={messageModal}
                    setMessageModal={setMessageModal}
                    getRounds={getRounds}
                    allBill={bill}
                    buildingId={"0"}
                    scheduleId={scheduleId || "0"}
                  />
                )}
                {messageModal.isOpen && (
                  <BaseModal
                    isOpen={messageModal.isOpen}
                    btnRightTitle={"확인"}
                    onClick={async () => {
                      setMessageModal({ ...messageModal, isOpen: false });
                    }}
                    title={messageModal.message}
                  ></BaseModal>
                )}
              </div>
            </div>
            <div>
              <p className="font14 ml5 mb5">
                프로바이더 : {contractBaseInfo?.provider?.provider?.providerName && contractBaseInfo?.provider?.provider?.providerName}
              </p>
            </div>
            <div className="flex-center">
              <div>
                <p className="font14 ml5 mb5">팝빌 연동 정보 :</p>
                <p className="font14 ml5 mb5">
                  - 현금영수증 조회 API : {contractBaseInfo?.provider?.provider?.isVerifiedCashbill ? "TRUE" : "FALSE"}
                </p>
                <p className="font14 ml5 mb5">
                  - 세금 계산서 발행 API : {contractBaseInfo?.provider?.provider?.isVerifiedTaxInvoice ? "TRUE" : "FALSE"}
                </p>
              </div>
              {basicInfo?.product?.productType === "TIME_COURT" && (
                <div className="ml20">
                  <p className="font14 ml5 mb5">페이레터 연동 정보 :</p>
                  <p className="font14 ml5 mb5">- PaymentKey API : {checkAuto.isVerifiedPaymentKey ? "TRUE" : "FALSE"}</p>
                  <p className="font14 ml5 mb5">- Search Key API : {checkAuto.isVerifiedSearchKey ? "TRUE" : "FALSE"}</p>
                </div>
              )}
            </div>
            <ScrollShadowProvider tableRef={tableRef} className="mt30">
              <div {...getTableProps()} ref={tableRef} className="base-table sticky">
                <div className="header">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                      {headerGroup.headers.map((header) => {
                        return (
                          <div {...header.getHeaderProps()} className="base-table__th">
                            {header.render("Header")}
                            {/* <div className="ic_sort"></div> */}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
                <div {...getTableBodyProps()} className="body">
                  {page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <div {...row.getRowProps()} className={`base-table__tr ${row.values.floor ? "bg-gray100" : ""}`}>
                        {row.cells.map((cell: any) => {
                          return (
                            <div {...cell.getCellProps()} className="base-table__td">
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {page.length === 0 && (
                    <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                      <div className="base-table__td w-100 text-center">
                        <div className="w-100">{!isApiLoading() && <span>데이터가 없습니다.</span>}</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="footer">
                  {footerGroups.map((footerGroup) => (
                    <div {...footerGroup.getHeaderGroupProps()} className="base-table__tr">
                      {footerGroup.headers.map((column) => (
                        <div {...column.getHeaderProps()} className="base-table__td base-table-footer">
                          {column.render("Footer")}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </ScrollShadowProvider>
          </article>
          {basicInfo?.product?.productType !== "TIME_COURT" && (
            <OverdueInfo
              basicInfo={basicInfo}
              overdueList={overdueList}
              callList={getRounds}
              contractBaseInfo={contractBaseInfo}
              scheduleId={scheduleId}
              showBtn={showBtn}
              messageModal={messageModal}
              setMessageModal={setMessageModal}
            />
          )}
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <GoToListButton />
        </div>
        <div className="right-area">
          <BaseButton
            title="수정 취소"
            className="color-white size-large ml8"
            onClick={() => {
              navigate(pagePath.billing.detail.replace(":id", String(params.id) + "?tab=schedule"));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ScheduleInfo;
