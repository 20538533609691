import { useEffect, useMemo, useState } from "react";
import { useBlockLayout, useGlobalFilter, useRowSelect, useTable } from "react-table";
import { getCommonFacilityListAsync } from "src/api/building/building-api";
import { CommonFacilityListModel, CommonFacilityListParams } from "src/api/building/building-types";
import { getContractFacilityListSync } from "src/api/contract/contract-facility-api";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseInputWithSearch, BasePagination, BaseSelect } from "src/components";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { rvFacilityColumns } from "../columns/RvFacilityColumns";

type Props = {
  onClick: (data: CommonFacilityListModel) => void;
  onClose: () => void;
  contractId: string;
};

const FACILITY_OPTIONS = [
  {
    value: "",
    label: "전체",
  },
  {
    value: "MEETING_ROOM",
    label: "회의실",
  },
  {
    value: "DESK",
    label: "좌석",
  },
  {
    value: "REFRESH_ROOM",
    label: "편의시설",
  },
];

const RvFacilityListModal = ({ onClick, onClose, contractId }: Props) => {
  const defaultParams: CommonFacilityListParams = {
    page: 0,
    size: 20,
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
    searchType: "FACILITY_NAME",
  };

  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<CommonFacilityListParams>(defaultParams);
  const [facilityList, setFacilityList] = useState<CommonFacilityListModel[]>([]);
  const [buildingOptions, setBuildingOptions] = useState<{ value: string; label: string }[]>([{ value: "", label: "전체" }]);

  //계약에 연결된 공용공간 목록 보기
  const { executeAsync: getContractFacilitys } = useApiOperation(getContractFacilityListSync);

  // 건물에 등록된 공용공간 보기
  const { executeAsync: getFacilityList } = useApiOperation(getCommonFacilityListAsync);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = useTable(
    {
      columns: rvFacilityColumns,
      data: facilityList,
    },
    useBlockLayout,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 60,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();

            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedOrg"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    getContractFacilityIds();
  }, []);

  useEffect(() => {
    if (params.buildingCommonFacilityIdList) {
      fetchFacilityList(params);
    }
  }, [params.buildingCommonFacilityIdList]);

  const getContractFacilityIds = async () => {
    if (!contractId) return;
    const { data } = await getContractFacilitys({ contractId });
    const facilityIds = data.data.content?.ctProductFacility.map((item) => item.id).join(",");
    setParams({ ...params, buildingCommonFacilityIdList: facilityIds });
  };

  //공용공간 목록
  const fetchFacilityList = async (request: CommonFacilityListParams) => {
    const { data: facilities } = await getFacilityList(request);
    const result = facilities.data.content;

    const buildingsData = result.map((item) => ({
      value: String(item.buildingId),
      label: String(item.buildingName),
    }));

    setFacilityList(result);
    updateBuildingOptions(buildingsData);
    setPageMeta(facilities.meta.pageMeta);
  };

  //건물 옵션
  const updateBuildingOptions = (newBuildings: typeof buildingOptions) => {
    setBuildingOptions((prev) => {
      // 전체 옵션은 항상 유지
      const defaultOption = { value: "", label: "전체" };

      // 기존 옵션과 새로운 옵션을 합치고 중복 제거
      const allOptions = [...prev, ...newBuildings];
      const uniqueOptions = [
        defaultOption,
        ...Array.from(
          new Map(
            allOptions
              .filter((optoin) => optoin.value !== "") // "전체" 옵션 제외
              .map((optoin) => [optoin.value, optoin]),
          ).values(),
        ),
      ];

      return uniqueOptions;
    });
  };

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <>
      <section className="base-abstract-modal__title flex-center-between ">
        <h1>공용공간 선택</h1>
        <div className=" right-area">
          <div className="flex-center">
            <BaseSelect
              className="minmax120 mr5"
              stateOptions={buildingOptions}
              value={params.buildingIdList}
              placeholder="건물"
              setStateValue={async (value: string) => {
                setParams({ ...params, buildingIdList: value });
                await fetchFacilityList({ ...params, buildingIdList: value });
              }}
            />
            <BaseSelect
              className="minmax120 mr5"
              stateOptions={FACILITY_OPTIONS}
              value={params.commonFacilityTypeList}
              placeholder="공용공간 유형"
              setStateValue={async (value: string) => {
                setParams({ ...params, commonFacilityTypeList: value });
                await fetchFacilityList({ ...params, commonFacilityTypeList: value });
              }}
            />

            <BaseInputWithSearch
              inputValue={params.keywordList}
              selectValue={params.searchType}
              stateOptions={[
                {
                  value: "FACILITY_NAME",
                  label: "공용공간명",
                },
              ]}
              onKeyUp={() => fetchFacilityList({ ...params, page: 0 })}
              onChange={(keywordList: string) => {
                setParams({ ...params, keywordList });
              }}
              setStateValue={(searchType: string) => {
                setParams({ ...params, searchType });
              }}
              onSearchClick={() => fetchFacilityList({ ...params, page: 0 })}
            />
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table px30">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps()} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {rows.length && rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={params.page || 0}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size, page: 0 });
            fetchFacilityList({ ...params, size, page: 0 });
          }}
          goPage={(page: number) => {
            setParams({ ...params, page });
            fetchFacilityList({ ...params, page });
          }}
          totalElements={pageMeta?.totalElements || 0}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => onClose()}>
                취소
              </button>
              <button
                className="base-btn"
                onClick={() => {
                  const selected = selectedFlatRows[0].original;

                  onClick(selected);
                  onClose();
                }}
              >
                선택
              </button>
            </div>
          }
        />
      </section>
    </>
  );
};

export default RvFacilityListModal;
