import moment from "moment";
import { Cell } from "react-table";
import { CctvDevice } from "src/api/cctv/cctv-types";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { ViewYmdFormat } from "src/utils";

const cctvListColumns = [
  {
    Header: "CCTV ID",
    accessor: "id",
    width: 120,
    Cell: (props: Cell<CctvDevice>) => {
      const detailPath = `${PagePath.cctv.detail.replace(":id", `${props.value}`)}`;
      return (
        <div>
          <DetailLink className="text-underline" to={detailPath}>
            {props.value || "-"}
          </DetailLink>
        </div>
      );
    },
  },
  {
    Header: "Camera Group",
    accessor: "cameraGroup",
    width: 160,
    Cell: (props: Cell<CctvDevice>) => {
      return <BaseTooltip className="text-left w-full" contents={props.value || "-"} />;
    },
  },
  {
    Header: "Camera ID",
    accessor: "cameraId",
    width: 160,
    Cell: (props: Cell<CctvDevice>) => {
      return <BaseTooltip className="text-left w-full" contents={props.value || "-"} />;
    },
  },
  {
    Header: "장치명",
    accessor: "name",
    width: 240,
    Cell: (props: Cell<CctvDevice>) => {
      return <BaseTooltip className="text-left w-full" contents={props.value || "-"} />;
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 120,
    Cell: (props: Cell<CctvDevice>) => {
      const buildingName = props?.row?.original?.cctvDevicePartnerList?.[0]?.buildingName;
      return <BaseTooltip className="text-left w-full" contents={buildingName || "-"} />;
    },
  },
  {
    Header: "층",
    accessor: "floorNum",
    width: 80,
    Cell: (props: Cell<CctvDevice>) => {
      const floorNum = props?.row?.original?.cctvDevicePartnerList?.[0]?.floorNum;
      return <div className="text-center">{floorNum ? `${floorNum > 0 ? "지상" : "지하"} ${floorNum}층` : "-"}</div>;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: (props: Cell<CctvDevice>) => {
      return <BaseTooltip contents={props.value ? props.value : "-"} type="date" />;
    },
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 120,
    Cell: (props: Cell<CctvDevice>) => {
      return <BaseTooltip contents={props.value || "-"} />;
    },
  },
];

export default cctvListColumns;
