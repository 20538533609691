import { useMemo } from "react";
import { ContentsTitle } from "src/components";
import { useQueryParams } from "src/hooks/useQueryParams";
import BasicInfoDetail from "./basicInfoDetail/BasicInfoDetail";

/* 
  CCTV 장치 > 상세
 */
const tabs = [{ value: "basicInfo", label: "기본정보" }];

const CCTVDeviceDetail = () => {
  const { queryParams } = useQueryParams();

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  return (
    <div className="">
      <ContentsTitle
        title="CCTV 장치"
        tabs={tabs}
        activeTab={activeTab}
        detailInfo={[{ title: "CCTV ID", value: queryParams.id ? String(queryParams.id) : "" }]}
      />
      {activeTab?.value === "basicInfo" && <BasicInfoDetail />}
    </div>
  );
};
export default CCTVDeviceDetail;
