/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { getBuildingsAsync } from "src/api/building/building-api";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractStep } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { getNoticeDetailAsync, postNoticeAsync, putNoticeAsync } from "src/api/notice/notice-api";
import { NoticeModel } from "src/api/notice/notice-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect, BaseToggle } from "src/components";
import MarkdownEditor from "src/components/MarkdownEditor";
import RangeDatepicker from "src/components/RangeDatepicker";
import MetaTag from "src/components/layout/MetaTag";
import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { YmdFormat } from "src/utils";
import BuildingSelectModal from "./components/BuildingSelectModal";
import ContractSelectModal from "./components/ContractSelectModal";
import { NoticeCategory } from "../../notice-types";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useRecoilValue } from "recoil";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import useDynamicHeight from "src/hooks/useDynamicHeight";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { FullDatePicker } from "src/components/FullDatePicker";
/**
 * feature:: rangeDataPicker에서 1개의 react-hook-form 컨트롤러를 사용하므로
 * 상세 데이터 호출 시 displayDate를 setValue 해줌. 타입은 dateRange state와 같다.
 */

export const INVITEABLE_CONTRACT_STEP = [
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.TERMINATE_RECEIVED,
];

type Contract = {
  contractId: number;
  contractApplyNumber: string;
  contractStep?: ContractStep;
  spaceProductName: string;
};
type Building = {
  buildingId: number;
  buildingName: string;
};

type PayloadContracts = {
  contractId: number;
  cmdType: "C" | "D" | "U";
};

const noticeCategory = [
  { label: "점검/보수", value: NoticeCategory.FACILITY_MAINTENANCE },
  { label: "입주 안내", value: NoticeCategory.MOVE_IN_GUIDE },
  { label: "시설 안내", value: NoticeCategory.FACILITY_GUIDE },
  { label: "행사 혜택", value: NoticeCategory.EVENTS_BENEFITS },
  { label: "설문 조사", value: NoticeCategory.SURVEY },
  { label: "기타", value: NoticeCategory.ETC },
];

const BasicInfoForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const id: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [notice, setNotice] = useState<NoticeModel>({});
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [isContractSelectModalOpen, setIsContractSelectModalOpen] = useState({ isOpen: false });
  const [isBuildingSelectModalOpen, setIsBuildingSelectModalOpen] = useState({ isOpen: false });

  const { executeAsync: getNoticeDetail } = useApiOperation(getNoticeDetailAsync);
  const { executeAsync: postNoticeDetail } = useApiOperation(postNoticeAsync);
  const { executeAsync: putNoticeDetail } = useApiOperation(putNoticeAsync);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);

  const checkHeightRefBuilding = useRef<HTMLDivElement | null>(null);
  const maxHeightBuilding = useDynamicHeight(checkHeightRefBuilding);

  const partner = useRecoilValue(globalPartnerState);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    trigger,
    formState: { dirtyFields, errors },
  } = useForm<any>({
    defaultValues: {
      contracts: [],
      buildings: [],
      title: "",
      isDisplayed: false,
      displayStartDate: "",
      displayEndDate: "",
      noticeCategory: "",
    },
  });

  const isAnythingChanged = () => {
    let changed = false;

    if (id) {
      const originContractNumberList = notice?.contracts?.map((contract) => Number(contract.contractId)) || [];
      const nowContractNumberList = contracts?.map((contract) => Number(contract.contractId));
      const originBuildingNumberList = notice?.buildings?.map((building) => Number(building.buildingId)) || [];
      const nowBuildingNumberList = buildings?.map((building) => Number(building.buildingId));

      const arraysMatch = (origin: Array<number>, now: Array<number>) => {
        if (origin.length !== now.length) {
          return false;
        }
        const sortedOrigin = origin.slice().sort();
        const sortedNow = now.slice().sort();

        return sortedOrigin.every((element, index) => element === sortedNow[index]);
      };

      if (!arraysMatch(originContractNumberList, nowContractNumberList) || !arraysMatch(originBuildingNumberList, nowBuildingNumberList)) {
        changed = true;
      }

      if (notice?.title !== getValues("title")) {
        changed = true;
      }
      if (notice?.content !== getValues("content")) {
        changed = true;
      }
      if (notice?.isDisplayed !== getValues("isDisplayed")) {
        changed = true;
      }
      if (notice?.noticeCategory !== getValues("noticeCategory")) {
        changed = true;
      }

      const formattedOriginStartDate = moment(notice?.displayStartDate).format(YmdFormat.WITH_TIME_ZONE);
      const formattedNowStartDate = moment(getValues("displayStartDate")).format(YmdFormat.WITH_TIME_ZONE);

      const formattedOriginEndDate = moment(notice?.displayEndDate).format(YmdFormat.WITH_TIME_ZONE);
      const formattedNowEndDate = moment(getValues("displayEndDate")).format(YmdFormat.WITH_TIME_ZONE);

      if (formattedOriginStartDate !== formattedNowStartDate || formattedOriginEndDate !== formattedNowEndDate) {
        changed = true;
      }
    } else {
      if (
        contracts.length > 0 ||
        buildings.length > 0 ||
        getValues("title") ||
        getValues("content") ||
        getValues("isDisplayed") ||
        getValues("displayStartDate") ||
        getValues("displayEndDate")
      ) {
        changed = true;
      }
    }

    return changed;
  };

  useEffect(() => {
    const requiredMessage = "필수 입력 항목입니다";
    register("noticeCategory", {
      required: requiredMessage,
    });
    register("title", {
      required: requiredMessage,
      maxLength: { value: 200, message: "200자 이상 입력 불가능 합니다." },
    });
    register("content", {
      required: requiredMessage,
    });
    register("displayStartDate", {
      required: requiredMessage,
    });
    register("displayEndDate", {
      required: requiredMessage,
    });
  }, [register]);

  const fetchApi = useCallback(async () => {
    const { data } = await getNoticeDetail({ id: String(id) });
    if (data.data.notice) {
      const notice = data.data.notice;
      setNotice(notice);
      setValue("title", notice.title);
      setValue("content", notice.content, {});
      setValue("isDisplayed", notice.isDisplayed);
      setValue("noticeCategory", notice.noticeCategory);
      if (notice.displayEndDate && notice.displayStartDate) {
        setValue("displayStartDate", notice.displayStartDate);
        setValue("displayEndDate", notice.displayEndDate);
      }
      if (notice.contracts && notice.contracts.length > 0) {
        const contractNumberList: Array<number> = notice.contracts.map((contract) => contract.contractId);
        const { data: contractList } = await getContractList({ page: 0, size: 99, contractIds: contractNumberList.join(",") }); // 계약목록 조회

        if (contractList.data.content) {
          const contracts = contractList.data.content;

          const newContracts = contracts.map((contract: any) => {
            let tempContracts: Contract = {
              contractId: 0,
              contractApplyNumber: "",
              spaceProductName: "",
            };
            tempContracts.contractId = contract.contractId;
            tempContracts.contractApplyNumber = contract.contractApplyNumber;
            tempContracts.spaceProductName = contract.spaceProductName;
            return tempContracts;
          });

          setContracts(newContracts);
        }
      }
      if (notice.buildings && notice.buildings.length > 0) {
        const buildingNumberList: Array<number> = notice.buildings.map((item) => item.buildingId);
        const { data: buildingList } = await getBuildingList({ page: 0, size: 99, id: buildingNumberList.join(",") }); // 건물목록 조회
        if (buildingList.data.content) {
          const buildings = buildingList.data.content;
          const newBuildings = buildings.map((building: any) => {
            let tempBuildings = {
              buildingId: 0,
              buildingName: "",
            };
            tempBuildings.buildingId = building.id;
            tempBuildings.buildingName = building.buildingName;
            return tempBuildings;
          });
          setBuildings(newBuildings);
        }
      }
    }
  }, [id, getNoticeDetail, getContractList, getBuildingList, setValue]);

  useEffect(() => {
    if (id) {
      fetchApi();
    }
  }, [fetchApi, id]);

  const onSubmit = useCallback(
    async (data: any, e?: any) => {
      e.preventDefault();
      let noticeData: NoticeModel = {
        title: "",
        contracts: [],
        buildings: [],
        content: "",
        // type: "SPACE",
        isDisplayed: false,
        displayStartDate: "",
        displayEndDate: "",
        noticeCategory: "",
      };

      let tempNoticeData = { ...noticeData, ...data };
      // 등록일때 무조건 C타입
      if (!id) {
        tempNoticeData.contracts = contracts?.map((item: Contract) => {
          return {
            contractId: Number(item.contractId),
            cmdType: "C",
          };
        });
        tempNoticeData.buildings = buildings?.map((item: Building) => {
          return {
            buildingId: Number(item.buildingId),
            cmdType: "C",
          };
        });
      }
      // 수정일때 C or D 타입
      if (id) {
        const originNoticeContractsNumberList: Array<number> = notice?.contracts?.map((item) => Number(item.contractId)) || [];
        const originNoticeBuildingsNumberList: Array<number> = notice?.buildings?.map((item) => Number(item.buildingId)) || [];
        const contractNumberList: Array<number> = contracts?.map((item) => Number(item.contractId)) || [];
        const buildingNumberList: Array<number> = buildings?.map((item) => Number(item.buildingId)) || [];
        const addedContract = contracts?.filter((contractItem) => !originNoticeContractsNumberList?.includes(Number(contractItem.contractId)));
        const addedBuilding = buildings?.filter((buildingItem) => !originNoticeBuildingsNumberList?.includes(Number(buildingItem.buildingId)));
        const deletedContractOrigin = notice.contracts?.filter((contract) => {
          return !contractNumberList?.includes(Number(contract.contractId));
        });
        const deletedBuildingOrigin = notice.buildings?.filter((building) => {
          return !buildingNumberList?.includes(Number(building.buildingId));
        });

        // 추가되는항목
        if (addedContract) {
          const addedContractList = addedContract.map((item) => {
            return {
              contractId: Number(item.contractId),
              cmdType: "C",
            };
          });
          tempNoticeData.contracts = [...tempNoticeData.contracts, ...addedContractList];
        }

        if (addedBuilding.length > 0) {
          const addedBuildingList = addedBuilding.map((item) => {
            return {
              buildingId: Number(item.buildingId),
              cmdType: "C",
            };
          });
          tempNoticeData.buildings = [...tempNoticeData.buildings, ...addedBuildingList];
        }

        // 제거되는 항목
        if (deletedContractOrigin) {
          const deletedContractList = deletedContractOrigin.map((item) => {
            return {
              id: item.id,
              cmdType: "D",
            };
          });
          tempNoticeData.contracts = [...tempNoticeData.contracts, ...deletedContractList];
        }

        if (deletedBuildingOrigin) {
          const deletedBuildingList = deletedBuildingOrigin.map((item) => {
            return {
              id: item.id,
              cmdType: "D",
            };
          });
          tempNoticeData.buildings = [...tempNoticeData.buildings, ...deletedBuildingList];
        }
      }

      const { ...payload } = tempNoticeData;

      if (id) {
        // 수정
        setConfirmModal({ isOpen: true, message: "수정하시겠습니까?", type: "editNotice", payload: payload });
      } else {
        // 등록
        setConfirmModal({ isOpen: true, message: "저장하시겠습니까?", type: "addNotice", payload: payload });
      }

      e.target.reset();
      // return false;
    },
    [id, contracts, buildings, notice],
  );

  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    return false;
  };

  const onAddedContractSelectModal = (contracts: Contract[]) => {
    if (!contracts) return;
    setContracts(contracts);
    setIsContractSelectModalOpen({ isOpen: false });
  };
  const onAddedBuildingSelectModal = (
    buildingList: {
      buildingId: number;
      buildingName: string;
    }[],
  ) => {
    if (!buildingList) return;
    setBuildings(buildingList);
    setIsBuildingSelectModalOpen({ isOpen: false });
  };

  const handleDeleteContract = (id: number) => {
    const filteredDeletedContract = contracts!.filter((contract) => contract.contractId !== id);
    setContracts(filteredDeletedContract);
  };

  const handleDeleteBuilding = (id: number) => {
    const filteredDeletedBuilding = buildings!.filter((building) => building.buildingId !== id);
    setBuildings(filteredDeletedBuilding);
  };

  return (
    <>
      {id ? <MetaTag subTitle={notice?.title || ""} /> : <MetaTag isCreatePage />}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="contents-container__scroll">
          <div className="contents-container__wrap">
            <article className="contents-container__wrap-article">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <div className="flex-center">
                    <p className="required">대상 설정</p>
                  </div>
                </div>
                <div className="contents-container__grid-contents">
                  <div className={`flex-row ${maxHeightBuilding > 40 ? "flex-start-start" : "flex-center-start"}`}>
                    <BaseButton
                      type="button"
                      className="color-white size-medium mr8"
                      title="선택"
                      onClick={() => {
                        (buildings && buildings.length > 0) || (contracts && contracts.length > 0)
                          ? setConfirmModal({ isOpen: true, type: "clearTarget", message: "기존 선택한 항목이 초기화됩니다" })
                          : setIsBuildingSelectModalOpen({ isOpen: true });
                      }}
                    />
                    {/* <span className="ml8 text-primary3">
                      {buildings.length <= 0 ? "*공지할 건물을 지정할 수 있습니다. (미선택시 전체 노출)" : ""}
                    </span> */}
                    <div className="flex-files" ref={checkHeightRefBuilding}>
                      {buildings?.map((item) => (
                        <div className="flex-files__wrap" key={item.buildingId}>
                          <BaseNewTabLink
                            path={`${PagePath.building.detail.replace(":id", String(item.buildingId) || "")}`}
                            value={item.buildingName}
                            className="w-100 text-left"
                          />
                          <div className="delete-btn-x" onClick={() => handleDeleteBuilding(item.buildingId)}></div>
                        </div>
                      ))}
                      {contracts?.map((contract) => (
                        <div className="flex-files__wrap" key={contract.contractId}>
                          <BaseNewTabLink
                            path={`${PagePath.building.detail.replace(":id", String(contract.contractId) || "")}`}
                            value={contract.spaceProductName}
                            className="w-100 text-left"
                          />
                          <div className="delete-btn-x" onClick={() => handleDeleteContract(contract.contractId)}></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">카테고리</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax210">
                    <Controller
                      control={control}
                      name="noticeCategory"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseSelect name={name} value={value} stateOptions={noticeCategory} setStateValue={onChange} errorText={error?.message} />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">제목</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax740">
                    <Controller
                      control={control}
                      name="title"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput name={name} value={value} onChange={onChange} errorText={error?.message} />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">내용</p>
                </div>
                <div className="contents-container__grid-contents contents-container__1200">
                  <div>
                    {!id && (
                      <Controller
                        control={control}
                        name="content"
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                          return <MarkdownEditor value={value} onChange={onChange} errorText={error?.message} />;
                        }}
                      ></Controller>
                    )}
                    {id && (
                      <Controller
                        control={control}
                        name="content"
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => {
                          return watch("content") !== undefined ? (
                            <MarkdownEditor value={value} onChange={onChange} errorText={error?.message} />
                          ) : (
                            <>-</>
                          );
                        }}
                      ></Controller>
                    )}
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="">공개 여부</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="">
                    <Controller
                      control={control}
                      name="isDisplayed"
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <BaseToggle
                            onChange={(checked: boolean) => {
                              onChange(checked);
                            }}
                            checked={value}
                            name={name}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">공개 기간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <Controller
                      control={control}
                      name="displayStartDate"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <>
                          <FullDatePicker
                            selectedDate={value ? moment(value).toDate() : null}
                            minDate={moment().toDate()}
                            minTime={
                              moment(value || new Date()).isSame(moment(), "day")
                                ? moment()
                                    .startOf("hour")
                                    .minutes(Math.floor(moment().minutes() / 5) * 5)
                                    .toDate()
                                : moment().startOf("day").toDate()
                            }
                            maxTime={moment().endOf("day").toDate()}
                            setDate={(selectedDate: Date) => {
                              const date = moment(selectedDate).format(YmdFormat.WITH_TIME_ZONE);
                              onChange(date);
                            }}
                            className="minmax180"
                            timeIntervals={5}
                            errorText={error?.message}
                          />
                        </>
                      )}
                    ></Controller>
                    <span className="mx10">-</span>
                    <Controller
                      control={control}
                      name="displayEndDate"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <>
                          <FullDatePicker
                            selectedDate={value ? moment(value).toDate() : null}
                            minDate={moment().toDate()}
                            minTime={
                              moment(value || new Date()).isSame(moment(), "day")
                                ? moment()
                                    .startOf("hour")
                                    .minutes(Math.floor(moment().minutes() / 5) * 5)
                                    .toDate()
                                : moment().startOf("day").toDate()
                            }
                            maxTime={moment().endOf("day").toDate()}
                            setDate={(selectedDate: Date) => {
                              const date = moment(selectedDate).format(YmdFormat.WITH_TIME_ZONE);
                              onChange(date);
                            }}
                            className="mr10 minmax180"
                            timeIntervals={5}
                            errorText={error?.message}
                          />
                        </>
                      )}
                    ></Controller>
                    {(errors?.displayStartDate || errors?.displayEndDate) && <div className="validation-text">필수입력 항목입니다</div>}
                  </div>
                </div>
              </section>
            </article>

            {confirmModal.isOpen && (
              <BaseModal
                title={confirmModal.message}
                isOpen={confirmModal.isOpen}
                btnLeftTitle={
                  confirmModal.type === "addNotice" || confirmModal.type === "editNotice" || confirmModal.type === "clearTarget" ? "취소" : "머무르기"
                }
                btnRightTitle={
                  confirmModal.type === "addNotice" || confirmModal.type === "editNotice" || confirmModal.type === "clearTarget" ? "확인" : "나가기"
                }
                onClick={async () => {
                  if (confirmModal.type === "addNotice") {
                    setConfirmModal({ isOpen: false });
                    const { data } = await postNoticeDetail({ notice: confirmModal.payload! });
                    if (data.data) {
                      navigate(PagePath.notice.list);
                    }
                  } else if (confirmModal.type === "editNotice") {
                    setConfirmModal({ isOpen: false });
                    const { data } = await putNoticeDetail({ notice: confirmModal.payload!, id: String(id)! });
                    if (data.data) {
                      navigate(`${PagePath.notice.detail.replace(":id", String(id))}`);
                    }
                  } else if (confirmModal.type === "checkChanged") {
                    if (id) {
                      setConfirmModal({ isOpen: false });
                      navigate(`${PagePath.notice.detail.replace(":id", String(id))}`);
                    } else {
                      setConfirmModal({ isOpen: false });
                      navigate(`${PagePath.notice.list}`);
                    }
                  } else if (confirmModal.type === "clearTarget") {
                    setBuildings([]);
                    setContracts([]);
                    setConfirmModal({ isOpen: false });
                    setIsBuildingSelectModalOpen({ isOpen: true });
                  }
                }}
                onClose={() => setConfirmModal({ isOpen: false })}
              >
                {confirmModal.type === "checkChanged" && (
                  <div>
                    <span>
                      입력 중인 내용이 있는 경우,
                      <br /> 저장을 하지 않은 정보는 사라집니다.
                    </span>
                  </div>
                )}
              </BaseModal>
            )}
          </div>
        </div>

        {/* 버튼영역 */}
        <div className="contents-container__btn-wrap">
          <div className="left-area"></div>
          <div className="right-area">
            {Number(id) > 0 ? (
              <BaseButton
                title="수정 취소"
                className="size-large color-white"
                type={"button"}
                onClick={() => {
                  if (isAnythingChanged()) {
                    setConfirmModal({ isOpen: true, type: "checkChanged", message: "현재 페이지에서 나가시겠습니까?" });
                  } else {
                    navigate(PagePath.notice.detail.replace(":id", String(id)));
                  }
                }}
              />
            ) : (
              <BaseButton
                title="취소"
                className="size-large color-white"
                type={"button"}
                onClick={() => {
                  if (isAnythingChanged()) {
                    setConfirmModal({ isOpen: true, type: "checkChanged", message: "현재 페이지에서 나가시겠습니까?" });
                  } else {
                    navigate(PagePath.notice.list);
                  }
                }}
              />
            )}
            <BaseButton title="저장" type={"submit"} className="size-large ml10" />
          </div>
        </div>
      </form>
      {isContractSelectModalOpen.isOpen && (
        <ContractSelectModal
          onCanceled={() => {
            setBuildings([]);
            setContracts([]);
            setIsContractSelectModalOpen({ isOpen: false });
          }}
          onAdded={onAddedContractSelectModal}
          defaultChecked={contracts ? contracts : []}
          contractStepFilter={INVITEABLE_CONTRACT_STEP}
          buildings={buildings}
        />
      )}
      {isBuildingSelectModalOpen.isOpen && (
        <BuildingSelectModal
          onCanceled={() => setIsBuildingSelectModalOpen({ isOpen: false })}
          onAdded={onAddedBuildingSelectModal}
          defaultChecked={buildings ? buildings : []}
          setContractModal={(value: Boolean) => {
            setIsContractSelectModalOpen({ isOpen: true });
            setIsBuildingSelectModalOpen({ isOpen: false });
          }}
        />
      )}
    </>
  );
};

export default BasicInfoForm;
