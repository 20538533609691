import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { getReservationListAsync, getReservationUsageListAsync } from "src/api/reservation/reservation-api";
import { ReservationListModel, ReservationListParams, ReservationUsageParams } from "src/api/reservation/reservation-types";
import { useModal } from "src/recoil/modalState/hook";
import { downloadExcel, downloadExcelV2, formatPhoneNumber, qsParse, ViewYmdFormat, YmdFormat } from "src/utils";
import { reservationColumns } from "../list/columns";
import { ExternalServiceType, getStatusText, reservationUsageHeaders } from "../reservation-types";
import { getCommonFacilityListAsync } from "src/api/contract/contract-facility-api";
import { CommonFacilityListModel } from "src/api/building/building-types";

// 변환된 데이터를 위한 새로운 타입

const useRv = () => {
  const location = useLocation();
  const { setBaseModal } = useModal();
  const reservationQueryParam: ReservationListParams = useMemo(() => {
    const _queryParams: ReservationListParams = qsParse(location.search);

    // 기본값 설정 로직...
    if (!_queryParams?.page) _queryParams.page = 0;
    if (!_queryParams?.size) _queryParams.size = 20;
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC", nullHandling: "NULLS_LAST" }],
      };
    }
    if (_queryParams?.sort) {
      _queryParams.sort = {
        orders: _queryParams.sort.orders?.map((order) => ({
          ...order,
          nullHandling: "NULLS_LAST",
        })),
      };
    }
    if (!_queryParams?.keyword) {
      _queryParams.keyword = "";
    }

    // URL 파라미터 디코딩
    const queryParamsToDecode = [
      "start",
      "end",
      "keyword",
      "search001",
      "id",
      "parentId",
      "containsFacilityName",
      "containsBuildingName",
      "containsOrganizerMemberNo",
      "containsContractApplyNumber",
    ];
    for (const key of queryParamsToDecode) {
      if (_queryParams?.[key]) {
        _queryParams[key] = decodeURIComponent(_queryParams[key] as string);
      }
    }

    return _queryParams;
  }, [location.search]);

  const [reservationList, setReservationList] = useState<ReservationListModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // 계약 목록 api
  const { executeAsync: getContractList } = useApiOperation(getContractApply);

  // 공용공간 목록 api
  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);

  // 예약 소진시간 목록 조회
  const { executeAsync: getReservationUsageList } = useApiOperation(getReservationUsageListAsync);

  const { executeAsync: getCommonFacilityList } = useApiOperation(getCommonFacilityListAsync);

  useEffect(() => {
    getReservations(reservationQueryParam);
  }, [reservationQueryParam]);

  // 공용공간 예약 리스트 api
  const getReservations = async (reservationParams: ReservationListParams) => {
    if (reservationParams.keyword === "") {
      delete reservationParams.keyword;
    }
    if (reservationParams.search001 !== "" && reservationParams.search001 !== undefined) {
      reservationParams.search001 = decodeURIComponent(reservationParams.search001 || "");
    }

    // 예약 목록 조회
    const {
      data: {
        data: { content },
        meta,
      },
      status,
    } = await getReservationList(reservationParams);

    if (status >= 200 && status <= 299) {
      const reservations = content;

      const contractIds = [...new Set(reservations.map((item) => item.contract.id))].join(",");
      const deskIds =
        [...new Set(reservations.map((item) => item.facility.type === "DESK" && item.facility.id))].filter((id) => id !== false).join(",") || null;
      let deskFacilityList: CommonFacilityListModel[] = [];

      // 좌석 데이터가 있을 때만 api 호출하기
      if (deskIds) {
        // 공용공간 조회

        const {
          data: {
            data: { content: commonFacilityList },
          },
        } = await getCommonFacilityList({ page: 0, size: reservationParams.size, searchType: "ID", keywordList: deskIds });
        deskFacilityList = commonFacilityList;
      }

      // 계약 목록 조회
      const {
        data: { data },
      } = await getContractList({ contractIds: contractIds });

      const contracts = data.content;

      setNewReservationValues(reservations || [], contracts || [], deskFacilityList || []);
      setPageMeta(meta.pageMeta);
    } else {
      return;
    }
  };

  // 새로운 value로 저장 > 계약명 추가 & 엑셀 다운로드 value로 바꾸기
  const setNewReservationValues = (
    reservations: ReservationListModel[],
    contracts: ContractManageList[],
    commonFacilityList: CommonFacilityListModel[],
  ) => {
    const newValues = reservations.map((item) => {
      contracts.forEach((contract) => {
        if (Number(item.contract.id) === Number(contract.contractId)) {
          item = {
            ...item,
            facility: {
              ...item.facility,
              buildingCommonFacilityDeskGroupName:
                commonFacilityList.find((facility) => facility.id === item.facility.id)?.buildingCommonFacilityDeskGroupName || "",
            },
            contract: {
              ...item.contract, //
              contractName: contract.contractName || contract.spaceProductName,
              mbOrganizationName: contract.mbOrganizationName || "",
            },
          };
        }
      });
      return item;
    });
    setReservationList(newValues);
  };

  // 공통 유틸리티 함수들
  const createExcelFileName = (prefix: string, params: ReservationListParams) => {
    const timestamp = moment().format("YYYYMMDDHHmm");
    if (params.start && params.end) {
      return `ctrl.room_${prefix}_${moment(params.start).format("YYYYMMDD")}~${moment(params.end).format("YYYYMMDD")}_${timestamp}`;
    }
    return `ctrl.room_${prefix}_전체_${timestamp}`;
  };

  const showDownloadResult = (success: boolean, error?: any) => {
    setBaseModal({
      isOpen: true,
      title: success ? "엑셀 다운로드가 완료되었습니다." : "엑셀 다운로드에 실패하였습니다.",
      ...(error && { children: String(error) }),
      btnRightTitle: "확인",
      onClick: () => setBaseModal({ isOpen: false }),
    });
  };

  // 병렬 데이터 조회 함수
  const fetchDataInParallel = async <T,>(fetchFn: (params: any) => Promise<any>, params: any, totalPages: number): Promise<T[]> => {
    const promises = Array.from({ length: totalPages }, (_, i) => {
      return fetchFn({ ...params, page: i });
    });
    const responses = await Promise.all(promises);
    return responses.flatMap((response) => response.data.data.content);
  };

  // 계약 데이터 병렬 조회 함수
  const fetchContractDataInBatches = async (contractIds: string[]) => {
    const contractIdChunks = _.chunk([...new Set(contractIds)], 300);
    const contractPromises = contractIdChunks.map((chunk) => getContractList({ contractIds: chunk.join(",") }));
    const contractResponses = await Promise.all(contractPromises);
    return contractResponses.flatMap((response) => response.data.data.content);
  };

  /** 공용공간 예약목록 엑셀 다운로드 */
  const onDownloadReservations = async (passParams: ReservationListParams) => {
    if (!reservationList) return;

    try {
      const defaultSize = 1000;
      const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);

      // 1. 예약 데이터 조회
      const reservationParams = {
        ...passParams,
        statusCode: passParams.statusCode,
        search001: decodeURIComponent(passParams?.search001 || ""),
        size: defaultSize,
      };
      let reservations = await fetchDataInParallel<ReservationListModel>(getReservationList, reservationParams, totalPages);

      let deskFacilityList: CommonFacilityListModel[] = [];

      const deskIds =
        [...new Set(reservations.map((item) => item.facility.type === "DESK" && item.facility.id))].filter((id) => id !== false).join(",") || null;

      // 좌석 데이터가 있을 때만 api 호출하기
      if (deskIds) {
        // 공용공간 조회
        const {
          data: {
            data: { content: commonFacilityList },
          },
        } = await getCommonFacilityList({ page: 0, size: reservationParams.size, searchType: "ID", keywordList: deskIds });
        deskFacilityList = commonFacilityList;

        reservations = reservations.map((item) => {
          deskFacilityList.forEach((facility) => {
            if (item.facility.id === facility.id) {
              item.facility.buildingCommonFacilityDeskGroupName = facility.buildingCommonFacilityDeskGroupName;
            }
          });
          return item;
        });
      }

      // 2. 계약 데이터 조회 및 매핑
      const contracts = await fetchContractDataInBatches(reservations.map((item) => String(item.contract.id)));
      const contractMap = new Map(contracts.map((contract) => [String(contract.contractId), contract]));

      // 3. 데이터 변환
      const excelData = reservations.map((item) => {
        const contract = contractMap.get(String(item.contract.id));
        return transformReservationToExcelFormat(item, contract);
      });
      // 4. 엑셀 다운로드
      await downloadExcelV2({
        data: excelData,
        fileName: createExcelFileName("공용공간예약", passParams),
        columns: reservationColumns,
      });

      showDownloadResult(true);
    } catch (error) {
      showDownloadResult(false, error);
    }
  };

  /** 소진시간 목록 엑셀 다운로드 */
  const onDownloadReservationUsages = async (passParams: ReservationListParams) => {
    if (!reservationList) return;

    try {
      const defaultSize = 1000;
      const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);

      // 1. 소진시간 데이터 조회
      const usageParams: ReservationUsageParams = {
        reservation: _.omit(passParams, ["sort", "page", "size"]),
        sort: {
          orders: [
            { property: "reservationId", direction: "DESC", nullHandling: "NULLS_LAST" },
            { property: "order", direction: "ASC", nullHandling: "NULLS_LAST" },
          ],
        },
        size: defaultSize,
      };

      const usageData = await fetchDataInParallel<any>(getReservationUsageList, usageParams, totalPages);

      // 2. 데이터 변환
      const excelData = usageData.map(transformUsageToExcelFormat);

      // 3. 엑셀 다운로드
      await downloadExcel({
        data: excelData,
        fileName: createExcelFileName("공용공간예약_소진시간", passParams),
        header: reservationUsageHeaders.map((header) => header.label),
      });

      showDownloadResult(true);
    } catch (error) {
      showDownloadResult(false, error);
    }
  };

  // 데이터 변환 함수들
  const transformReservationToExcelFormat = (item: ReservationListModel, contract?: any) => {
    const start = moment(item.start).startOf("minute");
    const end = moment(item.end).startOf("minute");
    const { rvType } = calculateTimeInfo(start, end);
    return {
      id: item.id,
      parentId: item.parentId || "-",
      "status.code": getStatusText(item.status.code),
      summary: item.summary || "-",
      start: start.format(ViewYmdFormat.YYYY_MM_DD_HH_MM),
      end: end.format(ViewYmdFormat.YYYY_MM_DD_HH_MM),
      "facility.building.name": item.facility?.building?.name || "-",
      "facility.floor": `${item.facility.floor > 0 ? "지상" : "지하"} ${Math.abs(item.facility.floor)}층`,
      "organizer.displayName": item.organizer.displayName || "-",
      "organizer.userEmail": item.organizer.userEmail || "-",
      "organizer.phoneNumber": formatPhoneNumber(item.organizer.phoneNumber) || "-",
      "organizer.memberNo": item.organizer.memberNo || "-",
      "facility.type": getFacilityTypeText(item.facility.type),
      "facility.name":
        item.facility.type === "DESK"
          ? `${item.facility.buildingCommonFacilityDeskGroupName ? item.facility.buildingCommonFacilityDeskGroupName + " > " : ""}${
              item.facility.name
            }`
          : item.facility.name || "-",
      rvType,
      inboundChannel: getExternalServiceTypeText(item.inboundChannel),
      usingTime: `${end.diff(start, "minutes")}`,
      "contract.contractName": contract?.contractName || contract?.spaceProductName || "-",
      "contract.mbOrganizationName": contract?.mbOrganizationName || "-",
      createdDate: moment(item.createdDate).format(ViewYmdFormat.YYYY_MM_DD_HH_MM),
    };
  };

  const transformUsageToExcelFormat = (item: any) => ({
    reservationId: item.reservationId,
    contractApplyNumber: item.contract.applyNumber,
    facilityBuildingName: item.facility.building.name,
    facilityType: getFacilityTypeText(item.facility.type),
    facilityId: item.facility.id,
    facilityName: item.facility.name,
    start: moment(item.start).format(YmdFormat.YYYY_MM_DD_HH_MM),
    end: moment(item.end).format(YmdFormat.YYYY_MM_DD_HH_MM),
    duration: item.duration,
    organizerMemberNo: item.organizer.memberNo,
    organizerPhoneNumber: formatPhoneNumber(item.organizer.phoneNumber),
    organizerUserEmail: item.organizer.userEmail,
  });

  // 유틸리티 함수들
  const calculateTimeInfo = (start: moment.Moment, end: moment.Moment) => {
    const diffInMinutes = end.diff(start, "minutes");
    const days = Math.floor(diffInMinutes / 1440);
    const remainingMinutesAfterDays = diffInMinutes % 1440;
    const hours = Math.floor(remainingMinutesAfterDays / 60);
    const minutes = remainingMinutesAfterDays % 60;

    const usingTime = formatUsingTime(days, hours, minutes);
    const rvType = end.diff(start, "hours", true) >= 24 ? "전용" : "공용";

    return { usingTime, rvType };
  };

  const formatUsingTime = (days: number, hours: number, minutes: number) => {
    if (days > 0) {
      return `${days}일 ${hours > 0 ? `${hours}시간 ` : ""}${minutes > 0 ? `${minutes}분` : ""}`.trim();
    }
    if (hours > 0) {
      return `${hours}시간${minutes > 0 ? ` ${minutes}분` : ""}`;
    }
    return `${minutes}분`;
  };

  const getFacilityTypeText = (type: string) => {
    switch (type) {
      case "DESK":
        return "좌석";
      case "MEETING_ROOM":
        return "회의실";
      default:
        return "편의시설";
    }
  };

  const getExternalServiceTypeText = (type: string) => {
    const typeMap: Record<string, string> = {
      [ExternalServiceType.TAAP]: "Taap",
      [ExternalServiceType.GC]: "Google Calendar",
      [ExternalServiceType.OC]: "Outlook Calendar",
      [ExternalServiceType.CTRL_ROOM]: "Ctrl.room",
      [ExternalServiceType.TAAP_SPACE]: "TaapSpace",
    };
    return typeMap[type] || "알 수 없음";
  };

  return {
    reservationQueryParam,
    reservationList,
    pageMeta,
    getReservations,
    getReservationUsageList,
    onDownloadReservations,
    onDownloadReservationUsages,
  };
};

export default useRv;
