import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { decommaizeNumber } from "@toss/utils";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { getBuildingAsync, getBuildingsAsync } from "src/api/building/building-api";
import { updateCctvDeviceAsync } from "src/api/cctv/cctv-api";
import { CctvDevice, CctvDevicePartner } from "src/api/cctv/cctv-types";
import { BaseButton, BaseInput, BaseSelect } from "src/components";
import { useModal } from "src/recoil/modalState/hook";
import { axiosInstance, commaizePrice } from "src/utils";

interface SettingModalForm {
  buildingId: string;
  buildingFloorId: string;
  congestionCapacity: string;
}

interface SettingModalProps {
  cctvDevice?: CctvDevice;
}

/**
 * CCTV 장치 > 상세 > 설정
 */
const SettingModal = ({ cctvDevice }: SettingModalProps) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (data: CctvDevicePartner) => updateCctvDeviceAsync(axiosInstance, data),

    onSuccess() {
      //cctv 데이터 리프레쉬
      queryClient.invalidateQueries({ queryKey: ["cctvDevice"] });
      setBaseModal({
        isOpen: true,
        btnRightTitle: "확인",
        title: "저장되었습니다.",
        onClick: () => {
          setBaseModal({ isOpen: false });
          setAbstractModalZ1({ isOpen: false });
        },
      });
    },
  });

  const { setBaseModal, setAbstractModalZ1 } = useModal();

  const cctvDevicePartner = cctvDevice?.cctvDevicePartnerList?.[0];

  const { handleSubmit, watch, setValue, control, reset } = useForm<SettingModalForm>({
    defaultValues: {
      buildingId: "",
      buildingFloorId: "",
      congestionCapacity: "",
    },
  });

  const { data: buildings } = useQuery({
    queryKey: ["buildings"],
    queryFn: () =>
      getBuildingsAsync(axiosInstance, { page: 0, size: 999999, sort: { orders: [{ property: "id", direction: "DESC" }] } }).then(
        (res) => res?.data?.data?.content,
      ),
  });

  const buildingOptions = buildings?.map((building) => ({
    label: building.buildingName || "-",
    value: String(building.id),
  }));

  const { data: buildingDetail } = useQuery({
    queryKey: ["buildingDetail", watch("buildingId")],
    queryFn: () => getBuildingAsync(axiosInstance, { buildingId: Number(watch("buildingId")) }).then((res) => res?.data?.data?.content.building),
    enabled: !!watch("buildingId"),
  });

  const buildingFloorOptions = buildingDetail?.buildingFloorList?.map((floor) => ({
    label: floor.floorNum ? `${floor.floorNum > 0 ? "지상" : "지하"} ${Math.abs(floor.floorNum)}층` : "-",
    value: String(floor.id),
  }));

  useEffect(() => {
    if (cctvDevicePartner) {
      reset({
        buildingId: cctvDevicePartner?.buildingId ? cctvDevicePartner?.buildingId?.toString() : "",
        buildingFloorId: cctvDevicePartner?.buildingFloorId ? cctvDevicePartner?.buildingFloorId?.toString() : "",
        congestionCapacity: cctvDevicePartner?.congestionCapacity ? cctvDevicePartner?.congestionCapacity?.toString() : "",
      });
    }
  }, [cctvDevice?.cctvDevicePartnerList, cctvDevicePartner, reset]);

  const onValid = (data: SettingModalForm) => {
    mutate({
      id: cctvDevicePartner?.id!,
      cctvDeviceId: cctvDevice?.id!,

      // 설정 값
      // 값이 없으면 초기값을 0으로 저장
      buildingId: data.buildingId ? +data.buildingId : 0,
      buildingFloorId: data.buildingFloorId ? +data.buildingFloorId : 0,
      congestionCapacity: data.congestionCapacity ? decommaizeNumber(data.congestionCapacity) : 0,
    });
  };

  return (
    <>
      <section className="base-abstract-modal__title">
        <h1>설정</h1>
      </section>
      <section className="base-abstract-modal__contents px30">
        <form onSubmit={handleSubmit(onValid)}>
          <section className="contents-container__grid ">
            <div className="contents-container__grid-index">
              <p className="">건물</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax240">
                <Controller
                  control={control}
                  name={"buildingId"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      placeholder=" "
                      menuPlacement="auto"
                      //선택 안함 옵션 추가(초기값)
                      stateOptions={[{ label: "선택 안함", value: "" }, ...(buildingOptions || [])]}
                      setStateValue={(value: string) => {
                        onChange(value);
                        setValue("buildingFloorId", "");
                      }}
                      value={value}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid ">
            <div className="contents-container__grid-index">
              <p className="">층</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax240">
                <Controller
                  control={control}
                  name={"buildingFloorId"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <BaseSelect
                      placeholder=" "
                      menuPlacement="auto"
                      stateOptions={[{ label: "선택 안함", value: "" }, ...(buildingFloorOptions || [])]}
                      setStateValue={(value: string) => {
                        onChange(value);
                      }}
                      value={value}
                      name={name}
                      errorText={error?.message}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
          <section className="contents-container__grid ">
            <div className="contents-container__grid-index">
              <p className="">혼잡도 CAPA</p>
            </div>
            <div className="contents-container__grid-contents">
              <div className="minmax240">
                <Controller
                  control={control}
                  name={"congestionCapacity"}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    //숫자만 입력가능
                    <BaseInput onChange={onChange} value={value ? commaizePrice(value) : ""} name={name} errorText={error?.message} />
                  )}
                ></Controller>
              </div>
            </div>
          </section>
        </form>
      </section>
      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"취소"} className="color-white" onClick={() => setAbstractModalZ1({ isOpen: false })} />

        <BaseButton
          title={"저장"}
          onClick={() => {
            setBaseModal({
              isOpen: true,
              btnRightTitle: "확인",
              btnLeftTitle: "취소",
              title: "저장하시겠습니까?",
              onClick: () => {
                setAbstractModalZ1({ isOpen: false });
                handleSubmit(onValid)();
              },
            });
          }}
        />
      </section>
    </>
  );
};

export default SettingModal;
