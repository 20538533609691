import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useApiOperation } from "src/api/hooks";

import { Order, PageMeta } from "src/api/public-types";
import { BaseInputWithSearch, BaseTable } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { qsParse } from "src/utils";

import { CctvDevice, CctvDeviceQueryParams } from "src/api/cctv/cctv-types";

import { getCctvDeviceListAsync } from "src/api/cctv/cctv-api";
import { buildingsDetails } from "src/api/product/product-api";
import cctvListColumns from "./columns/cctvListColumns";

/**
 * CCTV 장치 > 목록 화면
 */
const CctvDeviceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: CctvDeviceQueryParams = useMemo(() => {
    const _queryParams: CctvDeviceQueryParams = qsParse(location.search);

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    // qs.parse에 decoder때문에 검색값 입력 검색시 한번더 decoding
    if (_queryParams.keyword) {
      _queryParams.keyword = decodeURIComponent(_queryParams.keyword);
    }

    return _queryParams;
  }, [location.search]);

  const [params, setParams] = useState<CctvDeviceQueryParams>({ ...queryParams });
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [cctvDevice, setCctvDevice] = useState<CctvDevice[]>();
  console.log(
    "cctvDevice",
    cctvDevice?.filter((item) => item.cctvEventDataList),
  );
  // cctv 목록 api
  const { executeAsync: getCctvDeviceList } = useApiOperation(getCctvDeviceListAsync);

  // 건물 상세 목록 api

  const { executeAsync: getBuildingsDetails } = useApiOperation(buildingsDetails);

  // 쿼리파람과 함께 navigate
  const navigateWithQueryParams = useCallback(
    (passParams?: CctvDeviceQueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  useEffect(() => {
    setParams({ ...queryParams }); // input 초기화
    const fetchApi = async (params: CctvDeviceQueryParams) => {
      // 목록 조회

      const response = await getCctvDeviceList(params);
      if (response.status >= 200 && response.status <= 299) {
        const CctvDeviceList = response.data.data.content;

        // result 에서 bulding id 만 추출
        const buildingIds = CctvDeviceList?.map((item) => item.cctvDevicePartnerList?.map((partner) => partner.buildingId))
          .flatMap((id) => id)
          ?.filter((id) => id);
        // bulidingId 중복없애서 호출

        const uniqueBuildingIds = [...new Set(buildingIds)];

        const buildingList =
          uniqueBuildingIds && uniqueBuildingIds.length > 0
            ? await getBuildingsDetails({
                building: {
                  ids: uniqueBuildingIds.map((id) => String(id)),
                },
              }).then((res) => res?.data?.data?.content ?? [])
            : [];

        const newCctvDeviceList: CctvDevice[] = CctvDeviceList.map((cctvDevice) => {
          const newCctvDevicePartner = cctvDevice?.cctvDevicePartnerList?.map((partner) => {
            const buildingzz = buildingList.find((building) => String(building.building.id) === String(partner.buildingId));

            const buildingFloor = buildingzz?.building.buildingFloorList?.find((floor) => String(partner.buildingFloorId) === String(floor.id));

            return {
              ...partner,
              buildingName: buildingzz?.building.buildingName,
              floorNum: buildingFloor?.floorNum,
            };
          });

          return {
            ...cctvDevice,
            cctvDevicePartnerList: newCctvDevicePartner,
          };
        });

        setCctvDevice(newCctvDeviceList);
        setPageMeta(response.data.meta.pageMeta);
      }
    };

    fetchApi(queryParams);
  }, [queryParams]);

  const searchTypeOptions = [
    { value: "", label: "전체" },
    { value: "CAMERA_GROUP", label: "Camera Group" },
    { value: "CAMERA_ID", label: "Camera ID" },
    { value: "CAMERA_NAME", label: "장치명" },
  ];

  return (
    <div className="">
      <div className="contents-container__table">
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>

              <div className="left-area__contents">
                <BaseInputWithSearch
                  selectWidth={160}
                  type="text"
                  selectValue={params.searchType}
                  inputValue={params?.keyword ? String(params?.keyword) : ""}
                  stateOptions={searchTypeOptions}
                  setStateValue={(searchType: string) => {
                    setParams({ ...params, searchType });
                  }}
                  onChange={(keywordList: string) => {
                    setParams({ ...params, keyword: keywordList });
                  }}
                  onClearClick={() => {
                    const editParams = { ...params };
                    delete editParams.keyword;
                    setParams(editParams);
                    navigateWithQueryParams({ ...params, page: 0, keyword: "" });
                  }}
                  onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
                  onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
                />
              </div>
            </section>
          </div>
        </div>

        {cctvDevice && (
          <BaseTable
            data={cctvDevice}
            columns={cctvListColumns}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            goPage={(page: number) => {
              navigateWithQueryParams({ page }, "pagination");
            }}
            totalElements={Number(pageMeta?.totalElements) || 0}
            orders={queryParams?.sort?.orders}
            disabledSortHeaders={["buildingName", "floorNum"]} //buildingName ,floorNum 제외
            sizeOption={(number: number) => {
              setParams({ ...params, size: number });
              navigateWithQueryParams({ ...params, page: 0, size: number }, "search");
            }}
            currentSize={Number(queryParams.size) || 20}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ ...params, sort: { orders } });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default CctvDeviceList;
