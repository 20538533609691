import { ReactNode, useCallback, useMemo, useRef } from "react";
import { HeaderGroup, useBlockLayout, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import { Order } from "src/api/public-types";
import useApiLoading from "src/hooks/useApiLoading";
import { BasePagination } from "./BasePagination";
import ScrollShadowProvider from "./ScrollShadowProvider";

interface Props {
  data: Array<any>;
  columns: Array<any>;
  pageIndex: number;
  totalPages: number;
  totalElements?: number;
  goPage: Function;
  setOrders: Function;
  orders?: Array<Order>;
  disabledSortHeaders?: Array<string>; // 정렬이 불가능한 컬럼이 있을 경우 header accesor 값 배열로 전달
  children?: ReactNode; // 페이지네이션 우측에 들어갈 버튼
  sizeOption?: (number: number) => void;
  currentSize?: number;
}

const PAGE_SIZE = 20;

/*  
  목록화면에서 공통으로 사용하는 table 컴포넌트 (페이징, 소팅 기능)
*/
export const BaseTable = ({
  data,
  columns,
  pageIndex,
  totalPages,
  totalElements,
  goPage,
  setOrders,
  orders,
  disabledSortHeaders,
  children,
  sizeOption,
  currentSize,
}: Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns: columns,
      data: data,
      // initialState: { pageSize: PAGE_SIZE },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useSticky,
    useRowSelect,
  );

  const tableRef = useRef<HTMLTableElement>(null);

  // columns width 의 총 합 (table witdh 를 구하기 위함)
  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  // 정렬이 불가능한 header 인지 여부
  const isDisabledSortHeader = useCallback(
    (header: HeaderGroup): boolean => {
      let isDisabledSortHeader = false;
      if (disabledSortHeaders && disabledSortHeaders.length > 0) {
        isDisabledSortHeader = disabledSortHeaders.includes(header.id);
      }
      return isDisabledSortHeader;
    },
    [disabledSortHeaders],
  );

  // header sort component
  const renderHeaderSortComponent = (header: HeaderGroup) => {
    if (isDisabledSortHeader(header)) return null;

    if (orders && orders && orders.length > 0) {
      const finedHeader = orders.find((order: { property: string; direction: string }) => order.property === header.id);
      if (finedHeader) {
        if (finedHeader.direction === "DESC") {
          return <div className="ic_sort down"></div>;
        } else if (finedHeader.direction === "ASC") {
          return <div className="ic_sort up"></div>;
        }
      }
    }
    return <div className="ic_sort"></div>;
  };

  // 정렬 버튼 클릭
  const clickHeaderSort = (header: HeaderGroup) => {
    // 새로운 정렬
    let newOrders = [...(orders || [])];

    if (orders && orders && orders.length > 0) {
      // 현재 정렬되어있는 프로퍼티일 경우 존재함
      const finedHeader = orders.find((order: { property: string; direction: string }) => order.property === header.id);

      if (finedHeader) {
        newOrders = orders.filter((currentOrder: Order) => currentOrder.property !== finedHeader.property);
      }

      // 해당 프로퍼티 정렬 다시 재할당
      let newDirection = null;
      if (finedHeader?.direction) {
        // 기존 정렬 방향이 있으면
        if (finedHeader?.direction === "DESC") {
          // 내림차순이면 오름차순으로 변경
          newDirection = "ASC";
        } else {
          // 오름차순이면 정렬 해제이기 때문에 기존 정렬삭제되서 처리할게 없음
          newDirection = "DESC";
        }
      } else {
        // 기존 정렬 방향이 없으면 내림차순으로 변경
        newDirection = "DESC";
      }
      if (newDirection) {
        newOrders = [{ property: header.id, direction: newDirection }]; // 단일 정렬시
      }
    } else {
      // 기존에 정렬되어 있는게 없으면 클릭한 프로퍼티를 내림차순으로 변경
      newOrders = [{ property: header.id, direction: "DESC" }];
    }
    setOrders(newOrders);
  };

  const handleGoPage = (page: number) => {
    goPage(page);
    tableRef.current?.scrollTo(0, 0);
  };
  return (
    <div className="base-table-wrap">
      <ScrollShadowProvider tableRef={tableRef}>
        <div {...getTableProps()} className="base-table sticky" ref={tableRef}>
          <div className="header">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div
                      // {...header.getHeaderProps(header.id !== "rowChecked" ? header.getSortByToggleProps() : undefined)}
                      {...header.getHeaderProps()}
                      className={`base-table__th ${!isDisabledSortHeader(header) ? "cursor-pointer" : ""}`}
                      onClick={() => {
                        if (!isDisabledSortHeader(header)) {
                          clickHeaderSort(header);
                        }
                      }}
                    >
                      {header.render("Header")}
                      <span>{renderHeaderSortComponent(header)}</span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="base-table__tr">
                  {row.cells.map((cell) => {
                    return (
                      <div {...cell.getCellProps()} className="base-table__td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {rows.length === 0 && (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ScrollShadowProvider>

      <BasePagination
        pageIndex={pageIndex}
        totalPages={totalPages}
        totalElements={totalElements || 0}
        currentSize={currentSize}
        sizeOption={sizeOption}
        goPage={handleGoPage}
      >
        {children}
      </BasePagination>
    </div>
  );
};
