import moment from "moment";

import { Cell } from "react-table";
import { ReservationListModel } from "src/api/reservation/reservation-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import DetailLink from "src/components/DetailLink";
import Link from "src/components/PartnerLink";
import PhoneNumberColumn from "src/components/PhoneNumberColumn";
import PagePath from "src/pagePath.json";
import { ViewYmdFormat } from "src/utils";
import { ExternalServiceType, getStatusText, inboundChannelType, ReservationState } from "../../reservation-types";
/* 
  공용공간 예약 목록 columns
*/

interface ColumnDefinition {
  Header: string;
  // accessor: string | ((item: ReservationListModel) => string);
  accessor: string;
  width?: number;
  sticky?: string;
  Cell?: (props: Cell<ReservationListModel>) => JSX.Element | string;
  excelValue?: (item: ReservationListModel) => any;
}

const ReservationColumns: ColumnDefinition[] = [
  {
    Header: "id",
    accessor: "id",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.reservation.detail.replace(":id", `${props.row.original.id}`)}`;
      const groupId = props.row.original.parentId;
      return (
        <div>
          <DetailLink className="text-underline" to={`${detailPath}${(groupId && `?groupID=${groupId}`) || ""}`}>
            {props.value}
          </DetailLink>
        </div>
      );
    },
    excelValue: (item: ReservationListModel) => item.id,
  },
  {
    Header: "그룹Id",
    accessor: "parentId",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.reservation.detail.replace(":id", `${props.row.original.id}`)}`;
      return (
        <div>
          {props.value && props.value !== "0" ? (
            <Link className="text-underline" to={`${detailPath}?groupID=${props.row.original.parentId || 0}`}>
              {props.value}
            </Link>
          ) : (
            <span>-</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "점유 상태",
    accessor: "status.code",
    sticky: "left",

    width: 90,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      const statueText = getStatusText(value);
      return (
        <div
          className={`${
            (value === ReservationState.ACCEPTED && "status A") ||
            (value === ReservationState.CANCELLED && "status D") ||
            (value === ReservationState.ACKNOWLEDGED && "status B") ||
            (value === ReservationState.INCOMPLETE && "status D")
          }`}
        >
          {statueText}
        </div>
      );
    },
  },
  {
    Header: "예약명",
    accessor: "summary",
    sticky: "left",
    width: 150,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || "-"} isSingleLine />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "facility.floor",
    width: 80,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      const floorText = value > 0 ? "지상" : "지하";
      return (
        <div>
          <span>
            {floorText} {Math.abs(value)}층
          </span>
        </div>
      );
    },
  },
  {
    Header: "공용공간명",
    accessor: "facility.name",
    width: 120,
    Cell: ({ row, value }: Cell<ReservationListModel>) => {
      const deskGroupName = row.original.facility.buildingCommonFacilityDeskGroupName;
      return (
        <div className="w-100 text-left">
          {row.original.facility.type === "DESK" ? (
            <BaseTooltip contents={`${deskGroupName ? deskGroupName + " > " + value : value}`} />
          ) : (
            <BaseTooltip contents={value || ""} />
          )}
        </div>
      );
    },
  },
  {
    Header: "예약 시작일시",
    accessor: "start",
    width: 160,
    Cell: ({ value }: any) => {
      return (
        <div>
          <span>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "예약 종료일시",
    accessor: "end",
    width: 160,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div>
          <span>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD_HH_MM) : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "facility.building.name",
    width: 160,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left">
          <span>{value}</span>
        </div>
      );
    },
  },

  {
    Header: "주최자 닉네임",
    accessor: "organizer.displayName",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{value || "-"}</p>
        </div>
      );
    },
  },
  {
    Header: "주최자 이메일",
    accessor: "organizer.userEmail",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return <BaseTooltip contents={value || "-"} isSingleLine={true} />;
    },
  },
  {
    Header: "주최자 휴대폰번호",
    accessor: "organizer.phoneNumber",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="d-flex flex-column">
          <PhoneNumberColumn phoneNumber={value ? value : "-"} />
        </div>
      );
    },
  },
  {
    Header: "신청/계약명",
    accessor: "contract.contractName",
    width: 160,
    Cell: ({ value, row }: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.contract.detail.replace(":id", `${row.original.contract.id}`)}`;

      return value ? <BaseNewTabLink path={detailPath || "-"} value={value} className="w-100 text-left" tooltip ellipsis /> : "-";
    },
  },

  {
    Header: "소속회사",
    accessor: "contract.mbOrganizationName",
    width: 160,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return <BaseTooltip contents={value || "-"} isSingleLine className="text-left w-100" />;
    },
  },
  {
    Header: "예약 유형",
    accessor: "rvType",
    width: 100,
    Cell: ({ row }: Cell<ReservationListModel>) => {
      const start = row.original.start;
      const end = row.original.end;
      const diffInHours = moment(end).diff(moment(start), "hours", true);

      const isOver24h = diffInHours >= 24;

      // 24시간 이상이면 true, 미만이면 false 반환

      return <div>{isOver24h ? "전용" : "공용"}</div>;
    },
  },
  {
    Header: "공용공간 유형",
    accessor: "facility.type",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      if (props.value === "MEETING_ROOM") {
        return <span>회의실</span>;
      } else if (props.value === "DESK") {
        return <span>좌석</span>;
      } else {
        return <span>편의시설</span>;
      }
    },
  },
  {
    Header: "예약 경로",
    accessor: "inboundChannel",
    width: 160,
    Cell: (props: Cell<ReservationListModel>) => {
      let externalServiceType = "";

      if (props.value === ExternalServiceType.TAAP) {
        externalServiceType = inboundChannelType[ExternalServiceType.TAAP];
      } else if (props.value === ExternalServiceType.GC) {
        externalServiceType = inboundChannelType[ExternalServiceType.GC];
      } else if (props.value === ExternalServiceType.OC) {
        externalServiceType = inboundChannelType[ExternalServiceType.OC];
      } else if (props.value === ExternalServiceType.CTRL_ROOM) {
        externalServiceType = inboundChannelType[ExternalServiceType.CTRL_ROOM];
      } else if (props.value === ExternalServiceType.TAAP_SPACE) {
        externalServiceType = inboundChannelType[ExternalServiceType.TAAP_SPACE];
      } else {
        externalServiceType = "알 수 없음";
      }

      return (
        <div>
          <span>{externalServiceType ? externalServiceType : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "이용시간",
    accessor: "usingTime",
    width: 120,
    Cell: ({ row }: Cell<ReservationListModel>) => {
      // UTC 시간을 한국 시간으로 변환하고 분까지만 고려
      const start = moment(row.original.start).startOf("minute");
      const end = moment(row.original.end).startOf("minute");

      // 분 단위 차이 계산
      const diffInMinutes = end.diff(start, "minutes");

      const days = Math.floor(diffInMinutes / 1440); // 1일 = 1440분
      const remainingMinutesAfterDays = diffInMinutes % 1440;
      const hours = Math.floor(remainingMinutesAfterDays / 60);
      const minutes = remainingMinutesAfterDays % 60;

      let displayTime = "";

      if (days > 0) {
        displayTime += `${days}일 `;
        if (hours > 0) displayTime += `${hours}시간 `;
        if (minutes > 0) displayTime += `${minutes}분`;
      } else if (hours > 0) {
        displayTime += `${hours}시간`;
        if (minutes > 0) displayTime += ` ${minutes}분`;
      } else {
        displayTime += `${minutes}분`;
      }

      return <div>{displayTime.trim()}</div>;
    },
  },
  {
    Header: "주최자 회원번호",
    accessor: "organizer.memberNo",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="d-flex flex-column">
          <span>{value || "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "생성일시",
    accessor: "createdDate",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const createdDate = props.row.original.createdDate;
      return <BaseTooltip contents={createdDate} type="date" />;
    },
  },
];

export default ReservationColumns;
