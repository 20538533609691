import React from "react";
import { UserListModel } from "src/api/user/user-types";
import { Cell } from "react-table";
import PhoneNumberColumn from "src/components/PhoneNumberColumn";
import { BaseTooltip } from "src/components";

export const rvMemberColumns: any = [
  {
    Header: "이용자 id",
    accessor: "contractMemberId",
    sticky: "left",
    width: 80,
    Cell: ({ value }: Cell<UserListModel>) => value,
  },
  {
    Header: "상태",
    accessor: "memberStatus",
    sticky: "left",
    width: 80,
    Cell: ({ value, row }: any) => {
      let changeValue = value;

      return (
        //visible 녹색
        //invisible 빨간색
        //draft = 회색
        <div className="flex-center">
          <p
            className={`${
              (changeValue === "MEMBER_INVITE" && "status A") ||
              (changeValue === "MEMBER_USE" && "status B") ||
              (changeValue === "MEMBER_DELETE" && "status D") ||
              (changeValue === "MEMBER_CLOSE" && "status D") ||
              (changeValue === "MEMBER_EXPIRE" && "status D")
            }`}
          >
            {(changeValue === "MEMBER_INVITE" && "초대") ||
              (changeValue === "MEMBER_USE" && "사용") ||
              (changeValue === "MEMBER_DELETE" && "삭제") ||
              (changeValue === "MEMBER_CLOSE" && "이용종료") ||
              (changeValue === "MEMBER_EXPIRE" && "초대만료")}
          </p>
        </div>
      );
    },
  },
  {
    Header: "닉네임",
    accessor: "nickname",
    width: 180,
    Cell: ({ value }: any) => {
      return <div className="text-left w-100 ellipsis2">{value || "-"}</div>;
    },
  },
  {
    Header: "휴대폰번호",
    accessor: "phoneNumber",
    width: 160,
    Cell: ({ row }: any) => {
      // console.log("row", row.original);
      return (
        <PhoneNumberColumn
          phoneNumber={
            row.original?.phoneNumber ? row.original.phoneNumber : row.original?.inviteMobileNumber ? row.original?.inviteMobileNumber : "-"
          }
        />
      );
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 220,
    Cell: ({ row, value, organizerMemberNo }: any) => {
      const emailFront = value?.split("@")[0];
      const emailBack = value?.split("@")[1];

      const email = `${emailFront}@${emailBack}`;
      // const isOrganizer = row.original.memberNo === organizerMemberNo;

      return (
        <div
          className="flex-center-between w-100"
          style={{
            wordBreak: "break-all",
          }}
        >
          <BaseTooltip contents={emailFront && emailBack ? email : "-"} isSingleLine={true} />

          {/* 주최자 표시 추후 적용해보기 */}
          {/* {isOrganizer && <span className="minmax40 status D ml5">주최자</span>} */}
        </div>
      );
    },
  },
  {
    Header: "권한",
    accessor: "role",
    width: 140,
    Cell: ({ row }: any) => {
      let roles = "";
      if (row.original.isContractor === true) {
        roles = "계약자 ";
      }
      if (row.original.isAdmin === true) {
        if (roles !== "") {
          roles += ", 마스터";
        } else {
          roles += "마스터";
        }
      }

      return <div>{roles ? roles : "-"}</div>;
    },
  },
];
