import { useEffect, useMemo, useState } from "react";
import { useBlockLayout, useFilters, useGlobalFilter, useRowSelect, useSortBy, useTable } from "react-table";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList, SearchParams } from "src/api/contract/contract-types";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { BaseCheckbox, BaseInputWithSearch, BasePagination, BaseTooltip } from "src/components";
import TableRadioButton from "src/pages/product/components/TableRadioButton";
import { rvContractColumns } from "../columns/RvContractColumns";
import { BULLET } from "src/utils";

type Props = {
  onClick?: (data: ContractManageList) => void;
  onClose?: () => void;
};

const searchOptions = [
  { value: "ALL", label: "전체" },
  { value: "CONTRACT_ID", label: "신청/계약 ID" },
  { value: "CONTRACT_NAME", label: "계약명" },
  { value: "SPACE_PRODUCT_NAME", label: "상품명" },
];

const requestParams: SearchParams = {
  page: 0,
  size: 20,
  searchType: "ALL",
  searchValue: "",
  sort: {
    orders: [
      {
        property: "contractManageId",
        direction: "DESC",
      },
    ],
  },
};

const RvContractModal = ({ onClick, onClose }: Props) => {
  const [contracts, setContracts] = useState<Array<ContractManageList>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const [isAvailable, setIsAvailable] = useState(true);
  const { executeAsync: getContractList } = useApiOperation(getContractApply);

  const [params, setParams] = useState(requestParams);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, selectedFlatRows } = useTable(
    {
      columns: rvContractColumns,
      data: contracts,
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useGlobalFilter,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns: Array<any>) => [
        {
          id: "selection",
          width: 50,
          Header: () => <div className="">선택</div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }: any) => {
            let currentState = row.getToggleRowSelectedProps();
            const contract: ContractManageList = row.original;
            const isUseMeetingRoom = contract.isUseMeetingRoom;
            const isUseRefreshRoom = contract.isUseRefreshRoom;
            const isUseDesk = contract.isUseDesk;
            const facilityNums = contract.facilityNums;
            const availableContractStep = contract.contractStep === "USE_PROGRESS" || contract.contractStep === "TERMINATE_RECEIVED";

            // 등록된 공용공간이 없거나 , 있는데 사용을 안하는경우(toggle off) disalbed
            const disabled =
              (!isUseMeetingRoom && !isUseRefreshRoom && !isUseDesk) ||
              facilityNums === 0 ||
              !availableContractStep ||
              contract.spaceProductType !== "FULL_COURT";

            return (
              <div className="minmax100 flex-center-center">
                <TableRadioButton
                  id={row.id}
                  disabled={disabled}
                  onClick={() => {
                    toggleAllRowsSelected(false);
                    toggleRowSelected(row.id, true);
                  }}
                  name="selectedOrg"
                  {...currentState}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const fetchApi = async (request: SearchParams) => {
    let newRequest;
    if (isAvailable) {
      newRequest = { ...request, spaceProductType: "FULL_COURT" };
    } else {
      newRequest = request;
    }

    const contractListRes = await getContractList(newRequest);
    setContracts(contractListRes?.data?.data?.content);
    setPageMeta(contractListRes?.data?.meta?.pageMeta);
  };

  useEffect(() => {
    let request = requestParams;
    if (isAvailable) {
      request = {
        ...params, //
        page: 0,
        spaceProductType: "FULL_COURT",
        isUseFacility: true,
        contractStep: "USE_PROGRESS, TERMINATE_RECEIVED",
      };
    }
    setParams(request);

    fetchApi(request);
  }, [isAvailable]);

  const tableWidth = useMemo(() => {
    let totalWidth = 0;
    headerGroups.forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => (totalWidth += Number(header?.width || 0)));
    });
    return totalWidth;
  }, [headerGroups]);

  return (
    <>
      <section className="pa30">
        <div className="base-abstract-modal__title flex-center-between pa0">
          <h1>신청/계약 선택</h1>
          <div className=" right-area">
            <div className="flex-center">
              <BaseCheckbox
                id={"rv_contract"}
                name={"rv_contract"}
                label="선택 가능"
                className="mr10"
                onChange={(checked: boolean) => {
                  setIsAvailable(checked);
                }}
                checked={isAvailable}
              />
              <BaseInputWithSearch
                inputValue={params.searchValue}
                selectValue={params.searchType}
                stateOptions={searchOptions}
                onKeyUp={() => fetchApi({ ...params, page: 0 })}
                onChange={(searchValue: string) => {
                  setParams({ ...params, searchValue });
                }}
                setStateValue={(searchType: string) => {
                  setParams({ ...params, searchType });
                }}
                onSearchClick={() => fetchApi({ ...params, page: 0 })}
              />
            </div>
          </div>
        </div>
        <div className="flex-center mt10">
          <p className="font14 text-gray500">{BULLET} 공용공간이 등록되어있는 공간임대형 신청/계약만 선택 가능합니다.</p>
          <BaseTooltip touchIcon={"QUESTION"} className="ml4 mt1" size={16}>
            <p className="mb5">
              • 공간예약 상품은 별도 플랫폼인 TaapSpace 를 통해 <br />
              예약 가능합니다.
            </p>
            <p>•비상주 상품은 공용공간 예약 기능을 제공하지 않습니다.</p>
          </BaseTooltip>
        </div>
      </section>

      <section className="base-abstract-modal__contents">
        <div className="contents-container__table px30">
          <div {...getTableProps()} className="base-table view-data-table sticky">
            <div className="header">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="base-table__tr">
                  {headerGroup.headers.map((header) => {
                    return (
                      <div {...header.getHeaderProps(header.getSortByToggleProps())} className="base-table__th">
                        {header.render("Header")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            {contracts.length && contracts.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {rows.map((row, idx: number) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="base-table__tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} className="base-table__td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="base-table__tr table-cursor-unset" style={{ width: tableWidth }}>
                <div className="base-table__td w-100 text-center">
                  <div className="w-100">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="px30">
        <BasePagination
          pageIndex={params.page || 0}
          totalPages={pageMeta?.totalPages || 0}
          currentSize={Number(params.size) || 20}
          sizeOption={(size) => {
            setParams({ ...params, size, page: 0 });
            fetchApi({ ...params, size, page: 0 });
          }}
          goPage={(page: number) => {
            console.log("params", params);
            setParams({ ...params, page });
            fetchApi({ ...params, page });
          }}
          totalElements={pageMeta?.totalElements || 0}
          children={
            <div className="flex-center">
              <button className="base-btn color-white mr10" onClick={() => onClose && onClose()}>
                취소
              </button>
              <button
                className="base-btn"
                onClick={() => {
                  if (!selectedFlatRows[0]) return;
                  const selected = selectedFlatRows[0].original;

                  onClick && onClick(selected);
                  onClose && onClose();
                }}
              >
                선택
              </button>
            </div>
          }
        />
      </section>
    </>
  );
};

export default RvContractModal;
