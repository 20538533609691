import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { ko } from "date-fns/locale";
import { forwardRef, useRef } from "react";

interface Props extends Omit<ReactDatePickerProps<never, true>, "startDate" | "endDate"> {
  dateRange: [Date | null, Date | null];
  disabled?: boolean;
  type?: "month" | "";
  dateFormat?: "yyyy.MM" | "";
  placeholder?: string;
}

function RangeDatepickerInline(props: Props) {
  const [startDate, endDate] = props.dateRange;

  const RangeDatePinkerInput = forwardRef<HTMLInputElement, any>(({ value, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(ref as any);
    const handleClickIcon = () => inputRef.current?.focus();

    return (
      <div className="range-datepicker-input w205">
        <input value={value} {...props} disabled={props.disabled ? true : false} ref={inputRef} className="text-center px0" />
      </div>
    );
  });

  return (
    <div>
      <ReactDatePicker
        {...props}
        selectsRange={true}
        calendarClassName="base-datepicker"
        dateFormat={props.dateFormat ? props.dateFormat : "yyyy.MM.dd"}
        locale={ko}
        startDate={startDate}
        endDate={endDate}
        inline={true}
        showMonthYearPicker={props.type === "month" ? true : false}
      />
      <div className="mt10">
        <ReactDatePicker
          {...props}
          open={false}
          disabled={props.disabled}
          customInput={<RangeDatePinkerInput disabled={props.disabled} />}
          placeholderText={props.placeholder}
          selectsRange={true}
          dateFormat={props.dateFormat ? props.dateFormat : "yyyy.MM.dd"}
          locale={ko}
          startDate={startDate}
          endDate={endDate}
          showMonthYearPicker={props.type === "month" ? true : false}
          onFocus={(e) => e.target.blur()}
        />
      </div>
    </div>
  );
}

export default RangeDatepickerInline;
