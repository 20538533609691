import React, { FocusEventHandler, KeyboardEventHandler, useState } from "react";
import { BaseSelect } from "./BaseSelect";

interface Props {
  type?: string;
  className?: string;
  stateOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
  }>;
  isSelectDisabled?: boolean;
  selectWidth?: number;
  selectValue?: string | number | null;
  setStateValue: Function;
  inputType?: string;
  inputValue?: string | number;
  onChange?: Function;
  inputName?: string;
  onKeyUp?: Function;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
  // disabled?: boolean;
  // readonly?: boolean;
  onSearchClick?: Function;
  onClearClick?: Function;
  clearable?: boolean;
  inputRef?: any;
}

/**
 * 목록에서 사용하는 select와 합쳐진 검색인풋
 * search icon이 항상 노출됨
 */

export const BaseInputWithSearch = (props: Props) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const targetValue = e.target.value;
      if (props.inputType === "number" && props.maxLength) {
        // type number 일때 maxLength 안먹혀서 추가
        if (targetValue.length > props.maxLength) {
          const sliceValue = targetValue.slice(0, props.maxLength);
          props.onChange(sliceValue, e);
        } else {
          props.onChange(targetValue, e);
        }
      } else {
        props.onChange(targetValue, e);
      }
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (props.onKeyUp) {
        props.onKeyUp();
      }
    }
  };

  const onSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onSearchClick) {
      props.onSearchClick();
    }
  };

  const onClearClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onClearClick) {
      props.onClearClick();
    }

    if (props.clearable && props.onChange) {
      props.onChange("");
    }
  };

  const [isFocus, setIsFocus] = useState(false);

  return (
    <div className={`base-search-input ${props.className ? props.className : ""} ${isFocus ? "base-search-input__focus" : ""}`}>
      <div className={props.selectWidth ? `minmax${props.selectWidth}` : "minmax148"}>
        <BaseSelect
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          value={props.selectValue}
          placeholder={"검색 옵션"}
          stateOptions={props.stateOptions}
          setStateValue={props.setStateValue}
          isDisabled={props.isSelectDisabled}
        />
      </div>
      <div className="base-input base-search minmax200">
        <input
          className=""
          ref={props?.inputRef}
          type={props.inputType}
          name={props.inputName}
          placeholder={"검색어를 입력하세요"}
          onChange={onChangeValue}
          onKeyUp={onKeyUp}
          onFocus={() => setIsFocus(true)}
          onKeyDown={props.onKeyDown}
          onBlur={(e) => {
            setIsFocus(false);
            props.onChange && props.onChange(e.target.value.trim(), e);
            props.onBlur && props.onBlur(e);
          }}
          value={props.inputValue || ""}
          maxLength={props.maxLength || 100}
          // readOnly={props.readonly ? true : false}
          // disabled={props.disabled ? true : false}
        />
        {String(props?.inputValue || "").length > 0 && (props.onClearClick || props.clearable) && (
          <button type="button" className="base-clear-btn" onClick={onClearClick}></button>
        )}
        {props.onSearchClick && (
          <button
            type="button"
            className="base-search-btn"
            onClick={onSearchClick}
            onMouseUp={(event) => {
              console.log("event :>> ", event);
              if (event.button === 1) {
                // 마우스의 휠 버튼 클릭 여부 확인
                event.currentTarget.click();
              }
            }}
          ></button>
        )}
      </div>
    </div>
  );
};
