import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel, FacilityQueryParams } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import GoToListButton from "src/components/GoToListButton";
import MetaTag from "src/components/layout/MetaTag";
import { qsParse } from "src/utils";
import { MeetingRoomSection, RefreshRoomSection } from "./components";
import FacilitySubTabs from "./components/FacilitySubTabs";
import DeskSection from "./desk/DeskSection";
import ParkingSection from "./parking/ParkingSection";

const CommonFacility = () => {
  const { pathname, search } = useLocation();
  const { id, facilityType }: FacilityQueryParams = qsParse(search);

  // path variable 에서 buildingId 추출

  const buildingId = useMemo(() => {
    const paths = pathname.split("/");
    const pathBuildingId = paths[paths.length - 1];

    return id ? id : Number(pathBuildingId);
  }, [pathname]);

  const [building, setBuilding] = useState<BuildingModel>();

  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  const fetchBuilding = async (buildingId: number) => {
    //
    const { data } = await getBuilding({ buildingId });
    setBuilding(data.data.content.building);
  };

  useEffect(() => {
    if (buildingId) {
      fetchBuilding(Number(buildingId));
    }
  }, [buildingId]);

  return (
    <>
      <MetaTag subTitle={building?.buildingName} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <FacilitySubTabs buildingId={String(buildingId)} />
          <article className="contents-container__wrap-article">
            {(!facilityType || facilityType === "PARKING") && (
              <ParkingSection
                building={building!} //
                fetchBuilding={fetchBuilding}
              />
            )}
            {facilityType === "MEETING_ROOM" && ( //
              <MeetingRoomSection building={building!} fetchBuilding={fetchBuilding} />
            )}
            {facilityType === "DESK" && ( //
              <DeskSection building={building!} fetchBuilding={fetchBuilding} />
              // <DeskSection building={building!} />
            )}
            {facilityType === "REFRESH_ROOM" && ( //
              <RefreshRoomSection building={building!} fetchBuilding={fetchBuilding} />
            )}
          </article>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default CommonFacility;
