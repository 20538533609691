import { Link, useLocation } from "react-router-dom";
import { Cell } from "react-table";
import { DeviceModel } from "src/api/iot/iot-types";
import { BaseTooltip } from "src/components";

const BuildingDeviceColumns: any[] = [
  {
    Header: "id",
    accessor: "id",
    width: 60,
    Cell: ({ row, value }: Cell<DeviceModel>) => {
      const location = useLocation();
      return (
        <Link className="text-underline" to={`${location.pathname}${location.search}&tabType=detail&tabId=${value}`}>
          {value}
        </Link>
      );
    },
  },
  {
    Header: "건물명",
    accessor: "building.buildingName",
    width: 120,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return <BaseTooltip contents={value} className="text-left w-100" />;
    },
  },
  {
    Header: "위치유형",
    accessor: "building.location.category",
    width: 80,
    Cell: ({ value }: Cell<DeviceModel>) => {
      let location = value === "FLOOR" ? "층" : value === "ROOM" ? "호실" : "공용공간";

      return <span>{location}</span>;
    },
  },
  {
    Header: "층",
    accessor: "building.floor",
    width: 80,
    Cell: ({ row }: Cell<any>) => {
      const notGround = String(row.original.building.floor).includes("-");
      const value = String(row.original.building.floor).replace("-", "");
      return <span>{!notGround ? ` 지상 ${value}층` : `지하 ${value}층`}</span>;
    },
  },
  {
    Header: "호",
    accessor: "building.ho",
    width: 80,
  },
  {
    Header: "공용공간 유형",
    accessor: "building.facility",
    width: 80,
    Cell: ({ value, row }: Cell<DeviceModel>) => {
      return <span>{value === "DESK" ? "좌석" : value === "MEETING_ROOM" ? "회의실" : value === "REFRESH_ROOM" ? "편의시설" : "-"}</span>;
    },
  },
  {
    Header: "공용공간",
    accessor: "building.location.facilityName",
    width: 160,
    Cell: ({ value, row }: Cell<DeviceModel>) => {
      const facilityName = row.original.building.facility === "DESK" ? `${row.original.building.location.deskGroupName} > ${value}` : value;
      return <BaseTooltip contents={facilityName} className="text-left w-100" />;
    },
  },
  {
    Header: "IoT 구분",
    accessor: "platformDevice.platformAccount.platform",
    width: 100,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return <span>{value}</span>;
    },
  },
  {
    Header: "기기명",
    accessor: "internalDisplayName",
    width: 190,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return <span className="w-100 text-left">{value}</span>;
    },
  },
  {
    Header: "Device ID",
    accessor: "platformDevice.id",
    width: 160,
    Cell: ({ value }: Cell<DeviceModel>) => {
      return (
        <span style={{ wordBreak: "break-word" }} className="w-100 text-left font13">
          {value}
        </span>
      );
    },
  },
  {
    Header: "삭제",
    accessor: "",
    width: 60,
    Cell: ({ row, setConfirmModal }: any) => {
      return (
        <button
          className="base-trash-btn color-gray"
          onClick={(e) => {
            e.preventDefault();
            setConfirmModal({ isOpen: true, message: "삭제하시겠습니까?", payload: row.original.id });
          }}
        ></button>
      );
    },
  },
];

export default BuildingDeviceColumns;
