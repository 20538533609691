import { useMemo } from "react";
import {
  BuildingCommonFacility,
  BuildingCommonFacilityDeskGroupModel,
  BuildingModel,
  BuildingParkingLotModel,
  CommonFacilityModel,
  DeskSpaceModel,
} from "src/api/building/building-types";
import { postCommonfacilityAsync } from "src/api/building/commonfacility-api";
import { useApiOperation } from "src/api/hooks";
import { DeskSpace } from "../building-types";

const useFacility = (building: BuildingModel) => {
  const { executeAsync: postCommonfacility } = useApiOperation(postCommonfacilityAsync);

  const facility = useMemo(() => {
    const commonFacility = building?.buildingCommonFacility;
    return {
      parkingLotList: commonFacility?.parkingLotList || [],
      meetingRoomList: commonFacility?.meetingRoomList || [], //
      deskSpace: {
        deskGroupList: commonFacility?.deskSpace?.deskGroupList || [],
        mediaList: commonFacility?.deskSpace?.mediaList || [],
      },
      refreshRoomList: commonFacility?.refreshRoomList || [],
    };
  }, [building?.buildingCommonFacility]);

  // 주차 api
  const onRemoveParking = async () => {
    await postCommonfacility({ buildingId: building.id, ...facility, parkingLotList: [] });
  };

  const onUpdateParking = async (data: BuildingParkingLotModel) => {
    const parkingData: BuildingParkingLotModel = {
      buildingId: building.id,
      ...data,
    };

    const request = {
      buildingId: building.id,
      ...facility,
      parkingLotList: [parkingData],
      deskSpace: {
        deskGroupList: facility.deskSpace.deskGroupList,
        mediaList: [],
      },
    };

    await postCommonfacility(request);
  };

  // 회의실 api
  // 삭제
  const onRemoveMeetingRoom = async (id?: number) => {
    const request = {
      buildingId: building.id,
      ...facility,
      meetingRoomList: id ? [...facility.meetingRoomList.filter((item) => item.id !== id)] : [],
    };
    return await postCommonfacility(request);
  };

  // 업데이트
  const onUpdateMeetingRoom = async (data: CommonFacilityModel) => {
    const request = {
      buildingId: Number(data.buildingId), //
      ...facility,

      meetingRoomList: data.id //
        ? [...facility.meetingRoomList.map((item) => (item.id === data.id ? data : item))]
        : [...facility.meetingRoomList, data],
    };

    return await postCommonfacility(request);
  };

  // 좌석 api
  // 삭제
  const onRemoveDesk = async (desk?: DeskSpace) => {
    const request = {
      buildingId: building.id,
      ...facility,
      deskSpace: {
        deskGroupList: !desk
          ? []
          : desk?.type === "GROUP"
          ? // 그룹 삭제인 경우: 해당 그룹만 필터링
            facility.deskSpace.deskGroupList.filter((group) => Number(group.id) !== Number(desk.deskGroupId))
          : // 개별 좌석 삭제인 경우: 해당 좌석만 필터링
            facility.deskSpace.deskGroupList.map((group) => ({
              ...group,
              deskList: group.deskList?.filter((d) => !(Number(d.id) === Number(desk?.deskId) && Number(group.id) === Number(desk?.deskGroupId))),
            })),
        mediaList: facility.deskSpace.mediaList,
      },
    };

    return await postCommonfacility(request);
  };

  //좌석 그룹 업데이트
  const onUpdateDeskGroup = async (data: BuildingCommonFacilityDeskGroupModel) => {
    const request = {
      buildingId: building.id,
      ...facility,
      deskSpace: {
        deskGroupList: data.id
          ? [
              ...facility.deskSpace.deskGroupList.map((group) => {
                if (Number(group.id) === Number(data.id)) {
                  // 기존 그룹 데이터를 유지하면서 특정 필드만 업데이트
                  return {
                    ...group,
                    floorNum: data.floorNum,
                    groupName: data.groupName,
                    isGround: data.isGround,
                    description: data.description,
                    mediaList: data.mediaList,
                  };
                }
                return group;
              }),
            ]
          : [...facility.deskSpace.deskGroupList, data],
      },
    };
    return await postCommonfacility(request);
  };

  // 좌석 업데이트
  const onUpdateDesk = async (data: CommonFacilityModel) => {
    const request = {
      buildingId: building.id,
      ...facility,
      deskSpace: {
        deskGroupList: facility.deskSpace.deskGroupList.map((group) => {
          if (Number(group.id) === Number(data.buildingCommonFacilityDeskGroupId)) {
            // 이동하려는 목적지 그룹인 경우
            const newDesk = {
              ...data,
              buildingCommonFacilityDeskGroupId: group.id, // 그룹 ID 설정
            };

            return {
              ...group,
              deskList: data.id
                ? [...(group.deskList?.filter((desk) => Number(desk.id) !== Number(data.id)) || []), newDesk]
                : [...(group.deskList || []), newDesk],
            };
          } else {
            // 다른 그룹들의 경우 해당 desk를 제거
            return {
              ...group,
              deskList: group.deskList?.filter((desk) => Number(desk.id) !== Number(data.id)) || [],
            };
          }
        }),
      },
    };

    return await postCommonfacility(request);
  };

  // //좌석 업데이트
  // const onUpdateDesk = async (data: CommonFacilityModel) => {
  //   const request = {
  //     buildingId: building.id,
  //     ...facility,
  //     deskSpace: {
  //       deskGroupList: facility.deskSpace.deskGroupList.map((group) => {
  //         // 현재 그룹이 desk가 속한 그룹이 아니면 그대로 반환
  //         if (Number(group.id) !== Number(data.buildingCommonFacilityDeskGroupId)) {
  //           return group;
  //         }

  //         // desk가 속한 그룹인 경우 deskList 수정
  //         return {
  //           ...group,
  //           deskList: data.id //
  //             ? group.deskList?.map((desk) => (Number(desk.id) === Number(data.id) ? data : desk)) || []
  //             : [...(group.deskList || []), data],
  //         };
  //       }),
  //     },
  //   };

  //   return await postCommonfacility(request);
  // };

  // 편의시설 api
  // 삭제
  const onRemoveRefreshRoom = async (id?: number) => {
    const request = {
      buildingId: building.id,
      ...facility,
      refreshRoomList: id ? [...facility.refreshRoomList.filter((item) => item.id !== id)] : [],
    };
    return await postCommonfacility(request);
  };

  // 업데이트
  const onUpdateRefreshRoom = async (data: CommonFacilityModel) => {
    const request = {
      buildingId: Number(data.buildingId), //
      ...facility,

      refreshRoomList: data.id //
        ? [...facility.refreshRoomList.map((item) => (item.id === data.id ? data : item))]
        : [...facility.refreshRoomList, data],
    };
    return await postCommonfacility(request);
  };

  return {
    onRemoveParking,
    onUpdateParking,
    onRemoveMeetingRoom,
    onUpdateMeetingRoom,
    onRemoveDesk,
    onUpdateDesk,
    onRemoveRefreshRoom,
    onUpdateRefreshRoom,
    onUpdateDeskGroup,
  };
};

export default useFacility;
