import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import { HistoryUpdate } from "./history-types";

/* 
   변경내역
*/
export async function getHistoryListAsync(axios: AxiosInstance, params?: any): Promise<AxiosResponse<ApiResponse<{ content: HistoryUpdate[] }>>> {
  return await axios.get("/api/ctrl/partners/{partnerId}/pa/audit/logs", {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}
