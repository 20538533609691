import React from "react";
import { useLocation } from "react-router-dom";
import { facilityTabs } from "src/pages/building/building-types";
import useSubTabs from "src/pages/building/hooks/useSubTabs";

type Props = {
  buildingId: string;
};

const FacilitySubTabs = ({ buildingId }: Props) => {
  const { selectedTab, handleSelectTab } = useSubTabs();
  const { pathname } = useLocation();

  return (
    <div className="sub-tab border-bottom-gray">
      <ul className="">
        {facilityTabs.map((tab) => (
          <li
            key={tab.value}
            // className={` ${tab.value === selectedTab!.value && "border-gray"}  `}
            // className={`border-gray`}
            style={{
              listStyle: "none", //
              backgroundColor: tab.value === selectedTab!.value ? "white" : "#f2f4f6",
              borderTop: "1px solid #f2f4f6",
              borderLeft: "1px solid #f2f4f6",
              borderRight: "1px solid #f2f4f6",
            }}
          >
            <button
              className={` ${tab.value !== selectedTab!.value && "text-primary3"}`}
              style={{ fontSize: "15px" }}
              onClick={() => {
                const formPath = `?id=${buildingId}&tab=facility&facilityType=${tab.value}`;
                const detailPath = `?tab=facility&facilityType=${tab.value}`;
                handleSelectTab(tab, pathname.includes("detail") ? detailPath : formPath);
              }}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FacilitySubTabs;
