import { Sort } from "../public-types";

// 건물 / 상품 / iot 등록된 목록 queryParams
export interface IotDeviceQueryParams {
  page?: number;
  size?: number;
  id?: string | number;
  buildingId?: string | number;
  buildingLocation?: Array<{ category: string; identifier: string }>;
  productId?: string | number;
  registeredDeviceId?: string | number;
  contractManageId?: string | number;
  search001?: string;
  sort?: Sort;
}

/** 건물 디바이스 목록 response*/

/** 건물 디바이스 내에 건물정보*/
export interface DeviceInBuilding {
  id: number; //건물 아이디
  location: BuildingDeviceLocation; //건물 위치 정보
  facility?: string;
  floor?: string | number;
  ho?: string | number;
  buildingName?: string;
}

/** 건물 디바이스 내에 건물 위치정보*/
export interface BuildingDeviceLocation {
  category: UnionBuildingLocation; //건물 위치 카테고리
  identifier: string; //건물 위치 아이디
  // 핫픽스로 FE 에서 공용공간 명 처리
  facilityName?: string;
  deskGroupName?: string;
}

export const BuildingLocation = {
  FLOOR: "FLOOR",
  ROOM: "ROOM",
  FACILITY: "FACILITY",
} as const;

export type UnionBuildingLocation = (typeof BuildingLocation)[keyof typeof BuildingLocation] | "";

export const BuildingLocationCategory = [
  { value: "FLOOR", label: "층" },
  { value: "ROOM", label: "층/호" },
  { value: "FACILITY", label: "공용공간" },
];

/** 플랫폼 디바이스 정보*/

export interface PlatformDevice {
  id: number; //디바이스 아이디
  platformAccount: PlatformAccountType; //IoT 플랫폼 계정 정보
  name: string; //디바이스 이름
  label: string; //디바이스 레이블
  locationName: string; //위치 이름
  type: string; // 디바이스 타입
  registeredDeviceId?: number; //등록 된 디바이스 아이디 - 해당 값이 존재 하면 등록 된 디바이스
}

interface PlatformAccountType {
  email: string; //계정 이메일
  platform: UnionPlatformAccountCategory; // IoT 구분
}

const PlatformAccountCategory = {
  ST: "ST", //SmartThings
  B_IOT: "B_IOT", //b.IoT
} as const;

export type UnionPlatformAccountCategory = (typeof PlatformAccountCategory)[keyof typeof PlatformAccountCategory];

// 건물 iot 디바이스 쿼리 - 추후 삭제예정
// export interface IotBuildingDeviceQueryParams {
//   page?: number;
//   size?: number;
//   id?: string | number;
//   buildingId?: string | number;
//   buildingLocation?: Array<{ category: string; identifier: string }>;
//   search001?: string;
// }

// 등록된 디바이스 쿼리
export interface RegisteredDeviceQueryParams {
  page?: number;
  size?: number;
  search002?: string;
  sort?: Sort;
}

// 건물 디바이스 등록
export interface BuildingDeviceAddModel {
  registeredDeviceId: string | number; //등록 디바이스 아이디
  building: {
    id: string | number; //건물 아이디
    location: {
      category: UnionBuildingLocation; //건물의 카테고리 위치 정보 - 층/호/공용공간
      identifier: string; //건물 위치 아이디
    };
  };
}

//  Iot 등록된 디바이스 목록
export interface IoTDeviceRegistration {
  id: string | number;
  parterId: string | number;
  displayName: string;
  platformDevice: PlatformDevice;
  buildingDeviceIds?: string[] | number[];
}

// Iot 디바이스 컨트롤 목록
export interface BuildingDeviceControl {
  buildingDeviceId: number;
  control: RegisteredDeviceControl;
  labels?: IotLabel[];
}

// 건물 디바이스 컨트롤 목록제어
export interface RegisteredDeviceControl {
  id: number; //등록 디바이스 제어 아이디
  displayName: string; //제어명
  method: UnionDeviceControlMethod; //제어 구분
  componentId: string; //컴포넌트 아이디
  capabilityId: string; //기능 아이디
  commandPart: DeviceControlCommandPart; //명령 실행 파트
}

export const DeviceControlMethod = {
  READ_STATUS: "READ_STATUS", //상태조회
  EXEC_COMMANDS: "EXEC_COMMANDS", // 명령실행
} as const;

export type UnionDeviceControlMethod = (typeof DeviceControlMethod)[keyof typeof DeviceControlMethod] | "";

export interface DeviceControlCommandPart {
  command: string;
  arguments: string[];
}

export interface DeviceControlParams {
  deviceType: string;
  deviceId: string | number;
  controlId: string | number;
  controlMethod: UnionDeviceControlMethod;
  arguments: string | number;
  cacheable?: boolean;
}

//============ 상품 IoT 타입 ============

/** 상품 디바이스 목록 response*/

// - 추후 삭제예정
// export interface ProductDevicesQueryParams {
//   page?: number;
//   size?: number;
//   id?: string | number;
//   productId?: string | number;
//   registeredDeviceId?: string | number;
//   sort?: Sort;
// }

//상품 디바이스 목록 타입

// 상품 Iot 디바이스 컨트롤 목록
export interface ProductDeviceControl {
  productDeviceId: number;
  control: RegisteredDeviceControl;
}

export interface ProductDeviceAddType {
  productId?: number;
  buildingDeviceIds: number[];
}

// iot 라벨
export type IotLabel = {
  name: string;
  value: string;
};

//============ 신청계약 IoT 타입 ============

//상품 디바이스 목록 타입
export interface DeviceModel {
  id: number; //건물 디바이스 아이디
  building: DeviceInBuilding; // 건물 정보
  registeredDeviceId: number; // 등록 디바이스 아이디
  // displayName?: string; // 등록 디바이스 기기명
  internalDisplayName?: string; // 등록 디바이스 기기명
  platformDevice: PlatformDevice; //플랫폼 디바이스 정보
  labels?: IotLabel[];
}
export interface ProductDeviceModel extends DeviceModel {
  productId: number; //상품 아이디
}

export interface ContractDeviceModel extends DeviceModel {
  contractManageId: number; //상품 아이디
}

// 계약 디바이스 추가 type
export interface ContractDeviceAddType {
  contractManageId: number;
  buildingDeviceIds: number[];
}

// 계약 Iot 디바이스 컨트롤 목록
export interface ContractDeviceControl {
  contractDeviceId: number;
  control: RegisteredDeviceControl;
}

// 계약 디바이스 사용자 목록 조회 타입
export interface ContractControlMemberParams {
  contractDeviceId: string | number; //건물 디바이스 아이디
  controlId: string | number; //제어 아이디
  memberNo?: string; // 회원 번호
  memberNos?: string[];
  controlMemberId?: string; // 디바이스 제어 사용자 아이디
}

export interface ContractDeviceControlMember {
  id: string | number; //아이디
  contractDeviceId: string | number; //계약 디바이스 아이디
  controlId: string | number; //등록 디바이스 제어 아이디
  memberNo: string; //회원 번호
  phoneNumber: string; //휴대폰 번호
  email: string; //이메일 주소
  nickname?: string; //닉네임
}
