import React, { useRef } from "react";
import { BaseButton } from "src/components";
import { useModal } from "src/recoil/modalState/hook";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LoadingSpinner from "src/components/LoadingSpinner";
import { AIAnalysisData } from "./useAIAnalysis";
import { ViewYmdFormat, YmdFormat } from "src/utils";
import MarkdownViewer from "src/components/MarkdownViewer";

const AIReportModal = ({
  AIAnalysisDatas,
  dateRange,
  totalElements,
}: {
  AIAnalysisDatas: AIAnalysisData[];
  dateRange: [Date | null, Date | null];
  totalElements: number | undefined;
}) => {
  const { setAbstractModalZ2, setBaseModal } = useModal();
  const reportRef = useRef<HTMLDivElement>(null);

  const handleDownloadPDF = async () => {
    if (!reportRef.current) return;

    try {
      // 1. PDF 생성 중임을 알리는 로딩 모달 표시
      // setBaseModal({
      //   isOpen: true,
      //   title: "PDF 생성 중",
      //   children: <LoadingSpinner />,
      // });

      // 2. HTML을 캔버스로 변환
      // scale: 해상도를 2배로 증가
      // useCORS: 외부 이미지 로드 허용
      // backgroundColor: 배경색 지정
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      // 3. PDF 크기 계산
      // - A4 용지 기준으로 비율 계산
      // - 캔버스 비율을 유지하면서 PDF 크기 조정
      const imgWidth = 210; // A4 가로 크기 (mm)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const dynamicHeight = Math.max(297, imgHeight); // 최소 A4 높이 유지

      // 4. PDF 객체 생성
      // - portrait: 세로 방향
      // - unit: 밀리미터 단위
      // - format: 동적으로 계산된 크기 적용
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, dynamicHeight],
      });

      // 5. 캔버스 이미지를 PDF에 추가
      // - PNG 형식으로 변환
      // - 매개변수: (이미지데이터, 형식, x좌표, y좌표, 너비, 높이)
      pdf.addImage(
        canvas.toDataURL("image/png"), // 이미지 데이터: 캔버스를 PNG 형식의 데이터 URL로 변환
        "PNG", // 이미지 형식: PNG 포맷 지정
        0, // x좌표: 왼쪽 여백 (mm)
        0, // y좌표: 상단 여백 (mm)
        imgWidth, // 너비: A4 용지 너비에 맞춤 (210mm)
        imgHeight, // 높이: 비율에 맞게 계산된 높이
      );

      // 6. PDF 파일 저장
      // - 파일명에 현재 날짜와 시간 포함
      const fileName = `AI_Analysis_Report_${moment().format("YYYY.MM.DD_HH:mm")}.pdf`;
      pdf.save(fileName);

      // 7. 작업 완료 후 로딩 모달 닫기
      // setBaseModal({ isOpen: true, title: "PDF 생성이 완료됐습니다.", btnRightTitle: "확인" });
    } catch (error) {
      // 8. 에러 처리
      // - 콘솔에 에러 로그 출력
      // - 사용자에게 에러 메시지 표시
      console.error("PDF generation failed:", error);
      setBaseModal({
        isOpen: true,
        title: "다운로드 실패",
        children: "PDF 생성 중 오류가 발생했습니다.",
        btnRightTitle: "확인",
        onClick: () => setBaseModal({ isOpen: false }),
      });
    }
  };

  // PDF 생성용 컴포넌트
  const ReportContent = () => (
    <div className="report-content pdf-content pre-formatted">
      {/* Common Issues */}
      <div className="report-section">
        <div className="title-block">
          <div className="icon-flash"></div>
          <h2>Common issues</h2>
        </div>
        <div className="report-block">
          {AIAnalysisDatas.map((data, index) => {
            if (data.promptKey === "CommonIssues") {
              return <MarkdownViewer key={data.promptKey} value={data.content}></MarkdownViewer>;
            }
          })}
        </div>
      </div>
      {/* Risks */}
      <div className="report-section">
        <div className="title-block">
          <div className="icon-warning"></div>
          <h2>Risks</h2>
        </div>
        <div className="report-block">
          {AIAnalysisDatas.map((data, index) => {
            if (data.promptKey === "Risks") {
              return <MarkdownViewer key={data.promptKey} value={data.content}></MarkdownViewer>;
            }
          })}
        </div>
      </div>
      {/* Complainant sentiments */}
      <div className="report-section">
        <div className="title-block">
          {/* <img src="/assets/images/icon-chart.svg" alt="" /> */}
          <div className="icon-chart"></div>
          <h2>Complainant sentiments</h2>
        </div>
        <div className="report-block">
          {AIAnalysisDatas.map((data, index) => {
            if (data.promptKey === "ComplainantSentiments") {
              return <MarkdownViewer key={data.promptKey} value={data.content}></MarkdownViewer>;
            }
          })}
        </div>
      </div>
      {/* Patterns */}
      <div className="report-section">
        <div className="title-block">
          {/* <img src="/assets/images/icon-pattern.svg" alt="" /> */}
          <div className="icon-pattern"></div>
          <h2>Patterns</h2>
        </div>
        <div className="report-block">
          {AIAnalysisDatas.map((data, index) => {
            if (data.promptKey === "Patterns") {
              return <MarkdownViewer key={data.promptKey} value={data.content || "No content"}></MarkdownViewer>;
            }
          })}
        </div>
      </div>
      {/* Suggestions */}
      <div className="report-section">
        <div className="title-block">
          {/* <img src="/assets/images/icon-suggestion.svg" alt="" /> */}
          <div className="icon-suggestion"></div>
          <h2>Suggestions</h2>
        </div>
        <div className="report-block" style={{ background: "#F8F8FA" }}>
          {AIAnalysisDatas.map((data, index) => {
            if (data.promptKey === "Suggestions") {
              return <MarkdownViewer key={data.promptKey} value={data.content}></MarkdownViewer>;
            }
          })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div ref={reportRef} className="pdf-only">
        <section className="base-abstract-modal__title">
          <div className="report-title-wrap">
            <div className="flex-center-between gp4">
              <div className="logo_AI"></div>
              <p className="created">Created : {moment().format("YYYY.MM.DD HH:mm")}</p>
            </div>
            <p className="title-wrap">
              AI Analysis Report<span>Beta</span>
            </p>
            <div className="flex-center-between">
              <div className="reporting-period">
                <span>Reporting Period : </span>
                <p>
                  {moment(dateRange[0]).format(ViewYmdFormat.YYYY_MM_DD)} - {moment(dateRange[1]).format(ViewYmdFormat.YYYY_MM_DD)}
                  <span className="text-sm">(Total Analyzed Entries: {totalElements ?? "-"})</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="base-abstract-modal__contents px30">
          <ReportContent />
        </section>
      </div>

      <section className="base-abstract-modal__title">
        <div className="report-title-wrap">
          <div className="flex-center-between gp4">
            <div className="logo_AI"></div>
            <p className="created">Created : {moment().format("YYYY.MM.DD HH:mm")}</p>
          </div>
          <p className="title-wrap">
            AI Analysis Report<span>Beta</span>
          </p>
          <div className="flex-center-between">
            <div className="reporting-period">
              <span>Reporting Period : </span>
              <p>
                {moment(dateRange[0]).format(ViewYmdFormat.YYYY_MM_DD)} - {moment(dateRange[1]).format(ViewYmdFormat.YYYY_MM_DD)}
                <span className="text-sm">(Total Analyzed Entries: {totalElements ?? "-"})</span>
              </p>
            </div>

            <BaseButton
              title={
                (
                  <div className="flex-center-center gap8">
                    <div className="free-icon-pdf"></div>
                    <span className="font-weight-semibold">PDF Download</span>
                  </div>
                ) as any as string
              }
              className="color-white px10"
              onClick={handleDownloadPDF}
            />
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__contents px30">
        <ReportContent />
      </section>

      <section className="base-abstract-modal__btn-wrap justify-center">
        <BaseButton title={"Close"} className="color-black" onClick={() => setAbstractModalZ2({ isOpen: false })} />
      </section>
    </>
  );
};

export default AIReportModal;
