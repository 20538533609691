import { I18n } from "i18n-js";
import { localesKo } from "./locales/ko";
import { localesEn } from "./locales/en";
import _ from "lodash";

// 브라우저에서 사용중인 언어 확인
const browserLanguage = navigator.language.split("-")[0];

// 다국어 설정 JSON 형태로 변환
const flatToNestedObject = (flat: { [props: string]: any }) => {
  const nested: { [props: string]: any } = {};
  Object.keys(flat).forEach((path) => {
    _.set(nested, path, flat[path]);
  });

  return nested;
};

// i18n 인스턴스 생성
const i18n = new I18n(
  {
    en: flatToNestedObject(localesEn),
    ko: flatToNestedObject(localesKo),
  },
  {
    defaultLocale: "ko",
    locale: browserLanguage === "ko" ? "ko" : "en",
    enableFallback: true,
  },
);

export default i18n;

// ==============
