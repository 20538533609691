import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import {
  deleteBuildingIotDeviceAsync,
  deleteContractDeviceAsync,
  deleteProductDeviceAsync,
  getBuildingDeviceAsync,
  getContractDeviceAsync,
  getProductDeviceAsync,
  postContractDevicesAsync,
  postProductDevicesAsync,
} from "src/api/iot/iot-api";
import { ContractDeviceAddType, DeviceModel, IotDeviceQueryParams, ProductDeviceAddType } from "src/api/iot/iot-types";
import { PageMeta } from "src/api/public-types";
import { useErrorModal } from "src/recoil/errorModal/hook";
import { iotErrors } from "../iot-error";

type Options = {
  executeApi?: boolean;
};

export function useIotDevice(params: IotDeviceQueryParams, options?: Options) {
  const location = useLocation();
  const query = useMemo(() => qs.parse(location.search, { allowDots: true, ignoreQueryPrefix: true }), [location]);
  const [devicses, setDevices] = useState<DeviceModel[]>([]);

  const [buildings, setBuildings] = useState<BuildingModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { openErrorModal } = useErrorModal();

  //====== 건물 APIs ======
  // 건물 상세 조회 api hook
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // 건물 IoT 기기 탭 목록
  const { executeAsync: getBuildingDevice } = useApiOperation(getBuildingDeviceAsync);
  //  건물 디바이스 목록 삭제
  const { executeAsync: deleteBuildingDevice } = useApiOperation(deleteBuildingIotDeviceAsync);

  //====== 상품 APIs ======
  // 상품 IoT 기기 탭 목록
  const { executeAsync: getProductDevice } = useApiOperation(getProductDeviceAsync);

  //  상품 디바이스 목록 삭제
  const { executeAsync: deleteProductDevice } = useApiOperation(deleteProductDeviceAsync);

  // 기기추가 api
  const { executeAsync: addProductDevice } = useApiOperation(postProductDevicesAsync, { noHandleError: true });

  //====== 계약 APIs ======

  // 계약 IoT 기기 탭 목록
  const { executeAsync: getContractDevice } = useApiOperation(getContractDeviceAsync);

  //  계약 디바이스 목록 삭제
  const { executeAsync: deleteContractDevice } = useApiOperation(deleteContractDeviceAsync);

  // 기기추가 api
  const { executeAsync: addContractDevice } = useApiOperation(postContractDevicesAsync, { noHandleError: true });

  // 건물/상품/계약 > iot기기 탭에 등록된 디바이스 목록
  const getDevices = async (passParams: IotDeviceQueryParams) => {
    if (options?.executeApi) {
      let result: DeviceModel[] = [];
      //건물 디바이스
      if (passParams.buildingId) {
        const { data, status } = await getBuildingDevice(passParams);

        if (status >= 200 && status <= 299) {
          result = data.data.content;
          setDevices(result);
          setPageMeta(data.meta.pageMeta);
        }
      }

      // 상품 디바이스
      else if (passParams.productId) {
        const { data, status } = await getProductDevice(passParams);

        if (status >= 200 && status <= 299) {
          result = data.data.content;
          setDevices(result);
          setPageMeta(data.meta.pageMeta);
        }
      }
      //계약 디바이스
      else if (passParams.contractManageId) {
        const { data, status } = await getContractDevice(passParams);
        if (status >= 200 && status <= 299) {
          result = data.data.content;
          setDevices(result);
        }
      }
      await fetchAllBuildingDetails(result);
    }
  };

  // 디바이스 목록 삭제
  const deleteDevice = async (id: string) => {
    const path = location.pathname;
    let deleteFunction;

    if (path.includes("product")) {
      deleteFunction = deleteProductDevice;
    } else if (path.includes("contract")) {
      deleteFunction = deleteContractDevice;
    } else if (path.includes("building")) {
      deleteFunction = deleteBuildingDevice;
    }

    if (deleteFunction) {
      const { status } = await deleteFunction({ id: Number(id) });
      if (status >= 200 && status <= 299) {
        await getDevices(params);
      }
    }
  };

  // 에러 팝업
  const handleError = async (result: any) => {
    const allFail = result.every((item: any) => item.status > 399); // 전부 실패

    const partialSuccess = result.some((item: any) => item.status >= 200 && item.status <= 299); // 부분 성공

    const successedDisplay = result.filter((item: any) => item.status < 300).map((item: any) => item.device.internalDisplayName); // 추가 성공 목록

    const duplicatedDisplay = result.filter((item: any) => item.status >= 400).map((item: any) => item.device.internalDisplayName); // 추가했던 기기명

    const errorCode = result.filter((item: any) => item.status > 399).map((item: any) => item.response.meta.errorCode);

    const notDuplicatesErrorCode = [...new Set(errorCode)]; // 에러코드 배열 중복제거

    const findErrorMessage = iotErrors.find((item) => notDuplicatesErrorCode.includes(item.errorCode))?.message; // 에러코드로 에러 메세지 찾기

    const errorMessage = `${
      partialSuccess ? "일부 기기 추가를 실패하였습니다." : allFail ? "기기 추가를 실패하였습니다." : ""
    } \n ${findErrorMessage} `;

    openErrorModal({
      errorMessage: findErrorMessage ? errorMessage : String(notDuplicatesErrorCode.join(",")).replace(",", "\n"),
      statusCode: result.find((item: any) => item.status > 299).status,
      errorList: [`성공: ${successedDisplay.length}건`, `실패: ${duplicatedDisplay.length}건`],
      errorSubList: duplicatedDisplay,
    });

    await getDevices(params);
  };

  // 디바이스 추가
  const addDevices = async (request: any) => {
    const deviceData = request.buildingDeviceData;
    try {
      if (deviceData.length > 0) {
        const results = await Promise.all(
          deviceData.map(async (device: any) => {
            if (request.productId) {
              const { data, status } = await addProductDevice({
                productId: request.productId,
                buildingDeviceIds: [Number(device.buildingDeviceId)],
              } as ProductDeviceAddType);
              if (status >= 200 && status <= 299) {
                console.log(`상품 생성`);
              }
              return { request, response: data, status, device };
            }

            if (request.contractManageId) {
              const { data, status } = await addContractDevice({
                contractManageId: request.contractManageId,
                buildingDeviceIds: [Number(device.buildingDeviceId)],
              } as ContractDeviceAddType);
              if (status >= 200 && status <= 299) {
                console.log(`계약 생성`);
              }
              return { request, response: data, status, device };
            }
          }),
        );

        if (results.some((item) => item.status > 399)) {
          handleError(results);
        }

        // 마지막으로 getDevices 호출
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      await getDevices(params);
      console.log("추가 finaly 호출");
    }
  };

  useEffect(() => {
    if (!query.tabType || query.tabType === "detail") {
      getDevices(params);
    }
  }, [
    params.contractManageId,
    params.productId,
    params.search001,
    params.page,
    params.size,
    params.sort?.orders,
    query.tabType,
    options?.executeApi,
  ]);

  // 건물 상세 호출
  const fetchBuildingDetail = async (id: number) => {
    if (id) {
      const { data, status } = await getBuilding({ buildingId: id });
      if (status >= 200 && status <= 299) {
        return data.data.content.building;
      }
    }
  };

  // 건물 상세 api
  const fetchAllBuildingDetails = async (deviceList: DeviceModel[]) => {
    const buildingIds = String(params.buildingId ? params.buildingId : deviceList.map((device) => device.building.id)).split(",");
    const notDuplicatesIds = [...new Set(buildingIds)]; // 건물 id 중복제거

    if (buildingIds.length > 0 && options?.executeApi) {
      //
      //  건물 id 갯수만큼 상세 호출
      const buildingsPromise = notDuplicatesIds.map((id) => fetchBuildingDetail(Number(id)));
      try {
        const allBuildings = await Promise.all(buildingsPromise);
        setBuildings(allBuildings as BuildingModel[]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // react-table에 전달한 목록 (data:deviceList)
  const iotDevices = useMemo(() => {
    let _iotDevices: any = [];
    if (devicses.length > 0 && buildings.length > 0) {
      // 건물 층 정보
      const buildingFloor = buildings?.flatMap((buildnig) => buildnig?.buildingFloorList);

      // 건물 층/호 정보
      const buildingHo = buildingFloor?.flatMap((floor) =>
        floor?.buildingRoomList?.map((room: any) => ({ floorNum: floor.floorNum, floorName: floor.floorName, ...room })),
      );

      // 건물 공용공간 정보
      const buildingFacility = buildings?.flatMap((building) => building?.buildingCommonFacility);
      const meetingRooms = buildingFacility.flatMap((facility) => facility?.meetingRoomList) || [];
      const refreshRooms = buildingFacility.flatMap((facility) => facility?.refreshRoomList) || [];
      const deskList =
        buildingFacility
          .flatMap((facility) => facility?.deskSpace?.deskGroupList)
          ?.filter((group) => group?.deskList && group.deskList.length > 0)
          ?.flatMap((group) => group?.deskList?.map((desk) => ({ ...desk, groupName: group.groupName }))) || [];
      const facilityMerged = [...meetingRooms, ...refreshRooms, ...deskList];

      _iotDevices = devicses.map((device) => {
        const floorNum = buildingFloor?.find(
          (floor) => device.building.location.category === "FLOOR" && String(floor?.id) === String(device.building.location.identifier),
        )?.floorNum;

        const floorHoNum = buildingHo?.find(
          (room) => device.building.location.category === "ROOM" && String(room?.id) === String(device.building.location.identifier),
        )?.floorNum;

        const facility = facilityMerged?.find((room) => String(room?.id) === String(device.building.location.identifier));

        // 공용공간 명칭
        const facilityName = facility?.facilityName;
        // 좌석 그룹명 - DESK 면 좌석그룹명 까지 노출
        const deskGroupName = facility?.groupName;

        let data = {
          id: device.id,
          building: {
            ...device.building,
            buildingName: buildings.find((building) => building?.id === device.building?.id)?.buildingName,
            floor:
              floorNum || //
              floorHoNum ||
              `${!facility?.isGround ? "-" : ""}${facility?.floorNum}` ||
              "-",
            ho:
              (device.building.location.category === "ROOM" &&
                buildingHo?.find((room) => String(room?.id) === String(device.building.location.identifier))?.roomNum) ||
              "-",
            facility: facility?.commonFacilityType || "-",
            location: {
              ...device.building.location, //
              // 핫픽스 추가 facilityName
              facilityName: facilityName || "-",
              deskGroupName: deskGroupName || "",
            },
          },
          internalDisplayName: device.internalDisplayName,
          registeredDeviceId: device.registeredDeviceId,
          labels: device.labels,
          platformDevice: {
            ...device.platformDevice,
            platformAccount: {
              ...device.platformDevice.platformAccount,
              platform: device.platformDevice.platformAccount.platform === "ST" ? "SmartThings" : "b.IoT",
            },
          },
        };

        return data;
      });
    }

    return _iotDevices;
  }, [buildings, devicses]);
  return { iotDevices, buildings, deleteDevice, addDevices, pageMeta, getDevices };
}
