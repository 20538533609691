import { useEffect, useMemo, useState } from "react";
import { Cell } from "react-table";
import { BuildingCommonFacilityDeskGroupModel, BuildingModel, CommonFacilityModel } from "src/api/building/building-types";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, BaseToggle, BaseTooltip, Tab } from "src/components";
import { DeskSpace, SelectOption, facilitySubTabs, floorOptions, sortingDeskGroupList } from "src/pages/building/building-types";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import useFacility from "src/pages/building/hooks/useFacility";
import Esl from "../components/Esl";
import DeskFormModal from "./DeskFormModal";
import DeskGroupFormModal from "./DeskGroupFormModal";
import { useModal } from "src/recoil/modalState/hook";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { PaPartner } from "src/types/partner";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useRecoilValue } from "recoil";
import { useApiOperation } from "src/api/hooks";
import { getBuildingAsync } from "src/api/building/building-api";
import _ from "lodash";

type Props = {
  building: BuildingModel;
  fetchBuilding: (buildingId: number) => void;
};

/* 
  좌석 컴포넌트
 */
const DeskSection = ({ building, fetchBuilding }: Props) => {
  const partner = useRecoilValue(globalPartnerState);

  // 출입그룹 기능 활성 권한 여부
  const isAccessFeature = partner?.features?.some((feature: PaPartner) => feature.name === "FEATURE_RESERVATION_ACCESS" && feature.active) || false;

  const { isAuthority, fetchAuthorityReadonly } = usePartnerAuthority();

  const [isUsedFacility, setIsUsedFacility] = useState(false);

  const [activeTab, setActiveTab] = useState<Tab>(facilitySubTabs[0]);

  const [newFacility, setNewFacility] = useState<CommonFacilityModel>();

  // 새로운 통합된 모달 상태
  const [deskModal, setDeskModal] = useState<Modal & { readonly?: boolean }>({
    isOpen: false,
    readonly: false,
  });

  const [deskGroupModal, setDeskGroupModal] = useState<Modal & { readonly?: boolean }>({
    isOpen: false,
    readonly: false,
  });

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const { setBaseModal } = useModal();
  const { onUpdateDesk, onRemoveDesk, onUpdateDeskGroup } = useFacility(building);
  const { executeAsync: getBuilding } = useApiOperation(getBuildingAsync);

  // UI 에서 사용할 좌석목록 배열
  const deskSpaces: Array<DeskSpace> = useMemo(() => {
    // UI 에서 사용할 좌석목록 배열
    const deskSpaces: Array<DeskSpace> = [];
    const desks = sortingDeskGroupList(building?.buildingCommonFacility?.deskSpace?.deskGroupList || []);

    // 건물 상세조회 api 에서 내려준 좌석 목록 (정렬후)

    if (desks.length > 0) {
      for (let i = 0; i < desks.length; i++) {
        const commonfacility: BuildingCommonFacilityDeskGroupModel = desks[i];

        const isGround: boolean = commonfacility?.isGround === true; // 지상여부
        const floorNum: number = commonfacility?.floorNum || 1; // 층
        const floor = `${isGround ? "" : "-"}${floorNum}`;

        // 좌석 그룹
        const deskGroup: DeskSpace = {
          type: "GROUP",
          deskGroupId: commonfacility?.id,
          floor,
          deskGroupName: commonfacility.groupName,
          deskGroupDescription: commonfacility.description,
          isGray: true,
          mediaList: commonfacility.mediaList,
        };
        deskSpaces.push(deskGroup);

        // 좌석
        (commonfacility?.deskList || []).forEach((commonFacilityDesk: CommonFacilityModel) => {
          const desk: DeskSpace = {
            type: "DESK",
            deskGroupId: deskGroup.deskGroupId,
            deskId: Number(commonFacilityDesk.id),
            deskName: commonFacilityDesk.facilityName,
            locationCode: commonFacilityDesk.locationCode,
          };
          deskSpaces.push(desk);
        });
      }
    }
    // floor 값을 숫자로 변환하여 정렬

    return deskSpaces;
  }, [building]);

  const handleResetStates = () => {
    setNewFacility(undefined);
    setActiveTab(facilitySubTabs[0]);
  };

  useEffect(() => {
    setIsUsedFacility(deskSpaces.length > 0 ? true : false);
  }, [deskSpaces]);

  const columns: Array<any> = useMemo(
    () => [
      {
        Header: "id",
        accessor: "deskGroupId",
        width: 80,
        Cell: ({ row, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          const deskId = row?.original?.deskId || "";

          return (
            <div
              className={`text-underline ${isGroupRow ? "font-weight-medium" : ""}`}
              style={{ cursor: "pointer", color: "#007df0" }} //
              onClick={() => {
                handleResetStates();
                if (isGroupRow) {
                  setDeskGroupModal({
                    isOpen: true,
                    type: "GROUP",
                    payload: row?.original,
                    readonly: true,
                  });
                } else {
                  setDeskModal({
                    isOpen: true,
                    type: "DESK",
                    payload: row?.original,
                    readonly: true,
                  });
                }
              }}
            >
              <BaseTooltip contents={isGroupRow ? value : deskId} isSingleLine={true} />
            </div>
          );
        },
      },
      {
        Header: "위치",
        accessor: "floor",
        width: 120,
        Cell: ({ row, value }: any) => {
          const floor = floorOptions.find((option: SelectOption) => option.value === value);
          const isGroupRow = row?.original?.type === "GROUP";
          return <div className={`${isGroupRow ? "font-weight-medium" : ""}`}>{floor?.label || ""}</div>;
        },
      },
      {
        Header: "좌석 그룹 명",
        accessor: "deskGroupName",
        width: 120,
        Cell: ({ row, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          return <BaseTooltip contents={value} className={`${isGroupRow ? "font-weight-medium" : ""} text-left w-100`} />;
        },
      },
      {
        Header: "설명",
        accessor: "deskGroupDescription",
        width: 120,
        Cell: ({ row, value }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          return <BaseTooltip contents={value} className={`${isGroupRow ? "font-weight-medium" : ""} text-left w-100`} />;
        },
      },
      {
        Header: "이미지",
        accessor: "mediaList",
        width: 80,
        Cell: ({ row }: Cell<any>) => {
          const imageCount = (row?.original?.mediaList || []).length;
          const isGroup = row.original.type === "GROUP";
          return <>{isGroup && <div className={`${isGroup ? "font-weight-medium" : ""}`}>{imageCount}</div>}</>;
        },
      },
      {
        Header: "좌석 수량",
        accessor: "deskCount",
        width: 80,
        Cell: ({ row, data }: any) => {
          const isGroupRow = row?.original?.type === "GROUP";
          if (isGroupRow) {
            // 데스크 그룹의 경우, 데스크 합계 갯수를 보여준다
            const deskList = (data || []).filter((d: DeskSpace) => d.type === "DESK" && d.deskGroupId === row?.original?.deskGroupId);
            return <div className={`${isGroupRow ? "font-weight-medium" : ""}`}>{deskList.length}</div>;
          } else {
            return <div className="ic_ho"></div>;
          }
        },
      },
      {
        Header: "좌석 명",
        accessor: "deskName",
        width: 120,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} className="text-left w-100" />;
        },
      },
      {
        Header: "Location code",
        accessor: "locationCode",
        width: 160,
        Cell: ({ value }: any) => {
          return <BaseTooltip contents={value} />;
        },
      },
      {
        Header: "ESL",
        accessor: "eslAddress",
        width: 120,
        Cell: ({ row }: any) => (
          <div className={`${row?.original?.type === "GROUP" ? "font-weight-medium" : ""}`}>
            <Esl row={row} />
          </div>
        ),
      },

      {
        Header: "수정",
        accessor: "edit",
        width: 80,
        Cell: ({ row }: Cell<DeskSpace>) => (
          <BaseButton
            title="수정"
            className="color-white"
            onClick={() => {
              handleResetStates();
              if (row.original.type === "GROUP") {
                setDeskGroupModal({
                  isOpen: true,
                  type: row.original.type,
                  payload: row.original,
                });
              } else {
                setDeskModal({
                  isOpen: true,
                  type: row.original.type,
                  payload: row.original,
                });
              }
            }}
          />
        ),
      },
      {
        Header: "삭제",
        accessor: "delete",
        width: 40,
        Cell: ({ row }: Cell<DeskSpace>) => (
          <button
            className="base-trash-btn color-gray"
            onClick={(e) => {
              e.preventDefault();
              const deskList = deskSpaces.filter((desk: DeskSpace) => desk.type === "DESK" && desk.deskGroupId === row.original.deskGroupId);
              if (row.original.type === "GROUP" && deskList.length > 0) {
                setBaseModal({
                  isOpen: true,
                  title: "해당 좌석 그룹에 좌석이 있습니다.",
                  btnRightTitle: "확인",
                  onClick: () => setBaseModal({ isOpen: false }),
                });
              } else {
                setConfirmModal({
                  isOpen: true,
                  title: "삭제 하시겠습니까?",
                  type: "FACILITY_DELETE",
                  payload: row.original,
                });
              }
            }}
          ></button>
        ),
      },
    ],
    [deskSpaces],
  );

  // 새로 추가된 facility 찾기
  const getNewFacility = async (buildingId: number) => {
    const { data: newBuildingData } = await getBuilding({ buildingId });
    const newDeskGroups = newBuildingData.data.content.building.buildingCommonFacility?.deskSpace?.deskGroupList || [];
    const prevDeskGroups = building.buildingCommonFacility?.deskSpace?.deskGroupList || [];

    const newDeskLists = newDeskGroups.flatMap((group) => group.deskList || []);
    const prevDeskLists = prevDeskGroups.flatMap((group) => group.deskList || []);

    // 새로 추가된 desk 찾기
    const addedFacility = _.differenceBy(newDeskLists, prevDeskLists, "id");

    if (addedFacility.length > 0) {
      setNewFacility(addedFacility[0] as DeskSpace);
      setConfirmModal({
        isOpen: true,
        title: "저장이 완료되었습니다",
        message: "이어서 출입그룹 설정을 할 수 있습니다.\n 이동하시겠습니까?",
        type: "MOVED_ACCESS_GROUP",
      });
    }
  };

  // 좌석 전체 삭제
  const handleRemoveDesk = async (desk?: DeskSpace) => {
    const { status } = desk ? await onRemoveDesk(desk) : await onRemoveDesk();
    if (status >= 200 && status <= 299) {
      fetchBuilding(building.id);
      setConfirmModal({ isOpen: false });
    }
  };

  const onSaveDeskGroup = async (passData: BuildingCommonFacilityDeskGroupModel) => {
    const deskGroupRequest: BuildingCommonFacilityDeskGroupModel = {
      ...(passData.id && { id: passData?.id }),
      buildingId: building.id,
      isGround: Number(passData.floorNum) > 0,
      floorNum: Math.abs(Number(passData.floorNum)),
      groupName: passData.groupName,
      deskList: passData.deskList,
      description: passData.description,
      mediaList: passData.mediaList,
    };

    const { status } = await onUpdateDeskGroup(deskGroupRequest);
    if (status >= 200 && status <= 299) {
      if (!passData.id) {
        const { data: newBuildingData } = await getBuilding({ buildingId: building.id });
        const newDeskGroups = newBuildingData.data.content.building.buildingCommonFacility?.deskSpace?.deskGroupList || [];
        const prevDeskGroups = building.buildingCommonFacility?.deskSpace?.deskGroupList || [];

        const addedFacility = _.differenceBy(newDeskGroups, prevDeskGroups, "id");
        if (addedFacility.length > 0 && passData.groupName) {
          setNewFacility({ buildingCommonFacilityDeskGroupId: addedFacility[0].id });
        }
      } else {
        setConfirmModal({ isOpen: false });
      }
      setConfirmModal({ isOpen: false });
      setDeskGroupModal({ isOpen: false });
      fetchBuilding(building.id);
    }
  };

  // 좌석 저장
  const onSaveDesk = async (passData: CommonFacilityModel) => {
    const deskRequest = {
      ...(passData.id && { id: passData?.id }),
      buildingId: building.id,
      commonFacilityType: "DESK",
      facilityName: passData.facilityName,
      locationCode: passData.locationCode,
      buildingCommonFacilityDeskGroupId: passData.buildingCommonFacilityDeskGroupId,
    };
    const { status } = await onUpdateDesk(deskRequest as CommonFacilityModel);
    if (status >= 200 && status <= 299) {
      setConfirmModal({ isOpen: false });

      if (isAccessFeature && !passData.id) {
        await getNewFacility(building.id);
      } else {
        setDeskModal({ isOpen: false });
      }
      fetchBuilding(building.id);
      passData.id && setDeskModal({ isOpen: false });
    }
  };

  const handleConfirm = async (passData?: BuildingCommonFacilityDeskGroupModel | CommonFacilityModel, type?: "GROUP" | "DESK") => {
    if (!passData) {
      setConfirmModal({ isOpen: true, title: "정보가 초기화되어 저장됩니다.", type: "FACILITY_DELETE" });
    } else {
      let requestData;
      if (type === "GROUP") {
        requestData = passData as BuildingCommonFacilityDeskGroupModel;
      } else {
        requestData = passData as CommonFacilityModel;
      }
      setConfirmModal({
        isOpen: true,
        title: "저장하시겠습니까?",
        payload: { passData: requestData, type },
      });
    }
  };

  // 저장 모달 확인 클릭시 실행
  const executeConfirm = async () => {
    // 삭제
    if (confirmModal.type === "FACILITY_DELETE") {
      const desk = confirmModal.payload as DeskSpace;
      desk ? handleRemoveDesk(desk) : handleRemoveDesk();
    }
    // 출입그룹 이동
    else if (confirmModal.type === "MOVED_ACCESS_GROUP") {
      setActiveTab(facilitySubTabs[1]);
      setConfirmModal({ isOpen: false });
    }
    // 저장
    else {
      const { passData, type } = confirmModal.payload as { passData: CommonFacilityModel; type: "GROUP" | "DESK" };
      if (type === "GROUP") {
        onSaveDeskGroup(passData);
      } else {
        onSaveDesk(passData as DeskSpace);
      }
    }
  };

  return (
    <section className="">
      <div className="contents-container__sub-title">
        <div className="flex-center">
          <h2 className="minmax80">설정</h2>
          <BaseToggle
            checked={isUsedFacility}
            onChange={(checked: boolean) => {
              if (!isAuthority("w")) {
                return fetchAuthorityReadonly(true);
              }

              if (deskSpaces.length > 0 && !checked) {
                handleConfirm();
              } else {
                setIsUsedFacility(checked);
              }
            }}
          />
        </div>
      </div>
      {isUsedFacility && (
        <>
          <div className="flex-center-between mb30" style={{ minHeight: 40 }}>
            <h2>좌석 목록</h2>
            {isAuthority("w") && (
              <BaseButton
                title="좌석 등록"
                onClick={() => {
                  handleResetStates();
                  setDeskModal({
                    isOpen: true,
                    type: "DESK",
                  });
                }}
              />
            )}
          </div>

          <ViewDataTable
            columns={columns.filter((column) => {
              if (!isAuthority("w") && column.accessor === "edit") return false;
              if (!isAuthority("d") && column.accessor === "delete") return false;
              return true;
            })}
            data={deskSpaces}
          />
        </>
      )}
      {confirmModal.isOpen && (
        <BaseModal
          isOpen={true}
          title={confirmModal.title}
          onClick={() => {
            executeConfirm();
          }}
          // onClose={() => setConfirmModal({ isOpen: false })}
          onClose={() => {
            if (confirmModal.type === "MOVED_ACCESS_GROUP") {
              handleResetStates();
              setDeskModal({ isOpen: false });
            }
            setConfirmModal({ isOpen: false });
          }}
          btnLeftTitle="취소"
          btnRightTitle={confirmModal.type === "MOVED_ACCESS_GROUP" ? "확인" : "저장"}
        >
          {confirmModal.message && <p>{confirmModal.message}</p>}
        </BaseModal>
      )}
      <>
        {deskModal.isOpen && (
          <DeskFormModal
            onClose={() => setDeskModal({ isOpen: false })}
            handleConfirm={handleConfirm}
            facility={
              newFacility && newFacility.id
                ? {
                    type: "DESK",
                    deskId: newFacility.id, //
                    deskGroupId: Number(newFacility.buildingCommonFacilityDeskGroupId || newFacility.id),
                    deskName: newFacility.facilityName,
                    locationCode: newFacility.locationCode,
                  }
                : (deskModal.payload as DeskSpace)
            }
            deskGroupId={newFacility?.buildingCommonFacilityDeskGroupId || undefined}
            readonly={deskModal.readonly}
            building={building}
            deskSpaces={deskSpaces}
            openDeskGroupFormModal={(data) => {
              setDeskGroupModal({
                isOpen: true,
                type: "GROUP",
                payload: data,
              });
            }}
            isAccessFeature={isAccessFeature}
            activeTab={activeTab}
            onChangeTab={setActiveTab}
          />
        )}
        {deskGroupModal.isOpen && (
          <DeskGroupFormModal
            onClose={() => setDeskGroupModal({ isOpen: false })}
            handleConfirm={handleConfirm}
            facility={deskGroupModal.payload as DeskSpace}
            readonly={deskGroupModal.readonly}
            building={building}
            deskSpaces={deskSpaces}
          />
        )}
      </>
    </section>
  );
};

export default DeskSection;
