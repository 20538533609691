import { RefObject, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { getBuildingsAsync } from "src/api/building/building-api";
import useApiOperation from "src/api/hooks/api-operation";
import { BaseButton, BaseCheckbox, BaseInput, BasePagination, BaseRadio } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import TableSelectModal from "src/components/TableSelectModal";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import useModalObserver from "src/recoil/modal-observer/hooks";

type Building = {
  id?: number;
  buildingId: number;
  buildingName: string;
};

interface Props {
  onCanceled: () => void;
  onAdded: (selected: Building[]) => void;
  defaultChecked: Building[];
  setContractModal: (value: Boolean) => void;
}

interface CellProps extends Cell<Building> {
  checked: boolean;
}

const TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 80,
    Cell: (props: CellProps) => {
      const buildingId = props.row.original.id;
      let disabled = false;
      return (
        <div data-data-id={buildingId} data-checked={props.value} className="checkbox" data-disabled={disabled}>
          <BaseCheckbox id={""} name={""} checked={props.value} disabled={disabled} />
        </div>
      );
    },
  },
  {
    Header: "건물 id",
    accessor: "id",
    width: 120,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 290,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;

      return <div className="w-100 text-left">{props.value}</div>;
    },
  },
  {
    Header: "주소",
    accessor: "address",
    width: 450,
    Cell: (props: CellProps) => <div className="w-100">{props.value ? props.value : "-"}</div>,
  },
];

function BuildingSelectModal({ onCanceled, onAdded, defaultChecked, setContractModal }: Props) {
  const [page, setPage] = useState({ current: 0, total: 0, totalElements: 0 });
  const [buildingList, setBuildingList] = useState<any[]>([]);
  const [selectedBuildingList, setSelectedBuildingList] = useState<Building[]>(defaultChecked);
  const { handleSubmit, getValues, control } = useForm<{ keyword: string }>();
  const [size, setSize] = useState(20);

  const searchFormRef = useRef<HTMLFormElement>(null);

  const buildingListTableData = buildingList.map((building) => {
    const selectedIdList = selectedBuildingList?.map((building) => building.buildingId);

    return {
      checked: selectedIdList.includes(building.id),
      ...building,
    };
  });

  const { executeAsync: getBuildingList } = useApiOperation(getBuildingsAsync);
  const search = ({ keyword, page, size }: { page: number; size: number; keyword?: string }) => {
    setSize(size);
    fetchBuildingList({ page, size, keyword });
  };

  const onSubmit = ({ keyword }: { keyword: string }) => search({ page: 0, size, keyword });
  const goPage = (nextPage: number) => search({ page: nextPage, size, keyword: getValues("keyword") });

  const fetchBuildingList = async ({ keyword, page, size }: { page: number; size?: number; keyword?: string }) => {
    const result = await getBuildingList({
      page,
      keyword,
      size,
      sort: {
        orders: [
          {
            property: "id",
            direction: "DESC",
          },
        ],
      },
    });
    setBuildingList(result.data.data.content);

    setPage({
      current: result.data.meta.pageMeta?.pageRequest.page || 0,
      total: result.data.meta.pageMeta?.totalPages || 0,
      totalElements: result.data.meta.pageMeta?.totalElements || 0,
    });
  };
  const select = (id: string) => {
    const selectedBuilding = buildingList.find((building) => String(building.id) === id);

    setSelectedBuildingList([
      ...selectedBuildingList,
      // 멀티셀렉일때 주석해제
      {
        buildingId: selectedBuilding?.id || 0,
        buildingName: selectedBuilding?.buildingName || "",
      },
    ]);
  };
  const unSelect = (buildingId: string) => {
    const filteredBuildingList = selectedBuildingList.filter((building) => String(building.buildingId) !== buildingId);
    setSelectedBuildingList(filteredBuildingList);
  };

  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };

  useEffect(() => {
    fetchBuildingList({
      page: 0,
      size,
      keyword: "",
    });
  }, []);
  const { generateId, subscribe, unsubscribe } = useModalObserver();
  const [modalId] = useState(() => generateId());
  const [firstButtonRef, setFirstButtonRef] = useState<RefObject<HTMLButtonElement>>();

  useEffect(() => {
    subscribe(modalId, {
      Escape: onCanceled,
    });

    return () => {
      unsubscribe(modalId);
    };
  }, [firstButtonRef]);

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const dataId = checkbox.dataset.dataId;
    const disabled = Boolean(String(checkbox.dataset.disabled) === "true");
    const checked = Boolean(checkbox.dataset.checked === "true");
    if (disabled !== undefined && !disabled) {
      if (!checked) {
        select(dataId!);
      } else {
        unSelect(dataId!);
      }
    }
  };

  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    const checkbox = target.closest(".checkbox") as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };

  return (
    <BaseAbstractModal isOpen={true} className="select-buildings-contracts-modal">
      <section className="base-abstract-modal__title flex-center-between">
        <h1>대상 선택</h1>
      </section>

      <section className="base-abstract-modal__contents px30">
        <section className="base-abstract-modal__contents-subtitle mt0">
          <h2 className="required ml10">건물</h2>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} ref={searchFormRef} className="flex-center-center">
              <Controller
                name="keyword"
                control={control}
                render={({ field }) => (
                  <BaseInput
                    placeholder="검색어를 입력하세요"
                    value={field.value}
                    onChange={field.onChange}
                    onSearchClick={handleSearchClick}
                    onKeyUp={handleSearchClick}
                    clearable
                  />
                )}
              />
            </form>
          </div>
        </section>
        <div className={"table-select-modal__contents select-buildings-contracts-table"} onClick={handleModalContentsClick}>
          <ViewDataTable columns={TABLE_COLUMNS} data={buildingListTableData} />
        </div>
        <div className="page">
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} currentSize={size} totalElements={page.totalElements} />
        </div>
        <section className="base-abstract-modal__contents-subtitle mt0 ">
          <div className="py20">
            <h2>자세한 설정</h2>
            <ul className="base-list mt20">
              <li className="font14">선택한 건물의 신청/계약 항목을 자세히 설정할 수 있습니다.</li>
            </ul>
          </div>
        </section>
        <div className="contract-area">
          <p className="label">신청/계약</p>
          <BaseButton
            type="button"
            className="color-white size-medium ml100"
            disabled={selectedBuildingList.length === 0}
            title="선택"
            onClick={() => {
              setContractModal(true);
            }}
          />
        </div>
      </section>

      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title={"취소"} className="color-white" onClick={onCanceled} />
        <BaseButton title={"선택"} onClick={() => onAdded(selectedBuildingList)} setRef={setFirstButtonRef} />
      </section>
    </BaseAbstractModal>
  );
}
export default BuildingSelectModal;
