import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useRef, useState } from "react";
import { getQuestionListAsync } from "src/api/question/qeustion-api";
import { BaseButton } from "src/components";
import LoadingSpinner from "src/components/LoadingSpinner";
import RangeDatepickerInline from "src/components/RangeDatepickerInline";
import { useModal } from "src/recoil/modalState/hook";
import { axiosInstanceNoLoading, createExcelFile } from "src/utils";
import AIReportModal from "./AIreportModal";
import { AIAnalysisData, useAIAnalysis } from "./useAIAnalysis";
import useCsHooks from "../../useCsHooks";
import QuestionColmns from "../column/QuestionColmns";
import * as Sentry from "@sentry/react";
const DateSelectModal = () => {
  const { setAbstractModalZ1, setBaseModal, setAbstractModalZ2 } = useModal();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const abortControllerRef = useRef<AbortController>();
  const isValidDateRange = dateRange[0] && dateRange[1];
  const { startAnalysis } = useAIAnalysis();
  const { makeExcelData } = useCsHooks(true);
  const [file, setFile] = useState<File | null>(null);

  const {
    data: csListData,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["aiDataAnalysis", dateRange[0]?.toISOString(), dateRange[1]?.toISOString()],
    queryFn: () =>
      getQuestionListAsync(axiosInstanceNoLoading, {
        startDate: dateRange[0]?.toISOString(),
        endDate: dateRange[1]?.toISOString(),
        page: 0,
        size: 999999,
      }).then((res) => res.data),
    enabled: !!isValidDateRange,
    placeholderData: (previousData) => previousData, //이전 데이터 유지
  });

  const { mutate: handleStartAnalysis, isPending: isAnalyzing } = useMutation({
    mutationFn: () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      const promise = new Promise<AIAnalysisData[]>(async (resolve, reject) => {
        const startTime = Date.now(); // 시작 시간 기록
        console.log("🔍 Data processing started");

        abortControllerRef.current?.signal.addEventListener("abort", () => {
          reject(new Error("Analysis cancelled"));
          return;
        });

        try {
          let sendFile = file;

          if (!sendFile) {
            const csDataList = await makeExcelData({
              startDate: dateRange[0]?.toISOString(),
              endDate: dateRange[1]?.toISOString(),
              sort: {
                orders: [{ property: "id", direction: "DESC" }],
              },
            });

            if (!csDataList) {
              throw new Error("undefined excelData");
            }

            const fileName = `Ctrl.room_CS_${moment().format("YYYY-MM-DD HH.mm")}`;
            sendFile = await createExcelFile({
              data: csDataList,
              fileName,
              columns: QuestionColmns,
            });
          }

          const analysisResult = await startAnalysis([dateRange[0]!, dateRange[1]!], sendFile, abortControllerRef.current?.signal);

          if (analysisResult.success) {
            resolve(analysisResult.data!);
          } else {
            reject(analysisResult.error);
          }
        } catch (error) {
          reject(error);
        } finally {
          const endTime = Date.now(); // 종료 시간 기록
          const duration = (endTime - startTime) / 1000; // 초 단위로 변환
          console.log(`⏱️ total processing completed in ${duration} seconds`);
          //센트리 전송

          Sentry.captureMessage(`CS AI total processing completed in ${duration} seconds`, {
            tags: {
              keyword: "CS_AI",
            },
          });
        }
      });

      return promise;
    },
    onSuccess: (AIAnalysisDatas) => {
      console.log("AIAnalysisDatas", AIAnalysisDatas);
      setAbstractModalZ2({
        isOpen: true,
        children: <AIReportModal AIAnalysisDatas={AIAnalysisDatas} dateRange={dateRange} totalElements={csListData?.meta?.pageMeta?.totalElements} />,
        size: "xlarge",
      });
    },
    onError: (error) => {
      if (error.message !== "Analysis cancelled") {
        Sentry.captureException(error, {
          tags: {
            keyword: "CS_AI",
          },
        });

        setBaseModal({
          isOpen: true,
          title: "AI 분석에 실패했습니다",
          children: "불편을 드려 죄송합니다. 잠시 후 다시 시도해 주세요.",
          btnLeftTitle: "닫기",
          btnRightTitle: "기간 선택",
          onClose: () => {
            setBaseModal({ isOpen: false });
            setAbstractModalZ1({ isOpen: false });
            setAbstractModalZ2({ isOpen: false });
          },
          onClick: () => {
            setBaseModal({ isOpen: false });
            setAbstractModalZ2({ isOpen: false });
          },
        });
      }
    },
  });

  const cancelAnalysis = () => {
    if (isAnalyzing && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const loadingComponent = (
    <>
      <section className="base-abstract-modal__title text-center">
        <h1>AI 분석 진행 중</h1>
      </section>

      <section className="base-abstract-modal__contents px30">
        <div className="ai-data-analysis-contents-wrap flex-center-center">
          <div className="ai-data-analysis-contents-wrap__result text-center">
            <LoadingSpinner size={86} />
            <p className="mt10">
              고객 문의 내용을 탐험하는 중입니다
              <br />
              거의 다 왔어요
              <br />
              분석 기간 : {moment(dateRange[0]).format("YYYY.MM.DD")} - {moment(dateRange[1]).format("YYYY.MM.DD")}
            </p>
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__btn-wrap justify-center">
        <BaseButton
          title="취소"
          className="color-white"
          onClick={() => {
            cancelAnalysis();
            setAbstractModalZ1({ isOpen: false });
          }}
        />
        <BaseButton
          title="돌아가기"
          className="color-white"
          onClick={() => {
            cancelAnalysis();
          }}
        />
      </section>
    </>
  );

  const dateSelectComponent = (
    <>
      <section className="base-abstract-modal__title text-center">
        <h1>분석기간 선택</h1>
        {process.env.REACT_APP_ENVIRONMENT === "dev" && (
          <div>
            <h3 className="">개발환경 전용 엑셀파일 직접 업로드 분석기간만 임의로 선택해주세요</h3>
            <input
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]);
                }
              }}
            />
          </div>
        )}
      </section>

      <section className="base-abstract-modal__contents px30">
        <div className="ai-data-analysis-contents-wrap">
          <RangeDatepickerInline placeholder="캘린더에서 기간을 선택해 주세요" dateRange={dateRange} onChange={(range) => setDateRange(range)} />

          {isFetching && (
            <div className="ai-data-analysis-contents-wrap__loading">
              <LoadingSpinner />
              <p>데이터를 집계중입니다</p>
            </div>
          )}

          {!isFetching &&
            (csListData && csListData.meta?.pageMeta?.totalElements && csListData.meta.pageMeta.totalElements > 0 ? (
              <div className="ai-data-analysis-contents-wrap__result">
                <div className="text-center mb-2">
                  <h2>집계 완료!</h2>
                  <p>
                    분석 가능한 <span className="font-bold text-blue-500">{csListData.meta.pageMeta?.totalElements}</span>개의 데이터를 확인했습니다.
                  </p>
                </div>
              </div>
            ) : (
              <div className="ai-data-analysis-contents-wrap__result text-center">
                <p>선택한 기간에 등록된 데이터가 없습니다.</p>
                <p>다시 선택해 주세요.</p>
              </div>
            ))}
        </div>
      </section>

      <section className="base-abstract-modal__btn-wrap justify-center">
        <BaseButton title="취소" className="color-white" onClick={() => setAbstractModalZ1({ isOpen: false })} />
        <BaseButton
          title="AI 분석 시작"
          className="color-blue"
          disabled={!isValidDateRange || isFetching || csListData?.data.content.length === 0}
          onClick={() => handleStartAnalysis()}
        />
      </section>
    </>
  );

  return <>{isAnalyzing ? loadingComponent : dateSelectComponent}</>;
};

export default DateSelectModal;
