import { useRef, useState } from "react";
import Select, { components, MenuListProps, OptionProps } from "react-select";
import { BaseButton } from "./BaseButton";
export interface BaseSelectState {
  placeholder?: string;
  stateOptions: Array<{
    value: string | number;
    label: string;
    isDisabled?: boolean;
  }>;
  value?: string | number | null;
  name?: string;
  setStateValue: Function;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  errorText?: string;
  isDisabled?: boolean;
  menuPosition?: "fixed" | "absolute";
  menuPortalTarget?: HTMLElement | undefined;
  menuPlacement?: "auto";
  maxMenuHeight?: number;
  onButtonClick?: () => void;
  buttonText?: string;
  noOptionsMessage?: string;
  onSettingClick?: (option: string) => void; // 설정 버튼 클릭 핸들러 추가
}

// selectBox 맨 하단에 버튼 추가
const ButtonInSelect = (props: MenuListProps<any, false>) => {
  // const { onButtonClick, buttonText } = props.selectProps as any;
  const { onButtonClick, buttonText } = props.selectProps as any;
  return (
    <div className="select-box-container">
      <div className="select-box-container__list">
        <components.MenuList {...props}>{props.children}</components.MenuList>
      </div>
      {onButtonClick && (
        <div className="pt10 mt4" style={{ borderTop: "1px solid #e0e0e0" }}>
          <BaseButton onClick={onButtonClick} title={buttonText || ""} />
        </div>
      )}
    </div>
  );
};

// Option 컴포넌트 > 톱니바퀴 추가
const CustomOption = (props: OptionProps<any>) => {
  const { onSettingClick } = props.selectProps as any;

  return (
    <components.Option {...props}>
      <div className="flex-center-between w-100">
        <div className="minmax160">{props.label}</div>
        {onSettingClick && (
          <span
            className="d-block base-setting-btn"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // select 이벤트 방지
              onSettingClick(props.data.value);
            }}
          ></span>
        )}
      </div>
    </components.Option>
  );
};

export const BaseSelectWithButton = (props: BaseSelectState) => {
  let selectedValue = null; // null 로 초기화 해야 선택안함이 정상적으로 초기화됨
  const maxMenuHeight = props.maxMenuHeight ?? 300;
  const stateOptions = props.stateOptions;
  if (props.value) {
    selectedValue = props.stateOptions.find((option) => String(option.value) === String(props.value));
  }

  const selectRef = useRef<HTMLDivElement | null>(null);
  const [menuPlacement, setMenuPlacement] = useState<"top" | "bottom">("bottom");

  const handleMenuOpen = () => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();

      const BelowHeight = window.innerHeight - rect.bottom;

      const itemHeight = 36;
      const itemCount = stateOptions?.length;
      const itemListHeight = itemHeight * itemCount;

      //메뉴의 최대 높이보다 아이템 토탈 높이가 작으면 리턴, 작으면 itemListHeight 리턴
      const menuHeight = maxMenuHeight > itemListHeight ? itemListHeight : maxMenuHeight;

      //셀랙트 위치 기준 바텀 여유 공간이 메뉴의 높이보다 작으면 위로
      //패딩 + 마진 값 20px 추가
      if (BelowHeight < menuHeight + 20) {
        setMenuPlacement("top");
      } else {
        setMenuPlacement("bottom");
      }
    }
  };

  const customComponents = {
    ...(props.onButtonClick ? { MenuList: ButtonInSelect } : {}),
    Option: CustomOption,
  };

  return (
    <div ref={selectRef as any} className={`w-100 base-select setting-button ${props.className ? props.className : ""}`}>
      <Select
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props?.placeholder || "선택해주세요"}
        isSearchable={false}
        value={selectedValue ?? null}
        className={`react-select-container`}
        classNames={{
          option: (state) => {
            if (state.isSelected) {
              return props.onSettingClick ? "react-select__option--is-selected-with-btn " : "react-select__option--is-selected";
            }
            return "react-select__option-has-button";
          },
        }}
        classNamePrefix="react-select"
        onChange={(e) => {
          e && props.setStateValue(e.value);
        }}
        noOptionsMessage={() => props.noOptionsMessage || "선택 가능한 옵션이 없습니다."}
        options={stateOptions}
        menuPortalTarget={props.menuPortalTarget}
        isDisabled={props.isDisabled}
        menuPosition={props.menuPlacement === "auto" ? "fixed" : props.menuPosition || "absolute"}
        menuPlacement={menuPlacement as any}
        onMenuOpen={handleMenuOpen}
        maxMenuHeight={props.maxMenuHeight}
        components={customComponents}
        {...(props.onButtonClick && {
          onButtonClick: props.onButtonClick,
          buttonText: props.buttonText,
        })}
        {...(props.onSettingClick && {
          onSettingClick: props.onSettingClick,
        })}
      />
      {props.errorText && <p className="validation-text">{props.errorText}</p>}
    </div>
  );
};
