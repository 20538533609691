import { AxiosInstance, AxiosResponse } from "axios";
import { GetMessagesParams, Messages, PatchMessagesParams } from "./messages-type";
import { ApiResponse } from "../public-types";
import { ApiPath } from "..";
import qs from "qs";

/* 
  REA01. 알림발송 목록
*/
export async function getScheduledMessagesAsync(
  axios: AxiosInstance,
  params?: GetMessagesParams,
): Promise<AxiosResponse<ApiResponse<{ content: Messages[] }>>> {
  return await axios.get<ApiResponse<{ content: Messages[] }>>(ApiPath.messages.get, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/* 
  REA02. 알림 예약 취소
*/
export async function cancelScheduledMessagesAsync(
  axios: AxiosInstance,
  params?: PatchMessagesParams,
): Promise<AxiosResponse<ApiResponse<{ content: Messages[] }>>> {
  if (!params) {
    throw Error("not found params");
  }
  return await axios.patch<ApiResponse<{ content: Messages[] }>>(ApiPath.messages.cancel, {
    ids: [params.ids],
  });
}
