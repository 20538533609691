import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CommonFacilityType } from "src/api/building/building-types";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { editRservationAsync, getReservationListAsync, postRservationAsync } from "src/api/reservation/reservation-api";
import { ReservationListModel, ReservationRequest } from "src/api/reservation/reservation-types";
import { BaseButton, BaseDatePicker, BaseInput, BaseTextarea } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import GoToListButton from "src/components/GoToListButton";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { useModal } from "src/recoil/modalState/hook";
import { YmdFormat } from "src/utils";
import { ExternalServiceType, ReservationState } from "../../reservation-types";
import RvContractModal from "./RvContractModal";
import RvFacilityListModal from "./RvFacilityListModal";
import RvMembersModal from "./RvMembersModal";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "src/hooks/useQueryParams";
import { FullDatePicker } from "src/components/FullDatePicker";

export type RvFormData = ReservationRequest & {
  contractName?: string; // 계약명
  facilityName?: string; // 공용공간명
  organizerMemberEmail?: string; //주최자 이메일
  contractApplyNumber?: string; //이용신청 번호
  facilityType?: CommonFacilityType;
  buildingId?: string;
};

type Props = {
  // reservationDetail?: RvFormData;
  reservationDetail?: ReservationListModel;
};

const BasicInfoForm = ({ reservationDetail }: Props) => {
  const { queryParams } = useQueryParams();

  const navigate = useNavigate();

  // 수정버튼 노출 > 상태가 접수/점유, 채널이 ctrl.room 일 경우
  const isNotEditable = useMemo(() => {
    if (!queryParams.id) return false;

    const checkEditable = //
      (reservationDetail?.status.code !== ReservationState.ACCEPTED && reservationDetail?.status.code !== ReservationState.ACKNOWLEDGED) ||
      reservationDetail?.inboundChannel !== ExternalServiceType.CTRL_ROOM;

    return checkEditable;
  }, [reservationDetail, queryParams.id]);

  const reservation: RvFormData = useMemo(() => {
    if (!reservationDetail) return {} as RvFormData;
    return {
      reservationId: reservationDetail.id,
      summary: reservationDetail.summary || "",
      description: reservationDetail.description || "",
      contractApplyNumber: reservationDetail.contract.applyNumber,
      start: reservationDetail.start || "",
      end: reservationDetail.end || "",
      contractId: reservationDetail.contract.id || "",
      facilityId: reservationDetail.facility.id || "",
      facilityName: reservationDetail.facility.name || "",
      facilityType: reservationDetail.facility.type,
      organizerMemberNo: reservationDetail.organizer.memberNo || "",
      organizerMemberEmail: reservationDetail.organizer.userEmail || "",
      buildingId: String(reservationDetail.facility.building.id),
      attendees:
        reservationDetail.attendees.map(({ memberNo, userEmail, displayName }) =>
          memberNo ? { member: { memberNo, memberEmail: userEmail } } : { external: { userEmail, displayName } },
        ) || [],
    };
  }, [reservationDetail]);

  const { setBaseModal, setAbstractModalZ1 } = useModal();

  const { executeAsync: postReservation } = useApiOperation(postRservationAsync);
  const { executeAsync: editReservation } = useApiOperation(editRservationAsync);

  const { executeAsync: getContractList } = useApiOperation(getContractApply);

  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync, { noLoading: true });

  const [hasConflict, setHasConflict] = useState(false);

  const checkReservationConflict = async () => {
    const start = watch("start");
    const end = watch("end");
    const facilityId = String(watch("facilityId") || "");

    // 세 값이 모두 있을 때만 체크
    if (!start || !end || !facilityId) {
      setHasConflict(false);
      return;
    }

    const { data, status } = await getReservationList({
      statusCode: "ACKNOWLEDGED",
      facilityId,
      start,
      end,
    });

    if (status >= 200 && status <= 299) {
      setHasConflict(data.data.content.length > 0);
    }
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<RvFormData>({
    defaultValues: {
      summary: "",
      start: "",
      end: "",
    },
  });

  const onSaveReservation = async (passData: ReservationRequest) => {
    let request = !passData.reservationId ? postReservation : editReservation;
    const newData = {
      summary: passData.summary,
      description: passData.description, //
      start: passData.start,
      end: passData.end,
      contractId: passData.contractId,
      facilityId: passData.facilityId,
      organizerMemberNo: passData.organizerMemberNo,
      attendees: passData.attendees,
    };
    const { data } = await request(passData.reservationId ? { ...newData, reservationId: passData.reservationId } : newData);

    if (data.data) navigate(PagePath.reservation.detail.replace(":id", data.data.reservation.id));
    setBaseModal({ isOpen: false });
  };

  const onSubmit = async (data: ReservationRequest, e?: any) => {
    e.preventDefault();
    setBaseModal({
      isOpen: true, //
      title: "저장하시겠습니까?",
      btnLeftTitle: "취소",
      btnRightTitle: "확인",
      onClick: () => {
        onSaveReservation(data);
      },
    });
  };

  useEffect(() => {
    (async () => {
      if (!reservation?.reservationId) return;

      // reservation 객체의 모든 키를 가져와서 순회
      Object.entries(reservation).forEach(([key, value]) => {
        // RvFormData의 키인지 타입 체크
        if (key in reservation) {
          setValue(key as keyof RvFormData, value);
        }
      });

      // contractName은 API 호출 결과에 따라 별도 설정
      if (reservation.contractId) {
        const { data: contract } = await getContractList({
          contractIds: String(reservation.contractId),
        });

        if (contract.data) {
          const contractContent = contract.data.content[0];
          setValue("contractName", contractContent?.contractName || contractContent?.spaceProductName || "-");
        }
      }
    })();
  }, [reservation?.reservationId]);

  useEffect(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("summary", { required: requiredMessage });
    register("start", { required: requiredMessage });
    register("end", { required: requiredMessage });
    register("contractId", { required: requiredMessage });
    register("facilityId", { required: requiredMessage });
    register("organizerMemberNo", { required: requiredMessage });
  }, [register]);

  const onError = () => {
    console.log("errors", errors);
  };

  // 시작일시보다 종료일시가 이전인지 확인
  const isSameOrAfterDate = (watch("start") && watch("end") && moment(watch("start")).isSameOrAfter(moment(watch("end")), "minutes")) || false;

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="contents-container__scroll reservation-form">
        <div className="contents-container__wrap">
          <article className="contents-container__wrap-article">
            <section className="contents-container__sub-title">
              <h2>예약 정보</h2>
            </section>

            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">신청/계약</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="contractId"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <>
                          <div className="flex-center">
                            <BaseButton
                              title="선택" //
                              className="color-white mr10"
                              disabled={!!reservation?.reservationId}
                              onClick={() =>
                                setAbstractModalZ1({
                                  isOpen: true,
                                  children: (
                                    <RvContractModal
                                      onClick={(contract) => {
                                        const spaceProductName = contract.spaceProductName;
                                        const contractName = contract.contractName;

                                        onChange(contract.contractId);
                                        setValue("contractName", contractName ? contractName : spaceProductName);
                                        setValue("contractApplyNumber", contract.contractApplyNumber);

                                        if (value && value !== contract.contractId) {
                                          setValue("facilityId", "");
                                          setValue("facilityName", "");
                                          setValue("organizerMemberEmail", "");
                                          setValue("organizerMemberNo", "");
                                          setValue("attendees", []);
                                        }
                                      }}
                                      onClose={() => setAbstractModalZ1({ isOpen: false })}
                                    />
                                  ),
                                })
                              }
                            />
                            {value && (
                              <BaseNewTabLink
                                value={watch("contractName") || ""}
                                path={PagePath.contract.detail.replace(":id", String(watch("contractId")))}
                              />
                            )}
                          </div>
                          {error?.message && <p className="validation-text">{error?.message}</p>}
                        </>
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">공용공간</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="facilityId"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <>
                          <div className="flex-center">
                            <BaseButton
                              title="선택"
                              disabled={!watch("contractId") || !!reservation?.reservationId}
                              className="color-white"
                              onClick={() =>
                                setAbstractModalZ1({
                                  isOpen: true,
                                  children: (
                                    <RvFacilityListModal
                                      onClick={(facility) => {
                                        onChange(facility.id);
                                        setValue("facilityName", facility.facilityName);
                                        setValue("facilityType", facility.commonFacilityType);
                                        checkReservationConflict();
                                      }}
                                      onClose={() => setAbstractModalZ1({ isOpen: false })}
                                      contractId={String(watch("contractId"))}
                                    />
                                  ),
                                })
                              }
                            />
                            <div className="ml10">
                              {value && reservation?.reservationId ? (
                                <BaseNewTabLink
                                  value={watch("facilityName") || ""}
                                  path={PagePath.building.detail.replace(":id", String(reservation.buildingId)) + "?tab=facility"}
                                />
                              ) : (
                                <span>{watch("facilityName")}</span>
                              )}
                            </div>
                          </div>
                          {error?.message && <p className="validation-text">{error?.message}</p>}
                        </>
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>

            <div className="flex-center">
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">예약 시작일시</p>
                </div>
                <div className="contents-container__grid-contents flex-center">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="start"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <>
                          <FullDatePicker
                            selectedDate={value ? moment(value).toDate() : null}
                            minDate={moment().toDate()}
                            minTime={
                              moment(value || new Date()).isSame(moment(), "day")
                                ? moment()
                                    .startOf("hour")
                                    .minutes(Math.floor(moment().minutes() / 30) * 30)
                                    .toDate()
                                : moment().startOf("day").toDate()
                            }
                            maxTime={moment().endOf("day").toDate()}
                            setDate={(selectedDate: Date) => {
                              const date = moment(selectedDate).format(YmdFormat.WITH_TIME_ZONE);
                              onChange(date);
                              checkReservationConflict();
                              if (moment(watch("end")).isSameOrBefore(date)) {
                                setValue("end", "");
                              }
                            }}
                            className="mr10 minmax180"
                            disabled={!!reservation?.reservationId}
                            errorText={error?.message}
                          />
                        </>
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">예약 종료일시</p>
                </div>
                <div className="contents-container__grid-contents ">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="end"
                      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                        <FullDatePicker
                          setDate={(selectedDate: Date) => {
                            const startDate = moment(watch("start"));
                            let endDate = moment(selectedDate);

                            // 선택된 종료일이 시작일보다 이전이면 시작일의 날짜로 설정
                            if (endDate.isBefore(startDate, "day")) {
                              endDate = moment(selectedDate).year(startDate.year()).month(startDate.month()).date(startDate.date());
                            }

                            // 시작일과 종료일이 같은 날짜이고, value가 없는 경우(처음 날짜 선택시)에만 시작시간 + 30분으로 설정
                            if (endDate.isSame(startDate, "day") && !value) {
                              const minTime = moment(startDate).add(30, "minutes");
                              endDate = moment(selectedDate)
                                .year(startDate.year())
                                .month(startDate.month())
                                .date(startDate.date())
                                .hour(minTime.hour())
                                .minute(minTime.minute());
                            }

                            const date = endDate.format(YmdFormat.WITH_TIME_ZONE);
                            onChange(date);
                            checkReservationConflict();
                          }}
                          nonMarkedDate={true}
                          minDate={moment(watch("start")).toDate()}
                          {...(watch("start")
                            ? {
                                minTime: moment(value).isSame(moment(watch("start")), "day")
                                  ? moment(watch("start")).add(30, "minutes").toDate()
                                  : moment().startOf("day").toDate(),
                                maxTime: moment().endOf("day").toDate(),
                              }
                            : {})}
                          selectedDate={value ? moment(value).toDate() : null}
                          className={`mr10 minmax180 `}
                          disabled={!!reservation?.reservationId}
                          errorText={error?.message}
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </section>
            </div>
            <div>
              {hasConflict && (
                <p className="validation-text pb10" style={{ marginLeft: 145 }}>
                  선택한 일정에 예약 불가능한 시간이 포함되어 있습니다. 다시 선택해 주세요.
                </p>
              )}
              {isSameOrAfterDate && (
                <p className="validation-text pb10" style={{ marginLeft: 145 }}>
                  종료일시는 시작일시보다 늦어야 합니다. 다시 선택해 주세요.
                </p>
              )}
            </div>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">예약명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <Controller
                    control={control}
                    name="summary"
                    render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                      <BaseInput
                        placeholder="예약명을 입력해주세요"
                        className="mr8"
                        type="text"
                        onChange={onChange}
                        value={value}
                        name={name}
                        errorText={error?.message}
                        maxLength={50}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>예약 내용</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="">
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                      <BaseTextarea
                        placeholder="예약명을 입력해주세요"
                        className="mr8"
                        onChange={onChange}
                        value={value}
                        name={name}
                        errorText={error?.message}
                        maxLength={1000}
                        disabled={!!reservation?.reservationId}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p className="required">주최자</p>
              </div>
              <div className="contents-container__grid-contents">
                <Controller
                  control={control}
                  name="organizerMemberNo"
                  render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
                    <div>
                      <div className="flex-center">
                        <BaseButton
                          title="선택" //
                          className="color-white mr10"
                          disabled={!watch("contractId") || !!reservation?.reservationId}
                          onClick={() =>
                            setAbstractModalZ1({
                              isOpen: true,
                              children: (
                                <RvMembersModal
                                  title="주최자"
                                  selectKey="organizerMemberNo"
                                  onClick={(members) => {
                                    const member = members[0];

                                    onChange(member.memberNo);
                                    setValue("organizerMemberEmail", member.email);
                                  }}
                                  onClose={() => setAbstractModalZ1({ isOpen: false })}
                                  applyNumber={watch("contractApplyNumber") || ""}
                                  contractName={watch("contractName") || ""}
                                />
                              ),
                            })
                          }
                        />
                        {value && <p>{watch("organizerMemberEmail")}</p>}
                      </div>
                      {error?.message && <p className="validation-text">{error?.message}</p>}
                    </div>
                  )}
                ></Controller>
              </div>
            </section>
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>참석자</p>
              </div>
              <div className="contents-container__grid-contents">
                <div>
                  <Controller
                    control={control}
                    name="attendees"
                    render={({ field: { onChange, value: attendeeList, name, ref }, fieldState: { error } }) => {
                      return (
                        <div className="flex-start">
                          <BaseButton
                            title="선택" //
                            className="color-white mr10"
                            disabled={!watch("contractId") || watch("facilityType") !== "MEETING_ROOM"}
                            onClick={() =>
                              setAbstractModalZ1({
                                isOpen: true,
                                children: (
                                  <RvMembersModal
                                    title="참석자"
                                    selectKey="memberNo"
                                    addedItems={attendeeList?.map((item) => item.member?.memberNo || "")}
                                    organizerMemberNo={watch("organizerMemberNo")}
                                    onClick={(members) => {
                                      const prev = attendeeList?.map(({ member }) => {
                                        return { member: { memberNo: member?.memberNo, memberEmail: member?.memberEmail } };
                                      });

                                      const addAttendMembers = members.map(({ memberNo, email }) => {
                                        return { member: { memberNo, memberEmail: email } };
                                      });

                                      // ��복된 항목을 제거한다
                                      const unique = [...(prev || []), ...addAttendMembers].filter(
                                        (item, index, self) =>
                                          index ===
                                          self.findIndex(
                                            (t) => t.member.memberNo === item.member.memberNo && t.member.memberEmail === item.member.memberEmail,
                                          ),
                                      );
                                      onChange(unique);
                                    }}
                                    onClose={() => setAbstractModalZ1({ isOpen: false })}
                                    applyNumber={watch("contractApplyNumber") || ""}
                                    checkboxType="checkbox"
                                    contractName={watch("contractName") || ""}
                                  />
                                ),
                              })
                            }
                          />
                          {attendeeList && (
                            <ul className="flex-center flex-wrap pt10" style={{ gap: "10px" }}>
                              {attendeeList.map(({ member }) => (
                                <li key={member?.memberNo} className="flex-center list-none mr10">
                                  <p>{member?.memberEmail}</p>
                                  <div
                                    className="delete-btn-x"
                                    onClick={() => {
                                      const filteredMember = attendeeList.filter((item) => item.member?.memberNo !== member?.memberNo);
                                      setValue("attendees", filteredMember);
                                    }}
                                  ></div>{" "}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      );
                    }}
                  ></Controller>
                </div>
              </div>
            </section>
          </article>
        </div>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">{!reservation?.reservationId && <GoToListButton />}</div>
        <div className="right-area">
          {reservation?.reservationId && (
            <BaseButton
              title={"수정취소"}
              className="color-white size-large mr5"
              onClick={() => {
                navigate(`${PagePath.reservation.detail.replace(":id", String(reservation.reservationId))}`);
              }}
            />
          )}
          <div className="flex-center">
            <BaseButton type="submit" title="저장" className="size-large" disabled={hasConflict || isNotEditable || isSameOrAfterDate} />
          </div>
        </div>
      </div>
    </form>
  );
};

export default BasicInfoForm;
