export const localesEn = {
  "lnb.sideBar.body.main.link": "Dashaboard",
  "lnb.sideBar.body.monitoring.link": "Space Monitoring",
  "lnb.sideBar.body.building.link": "Building",
  "lnb.sideBar.body.product.link": "Product",
  "lnb.sideBar.body.workorder.link": "Work Order",
  "lnb.sideBar.body.cs.link": "Customer Service",
  "lnb.sideBar.body.contract.link": "Contract",
  "lnb.sideBar.body.provider.link": "Settlement Information",
  "lnb.sideBar.body.reservation.link": "Shared Space reservation",
  "lnb.sideBar.body.user.link": "Member Invitation",
  "lnb.sideBar.body.visitor.link": "Visitor Invitation",
  "lnb.sideBar.body.notice.link": "Notice",
  "lnb.sideBar.body.org.link": "Business information",
  "lnb.sideBar.body.billing.link": "Billing Schedule",
  "lnb.sideBar.body.option.link": "Settings",
  "lnb.sideBar.body.guide.link": "Services Guide",
  "lnb.sideBar.body.voc.link": "VoC Categories",
  "lnb.sideBar.body.price.link": "Shared Spaces Pricing",
  "lnb.sideBar.body.cctv.link": "CCTV Devices",
  "lnb.sideBar.body.login": "Last login",
};
