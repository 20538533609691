import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import useNavigate from "src/hooks/usePartnerNavigate";
import { facilityTabs, SubTabType } from "../building-types";

const useSubTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  const [selectedTab, setSelectedTab] = useState<SubTabType>(facilityTabs[0]);

  useEffect(() => {
    const foundTab = facilityTabs.find((tab) => tab.value === queryParams.facilityType);
    setSelectedTab(foundTab || facilityTabs[0]);
  }, [queryParams.facilityType]);

  const handleSelectTab = (tab: SubTabType, queryString: string) => {
    setSelectedTab(tab);
    // 현재 URL의 쿼리 파라미터를 파싱

    navigate(`${location.pathname}${queryString}`);
  };

  return { selectedTab, handleSelectTab };
};

export default useSubTabs;
