import moment from "moment";
import { getBuildingsAsync } from "src/api/building/building-api";
import { getContractApply } from "src/api/contract/contract-api";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { getQuestionDetailListAsync, getQuestionListAsync } from "src/api/question/qeustion-api";
import { QuestionAnswerModel, QuestionListModel, QuestionQueryParams } from "src/api/question/question-type";
import { YmdFormat, numberToStringWithComma } from "src/utils";

export interface FetchQuestionListResponse {
  csList: Array<
    QuestionListModel & {
      buildingName?: string;
      answerList?: QuestionAnswerModel[];
      contract?: any; // 계약 정보 타입이 필요하다면 추가
      mbOrganizationName: string;
      durationTime?: number;
      contractName: string;
    }
  >;
  meta: {
    pageMeta?: PageMeta;
    [key: string]: any;
  };
}

const useCsHooks = (noLoading?: boolean) => {
  // 상담관리 기본정보 목록 api
  const { executeAsync: getQuestionList } = useApiOperation(getQuestionListAsync, { noLoading: noLoading });

  // 상담관리 상세정보 목록 api
  const { executeAsync: getQuestionDetailList } = useApiOperation(getQuestionDetailListAsync, { noLoading: noLoading });

  // 건물 목록 api
  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync, { noLoading: noLoading });

  // 계약 목록 api
  const { executeAsync: getContractApplyAsync } = useApiOperation(getContractApply, { noLoading: noLoading });

  /**
   * 소요시간 계산
   * @param csItem
   * @returns 1시간 1분 1초 형식으로 리턴
   */
  const getDurationTime = (csItem: QuestionListModel): number | undefined => {
    // 취소 상태일때 하이픈 반환
    // 완료 상태일때 완료 날짜와 생성 날짜 차이 계산
    // 나머지 상태는 생성 날짜와 현재 시간 차이 계산

    const status = csItem.status;
    const createdMoment = moment(csItem.createdDate);
    const completedMoment = moment(csItem.completedDate);
    const nowMoment = moment();

    // 취소 상태면 하이픈 반환
    if (status === "QUESTION_CANCELED") {
      return undefined;
    }

    // 완료 상태일 때 - 생성 날짜와 완료 날짜 차이 계산
    if (status === "QUESTION_COMPLETE" && csItem.completedDate) {
      const diffMinutes = completedMoment.diff(createdMoment, "minutes");
      return diffMinutes;
    }

    // 나머지 상태일 때 - 생성 날짜와 현재 시간 차이 계산
    const diffMinutes = nowMoment.diff(createdMoment, "minutes");
    return diffMinutes;
  };

  /**
   * cs 기본정보 리스트에 건물명과 답변내용 바인딩하여 리턴
   * @param passParams
   * @returns { csList , meta }
   */
  const fetchQuestionList = async (passParams: QuestionQueryParams): Promise<FetchQuestionListResponse> => {
    const getQuestionListRes = await getQuestionList(passParams);
    if (getQuestionListRes.status >= 200 && getQuestionListRes.status <= 299) {
      // 상담관리 기본정보 목록
      const csList = getQuestionListRes?.data?.data?.content ?? [];

      // result 에서 bulding id 만 추출
      const buildingIds = csList.map((item) => item.buildingId).filter((id) => id);
      // bulidingId 중복없애서 호출
      const uniqueBuildingIds = [...new Set(buildingIds)];

      const [buildingList, csDetailList, contractList] = await Promise.all([
        uniqueBuildingIds && uniqueBuildingIds.length > 0
          ? await getBuildings({
              page: 0,
              size: 999999,
              id: uniqueBuildingIds.join(","),
            }).then((res) => res?.data?.data?.content ?? [])
          : [],
        csList && csList.length > 0
          ? await getQuestionDetailList({
              page: 0,
              size: 999999,
              ids: csList.map((data) => data.id).join(","),
            }).then((res) => res?.data?.data?.content ?? [])
          : [],
        await getContractApplyAsync({
          page: 0,
          size: 999999,
          contractIds: csList
            .map((data) => data.connectedInfoList?.[0]?.connectedId) // 계약ID 리스트 중 첫번째 계약ID 추출
            .filter(Boolean) // null/undefined 값 제거
            .join(","),
        }).then((res) => res?.data?.data?.content ?? []),
      ]);

      // cs 기본정보 리스트에 건물명과 답변내용 바인딩
      const newCsList = csList.map((csItem) => {
        const buildingName = buildingList.find((building) => String(csItem.buildingId) === String(building.id))?.buildingName;
        const answerList = csDetailList.find((csDetail) => String(csItem.id) === String(csDetail.id))?.answerList;
        const contract = contractList.find((contract) => String(csItem.connectedInfoList?.[0]?.connectedId) === String(contract.contractId));

        const mbOrganizationName = contract?.mbOrganizationName || "-";
        const contractName = contract?.contractName || contract?.spaceProductName || "-";
        const durationTime = getDurationTime(csItem);

        return {
          ...csItem,
          buildingName: buildingName,
          answerList: answerList,
          contract: contractList,
          mbOrganizationName,
          durationTime,
          contractName: contractName,
        };
      });

      return { csList: newCsList, meta: getQuestionListRes.data.meta };
    }
    // 에러 상태일 때 기본 반환값
    return { csList: [], meta: { pageMeta: undefined } };
  };

  const makeExcelData = async (params: QuestionQueryParams) => {
    const data = await fetchQuestionList(params);

    if (data?.csList && data?.csList.length > 0) {
      const downloadData = data.csList?.map((item) => {
        // 상담관리 상태
        const QuestionStatusMap = {
          STATUS_UNRECOGNIZED: "정의되지 않은 상태",
          QUESTION_WAIT: "접수",
          QUESTION_PROCEEDING: "처리중",
          QUESTION_HOLD: "보류",
          QUESTION_COMPLETE: "완료",
          QUESTION_CANCELED: "취소",
        } as const;

        const SiteMap = {
          SITE_HOMEPAGE: "홈페이지",
          SITE_EXTERNAL: "외부 플랫폼",
          SITE_ETC: "기타",
          SITE_TAAP_SPACE: "TaapSpace",
          SITE_TAAP: "Taap",
        } as const;

        // 가장 최근 답변을 리턴
        const answer = item.answerList?.sort((a: QuestionAnswerModel, b: QuestionAnswerModel) => Number(b.id) - Number(a.id))?.[0].description;

        const formatData = {
          ...item,
          id: item.id ?? "-",
          status: QuestionStatusMap[item.status as keyof typeof QuestionStatusMap] ?? "-",
          csCategoryType: item.csCategoryType ?? "-",
          site: SiteMap[item.site as keyof typeof SiteMap] ?? "-",
          csTypeName: item.csTypeName ?? "-",
          summary: item.summary ?? "-",
          description: item.description ?? "-",
          answerList: answer ?? "-",
          buildingName: item.buildingName ?? "-",
          assigneeEmail: item.assigneeEmail ?? "-",
          cost: item.cost ? numberToStringWithComma(item.cost) : "-",
          expense: item.expense ? numberToStringWithComma(item.expense) : "-",
          modifiedDate: item.modifiedDate ? moment(item.modifiedDate).format(YmdFormat.YYYY_MM_DD_HH_MM) : "-",
          modifiedBy: isNaN(Number(item.modifiedBy)) ? item.modifiedBy || "-" : item?.reporterEmail || "-",
          completedBy: item.completedBy ?? "-",
          createdDate: item.createdDate ? moment(item.createdDate).format(YmdFormat.YYYY_MM_DD_HH_MM) : "-",
          completedDate: item.completedDate ? moment(item.completedDate).format(YmdFormat.YYYY_MM_DD_HH_MM) : "-",
          reporterName: item.reporterName ?? "-",
          durationTime: item.durationTime ?? "-",
          mbOrganizationName: item.mbOrganizationName ?? "-",
          contractName: item.contractName ?? "-",
          floorNum: item?.floorNum ? (item?.floorNum > 0 ? `지상${item?.floorNum}층` : `지하${Math.abs(item?.floorNum)}층`) : "-",
        };

        return formatData;
      });

      return downloadData;
    }
  };

  return { fetchQuestionList, makeExcelData, getDurationTime };
};

export default useCsHooks;
