import { range } from "lodash";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { BuildingModel, CommonFacilityModel, SpaceStyle } from "src/api/building/building-types";
import { MediaFile } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTextarea } from "src/components";
import PublicImageUpdate from "src/components/PublicImageUpdate";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { SelectOption, floorOptions, spaceStyleOptions } from "src/pages/building/building-types";

type Props = {
  building?: BuildingModel;
  onClose: () => void;
  handleConfirm: (passData?: CommonFacilityModel) => void;
  facility?: CommonFacilityModel;
  readonly?: boolean;
  onChageReadOnly: () => void;
};

const MeetingRoomBasicInfo = ({ building, onClose, handleConfirm, facility, readonly, onChageReadOnly }: Props) => {
  // const [mediaList, setMediaList] = useState<Array<MediaFile>>([]);

  const { isAuthority } = usePartnerAuthority();

  const rangeFloorOptions: Array<SelectOption> = useMemo(() => {
    // 건물 > 기본정보 > 규모 범위
    const floorRange = range(
      Number(building?.undergroundFloorNums || 0) === 0 ? 0 : Number(building?.undergroundFloorNums || 0) * -1,
      Number(building?.floorNums || 0) + 1,
    );
    const _rangeFloorOptions: Array<SelectOption> = [];
    for (let i = 0; i < floorRange.length; i++) {
      const floor: number = floorRange[i];
      if (floor !== 0) {
        _rangeFloorOptions.push({
          value: String(floor || ""),
          label: floorOptions.find((v: SelectOption) => v.value === String(floor || ""))?.label || "",
        });
      }
    }
    if (_rangeFloorOptions.length === 0) {
      return floorOptions;
    } else {
      return _rangeFloorOptions.sort((a: SelectOption, b: SelectOption) => Number(b.value || 0) - Number(a.value || 0));
    }
  }, [building]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,

    watch,
    formState: { errors },
  } = useForm<CommonFacilityModel>({
    defaultValues: {
      facilityName: "",
      spaceStyle: "SPACE_STYLE_UNRECOGNIZED",
      locationCode: "",
      equipments: "",
      description: "",
      mediaList: [],
    },
  });

  useEffect(() => {
    if (facility) {
      setValue("id", facility.id);
      setValue("floorNum", facility.floorNum);
      setValue("facilityName", facility.facilityName);
      setValue("spaceStyle", facility.spaceStyle);
      setValue("locationCode", facility.locationCode);
      setValue("facilityMaxPeopleNums", facility.facilityMaxPeopleNums);
      setValue("description", facility.description);
      setValue("equipments", facility.facilityList?.join(","));

      setValue("mediaList", facility.mediaList || []);
    }
  }, [facility]);

  useEffect(() => {
    register("floorNum", {
      required: "위치은 필수입력 항목입니다.",
    });
    register("facilityName", {
      required: "회의실 명은 필수입력 항목입니다.",
    });
    register("spaceStyle", {
      validate: {
        required: (spaceStyle?: SpaceStyle) => {
          const requiredMessage = "공간유형은 필수입력 항목입니다.";
          return (!!spaceStyle && spaceStyle !== "SPACE_STYLE_UNRECOGNIZED") || requiredMessage;
        },
      },
    });
    register("locationCode", {
      required: "Location code 는 필수입력 항목입니다.",
    });
    register("facilityMaxPeopleNums", {
      required: "정원은 필수입력 항목입니다.",
    });
  }, [register, getValues]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: CommonFacilityModel, e?: any) => {
    e.preventDefault();
    console.log("submitdata", data);
    handleConfirm(data);
    e.target.reset();
    return false;
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    console.log("onError errors", errors);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {/* <div className="base-abstract-modal__title"> */}
      <div className="contents-container__wrap px30">
        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>위치</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>
                  {floorOptions.find((option: SelectOption) => option.value === `${facility?.isGround ? "" : "-"}${facility?.floorNum}`)?.label}
                </span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="floorNum"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseSelect
                      className=""
                      stateOptions={rangeFloorOptions}
                      setStateValue={onChange}
                      value={value}
                      name={name}
                      errorText={errors.floorNum?.message}
                    />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>회의실 명</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{watch("facilityName")}</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="facilityName"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseInput
                      type="text" //
                      value={value}
                      name={name}
                      onChange={onChange}
                      placeholder="입력해주세요"
                      errorText={errors.facilityName?.message}
                    />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>회의실 유형</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{spaceStyleOptions.find((option: SelectOption) => option.value === watch("spaceStyle"))?.label}</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="spaceStyle"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseSelect
                      className="minmax240"
                      stateOptions={spaceStyleOptions}
                      setStateValue={onChange}
                      value={value}
                      name={name}
                      errorText={errors.spaceStyle?.message}
                    />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>Location code</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{watch("locationCode")}</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="locationCode"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseInput value={value} name={name} onChange={onChange} placeholder="입력해주세요" errorText={errors.locationCode?.message} />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p className={!readonly ? "required" : ""}>정원</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="minmax240">
                <span>{watch("facilityMaxPeopleNums")}명</span>
              </div>
            ) : (
              <div className="minmax240">
                <Controller
                  control={control}
                  name="facilityMaxPeopleNums"
                  render={({ field: { value, name, onChange }, formState: { errors } }) => (
                    <BaseSelect
                      stateOptions={Array.from(Array(100).keys()).map((i: number) => Object.assign({ label: `${i + 1}`, value: `${i + 1}` }))}
                      setStateValue={onChange}
                      value={String(value)}
                      name={name}
                      errorText={errors.facilityMaxPeopleNums?.message}
                    />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>시설 안내</p>
          </div>
          <div className="contents-container__grid-contents">
            {readonly ? (
              <div className="pt10">
                <span className="pre-formatted">{watch("equipments") || "-"}</span>
              </div>
            ) : (
              <div>
                <Controller
                  control={control}
                  name="equipments"
                  render={({ field: { value, name, onChange } }) => (
                    <BaseTextarea name={name} value={value} onChange={onChange} placeholder="입력해주세요" maxLength={1000} />
                  )}
                ></Controller>
              </div>
            )}
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>이용 안내</p>
          </div>
          <div className="contents-container__grid-contents">
            <div className="pt10">
              {readonly ? (
                <span className="pre-formatted">{watch("description") || "-"}</span>
              ) : (
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, name, onChange } }) => (
                    <BaseTextarea name={name} value={value} onChange={onChange} placeholder="입력해주세요" maxLength={1000} />
                  )}
                ></Controller>
              )}
            </div>
          </div>
        </section>

        <section className="contents-container__grid">
          <div className="contents-container__grid-index">
            <p>이미지 {readonly ? "" : `(${watch("mediaList")?.length || 0}/10)`}</p>
          </div>
          <div className="contents-container__grid-contents">
            <div style={{ width: 795 }}>
              <Controller
                control={control}
                name="mediaList"
                render={({ field: { value, onChange } }) => (
                  <>
                    {readonly && value?.length === 0 ? (
                      <span>-</span>
                    ) : (
                      <PublicImageUpdate
                        className="w-100"
                        fileMaxLength={10}
                        mediaFiles={value}
                        isOnlyView={readonly}
                        categoryName={"meetingRoom"}
                        mediaServiceType="MEDIA_BUILDING_MEETING_ROOM"
                        isUsedDescription
                        isUsedRepresentative
                        onChangeMediaFiles={(mediaFiles: MediaFile[]) => {
                          const hasPrimaryImage = mediaFiles.some((file) => file.isPrimary);
                          const medias = mediaFiles.map((file: MediaFile, index: number) => {
                            const additionalMediaFile: MediaFile = {
                              ...file,
                              ...{
                                mediaServiceType: "MEDIA_BUILDING_MEETING_ROOM", // 회의실
                                category1: "",
                                isPrimary: hasPrimaryImage ? file.isPrimary : index === 0,
                              },
                            };
                            return additionalMediaFile;
                          });
                          onChange(medias);
                          // setMediaList(medias);
                        }}
                      />
                    )}
                  </>
                )}
              ></Controller>
            </div>
          </div>
        </section>
      </div>

      <div className="flex-center-end pa30">
        <BaseButton type="button" title="취소" className="color-white mr5" onClick={onClose} />
        {isAuthority("w") && (
          <>
            {readonly ? (
              <BaseButton type="button" title={"수정"} onClick={() => onChageReadOnly()} /> //
            ) : (
              <BaseButton type="submit" title={"저장"} />
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default MeetingRoomBasicInfo;
