import { AxiosInstance, AxiosResponse } from "axios";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  BuildingDeviceControl,
  DeviceModel,
  BuildingDeviceAddModel,
  ContractControlMemberParams,
  ContractDeviceAddType,
  ContractDeviceControl,
  ContractDeviceControlMember,
  DeviceControlParams,
  IoTDeviceRegistration,
  IotDeviceQueryParams,
  ProductDeviceAddType,
  ProductDeviceControl,
  RegisteredDeviceQueryParams,
} from "./iot-types";
import qs from "qs";

/**RPI11. IoT 건물 디바이스 목록 페이지 조회 */
export const getBuildingDeviceAsync = async (
  axios: AxiosInstance,
  params?: IotDeviceQueryParams, //
): Promise<AxiosResponse<ApiResponse<{ content: Array<DeviceModel> }>>> => {
  return await axios.get(ApiPath.iot.building.device.list, {
    params,
    paramsSerializer: { serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true }) },
  });
};

/**RPI01. IoT 등록 디바이스 조회*/
export const getBuildingDeviceToRegister = async (
  axios: AxiosInstance,
  params?: RegisteredDeviceQueryParams, //
): Promise<AxiosResponse<ApiResponse<{ content: Array<IoTDeviceRegistration> }>>> => {
  return await axios.get(ApiPath.iot.registered.list, {
    params,
    paramsSerializer: { serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true }) },
  });
};

/**RPI12. IoT 건물 디바이스 등록 처리*/
export const postBuildingIotDeviceAsync = async (axios: AxiosInstance, params?: BuildingDeviceAddModel) => {
  return await axios.post(ApiPath.iot.building.device.add, params);
};

/**RPI13. IoT 건물 디바이스 삭제 처리*/
export const deleteBuildingIotDeviceAsync = async (
  axios: AxiosInstance,
  params?: { id: string | number }, //
) => {
  return axios.delete(ApiPath.iot.building.device.delete.replace("{buildingDeviceId}", String(params?.id)));
};

/**RPI21. IoT 건물 디바이스 제어 목록 조회*/
export const getBuildingDeviceControlAsync = async (
  axios: AxiosInstance,
  params?: { deviceType: "building" | "product" | "contract"; id: string | number }, //
): Promise<
  AxiosResponse<
    ApiResponse<{
      controls: Array<BuildingDeviceControl>;
    }>
  >
> => {
  let path = "";
  if (params) {
    switch (params.deviceType) {
      case "building":
        path = ApiPath.iot.building.control.list.replace("{buildingDeviceId}", String(params?.id));
        break;

      case "product":
        path = ApiPath.iot.product.control.list.replace("{productDeviceId}", String(params?.id));
        break;
      case "contract":
        path = ApiPath.iot.contract.control.list.replace("{contractDeviceId}", String(params?.id));
        break;
    }
  }

  return await axios.get(path);
};

/**======== 상품 IoT ======= */
/**RPI31. IoT 상품 디바이스 목록 페이지 조회 */
export const getProductDeviceAsync = async (
  axios: AxiosInstance,
  params?: IotDeviceQueryParams, //
): Promise<AxiosResponse<ApiResponse<{ content: Array<DeviceModel> }>>> => {
  return await axios.get(ApiPath.iot.product.device.list, {
    params,
    paramsSerializer: { serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true }) },
  });
};

/**RPI32. IoT 상품 디바이스 목록 추가 처리*/
export const postProductDevicesAsync = async (axios: AxiosInstance, params?: ProductDeviceAddType) => {
  return await axios.post(ApiPath.iot.product.device.add, params);
};

/**RPI33. IoT 상품 디바이스 목록 삭제 처리 */
export const deleteProductDeviceAsync = async (
  axios: AxiosInstance,
  params?: { id: string | number }, //상품 디바이스 아이디 목록
) => {
  return axios.delete(ApiPath.iot.product.device.delete.replace("{productDeviceId}", String(params?.id)));
};
/**RPI41. IoT 상품 디바이스 제어 목록 조회 */
export const getProductDevicesControlAsync = async (
  axios: AxiosInstance,
  params?: { productDeviceId: string | number }, //
): Promise<
  AxiosResponse<
    ApiResponse<{
      controls: Array<ProductDeviceControl>;
    }>
  >
> => {
  return await axios.get(ApiPath.iot.product.control.list.replace("{productDeviceId}", String(params?.productDeviceId)));
};

/**RPI22. / RPI42. /RPI62. IoT 디바이스 제어 실행 처리 */
export const deviceControlExcuteAsync = async (
  axios: AxiosInstance,
  params?: DeviceControlParams, //
) => {
  let path = "";

  if (params) {
    switch (params.deviceType) {
      case "building":
        path = ApiPath.iot.building.control.execute
          .replace("{buildingDeviceId}", String(params?.deviceId))
          .replace("{controlId}", String(params?.controlId));
        break;

      case "product":
        path = ApiPath.iot.product.control.execute
          .replace("{productDeviceId}", String(params?.deviceId))
          .replace("{controlId}", String(params?.controlId));
        break;

      case "contract":
        path = ApiPath.iot.contract.control.execute
          .replace("{contractDeviceId}", String(params?.deviceId))
          .replace("{controlId}", String(params?.controlId));
        break;
    }
  }

  return await axios.post(path, {
    arguments: params?.arguments !== undefined && params?.arguments !== "" ? [params.arguments] : [],
    cacheable: params?.cacheable,
  });
};

/**======== 신청/계약 IoT ======= */
/**RPI51. IoT 계약 디바이스 목록 페이지 조회 */
export const getContractDeviceAsync = async (
  axios: AxiosInstance,
  params?: IotDeviceQueryParams, //
): Promise<AxiosResponse<ApiResponse<{ content: Array<DeviceModel> }>>> => {
  return await axios.get(ApiPath.iot.contract.device.list, {
    params,
    paramsSerializer: { serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true }) },
  });
};

/**RPI52. IoT 계약 디바이스 목록 추가 처리*/
export const postContractDevicesAsync = async (axios: AxiosInstance, params?: ContractDeviceAddType) => {
  return await axios.post(ApiPath.iot.contract.device.add, params);
};

/**RPI53. IoT 계약 디바이스 목록 삭제 처리 */
export const deleteContractDeviceAsync = async (
  axios: AxiosInstance,
  params?: { id: string | number }, //상품 디바이스 아이디 목록
) => {
  return axios.delete(`${ApiPath.iot.contract.device.delete}?id=${params?.id}`);
};
/**RPI61. IoT 계약 디바이스 제어 목록 조회 */
export const getContractDevicesControlAsync = async (
  axios: AxiosInstance,
  params?: { contractDeviceId: string | number }, //
): Promise<
  AxiosResponse<
    ApiResponse<{
      controls: Array<ContractDeviceControl>;
    }>
  >
> => {
  return await axios.get(ApiPath.iot.contract.control.list.replace("{contractDeviceId}", String(params?.contractDeviceId)));
};

/**RPI63. IoT 계약 디바이스 제어 사용자 목록 조회 */
export const getContractDeviceMembersAsync = async (
  axios: AxiosInstance,
  params?: ContractControlMemberParams, //
): Promise<
  AxiosResponse<
    ApiResponse<{
      members: Array<ContractDeviceControlMember>;
    }>
  >
> => {
  return await axios.get(
    ApiPath.iot.contract.control.members
      .replace("{contractDeviceId}", String(params?.contractDeviceId))
      .replace("{controlId}", String(params?.controlId)),
  );
};

/**RPI64. IoT 계약 디바이스 제어 사용자 목록 추가 처리 */
export const postContractDeviceMembers = async (
  axios: AxiosInstance,
  params?: ContractControlMemberParams, //
) => {
  return await axios.post(
    ApiPath.iot.contract.control.members
      .replace("{contractDeviceId}", String(params?.contractDeviceId))
      .replace("{controlId}", String(params?.controlId)),
    {
      memberNos: params?.memberNos,
    },
  );
};

/**RPI65. IoT 계약 디바이스 제어 사용자 목록 삭제 처리 */
export const deleteContractDeviceMembers = async (
  axios: AxiosInstance,
  params?: ContractControlMemberParams, //
) => {
  return await axios.delete(
    `${ApiPath.iot.contract.control.members
      .replace("{contractDeviceId}", String(params?.contractDeviceId))
      .replace("{controlId}", String(params?.controlId))}?id=${params?.controlMemberId}`,
  );
};
