import { Cell } from "react-table";
import { CommonFacilityListModel } from "src/api/building/building-types";
import { BaseTooltip } from "src/components";
import BaseNewTabLink from "src/components/BaseNewTabLink";
import { floorOptions, SelectOption } from "src/pages/building/building-types";
import { PagePath } from "src/pages/product/details";

export const rvFacilityColumns: any = [
  {
    Header: "공용공간 id",
    accessor: "id",
    width: 100,
    Cell: (
      { row, value }: Cell<CommonFacilityListModel>, //
    ) => <BaseNewTabLink path={PagePath.building.detail.replace(":id", String(row.original.buildingId)) + "?tab=facility"} value={value} />,
  },

  {
    Header: "건물",
    accessor: "buildingName",
    width: 200,
    Cell: (
      { row, value }: Cell<CommonFacilityListModel>, //
    ) => (
      <div className="w-100 text-left">
        <BaseNewTabLink path={PagePath.building.detail.replace(":id", String(row.original.buildingId))} value={value} />
      </div>
    ),
  },

  {
    Header: "위치",
    accessor: "floor",
    width: 120,
    Cell: ({ row }: Cell<CommonFacilityListModel>) => {
      const isGround: boolean = row?.original?.isGround || false; // 지상여부
      const floorNum: number = row?.original?.floorNum || 0; // 위치
      const floor = floorOptions.find((option: SelectOption) => option.value === `${isGround ? "" : "-"}${floorNum}`);
      return floor?.label || "";
    },
  },
  {
    Header: "공용공간 유형",
    accessor: "commonFacilityType",
    width: 100,
    Cell: ({ value }: Cell<CommonFacilityListModel>) => {
      let facilityType;
      switch (value) {
        case "DESK": {
          facilityType = "좌석";
          break;
        }
        case "MEETING_ROOM": {
          facilityType = "회의실";
          break;
        }
        case "REFRESH_ROOM": {
          facilityType = "편의시설";
          break;
        }
      }
      return facilityType;
    },
  },
  {
    Header: "공용공간명",
    accessor: "facilityName",
    width: 200,
    Cell: ({ value }: Cell<CommonFacilityListModel>) => <span className="w-100 text-left">{value}</span>,
  },
  {
    Header: "Location code",
    accessor: "locationCode",
    width: 160,
    Cell: ({ value }: Cell<CommonFacilityListModel>) => {
      return <BaseTooltip contents={value || "-"} className="text-left w-100" />;
    },
  },
];
