import { useQuery, useMutation } from "@tanstack/react-query";
import { objectEntries, objectValues } from "@toss/utils";
import moment from "moment";
import {
  postOpenAIFileUploadAsync,
  postOpenAIThreadAsync,
  postOpenAIMessageAsync,
  postOpenAIExecutionAsync,
  getOpenAIExecutionAsync,
  getOpenAIMessagesAsync,
} from "src/api/question/qeustion-api";

import { axiosInstanceNoLoading, YmdFormat } from "src/utils";

// 프롬프트 목록
const ANALYSIS_PROMPTS_MAP = {
  CommonIssues: `팩토리얼성수 건물의 고객 불만 데이터가 담긴 파일의 ‘접수내용’ 컬럼을 분석해서 고객의 주요 불만 사항을 분석해서 알려줘. 최대 600자 내로 요약해서 알려줘.`,
  Risks: `고객 서비스 데이터가 포함된 Excel 파일에서 ‘접수 내용‘, ‘접수자 소속회사’ 및 ‘처리 소요시간’ 열을 확인하고, 다음 기준에 따라 위험 요인을 아래와 같이 분석해 주세요:
1) 안전 관련 키워드가 포함된 경우, 관련 접수건 수를 알려주세요.
2) ‘처리 소요시간‘데이터가 3일을 넘어가는 경우 , 접수건수를 알려주세요.
3) 특정 소속회사에서 다수의 접수가 있는 경우, 상위 3개의 회사명과 접수건수를 알려주세요.
위 요인을 분석해 주고, 개선 아이디어는 제외해 주세요.`,
  ComplainantSentiments: `데이터 준비:
‘팩토리얼성수’ 건물의 고객 불만 데이터가 포함된 Excel 파일 로드
‘접수 내용’ 열에 초점을 맞춤
감정 분석:
각 ‘접수 내용‘에 대해 감정 분석 수행
부정적 감정의 내용 특히 화남과 짜증, 불만을 표현한 내용 식별,
<Emotion>
“화남“: “죽겠어요,너무,힘들어요,더워요,살려주세요,언제되나요?”
“짜증“: “언제 쯤, 그만, 해주세요, 제발”
</Emotion>
감정 분류:
각 내용을 ‘중립‘, ‘화남‘, ‘짜증’ 중 하나로 분류
통계 산출:
전체 문의 중 각 감정 상태의 비율 계산
주요 불만 사항 추출:
화를 유발한 주요 문제점 식별
600자 이내로 요약
결과 정리:
부정적 감정이 표현된 사례 목록 작성
주요 불만 사항 요약을 3건 제공
감정 상태 비율 제공`,
  Patterns: `팩토리얼성수 건물의 고객 불만 데이터가 담긴 파일의 ‘접수내용‘과 ‘접수 일시’ 열의 데이터를 분석하여 어떤 요일과 시간대에 가장 문의가 많이 접수되는지 파악해줘. 그리고 그 요일과 시간대에 주로 어떤 문의가 접수되는지 500자 내로 요약해서 알려줘. 어떤 문의인지 알려주는 기준은 ‘접수내용’ 열의 데이터를 기준으로 해줘.`,
  Suggestions: `팩토리얼성수 건물의 고객 불만 데이터가 담긴 엑셀 파일 분석하여, 고객의 불만 사항을 해결하기 위한 구체적이고 실질적인 개선 아이디어를 제안해 주세요. 문제를 해결하기 위한 구체적인 조치와 구체적인 방법을 900자 내로 요약해서 알려주세요. 작성한 내용 중에 주요 불만 사항은 삭제해 주세요.`,
};

// const ANALYSIS_PROMPTS_MAP = {
//   CommonIssues: `다음 데이터에서 가장 빈번한 문제 3가지를 알려주세요:
//      - 각 문제의 발생 건수
//      - 관련된 주요 키워드
//      표 형식으로 정리해주세요.`,

//   Risks: `다음 데이터를 분석해주세요:
//      1. CS 접수가 가장 많은 3개 업체와 각각의 건수
//      2. 긴급 대응이 필요한 위험 사례 (최대 5개)`,

//   ComplainantSentiments: `다음 데이터의 감정 분석을 해주세요:
//      1. 긍정/부정/중립의 비율
//      2. 가장 많이 사용된 감정 표현 단어 5개
//      3. 대표적인 고객 피드백 2개`,

//   Patterns: `다음 데이터에서 패턴을 분석해주세요:
//      1. 요일별 특징
//      2. 시간대별 특징
//      3. 반복되는 민원 유형`,

//   Suggestions: `다음 데이터를 바탕으로:
//      1. 즉시 적용 가능한 개선안 3가지
//      2. 각 개선안의 실행 난이도(상/중/하)
//      3. 예상되는 효과`,
// };

const analysisPrompts = objectEntries(ANALYSIS_PROMPTS_MAP);

export interface AIAnalysisData {
  promptKey: "CommonIssues" | "Risks" | "ComplainantSentiments" | "Patterns" | "Suggestions";
  content: string;
}
export interface AIAnalysisResult {
  success: boolean;
  data?: AIAnalysisData[];
  error?: Error;
}

/**
 * CS AI 분석 hook
 * AI 분석 API 사용방법 링크
 * https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/879329605/2024.12.23+-+CS+AI+OpenAI+Platform+api#02-Thread-%EC%83%9D%EC%84%B1
 * @returns
 */
export const useAIAnalysis = () => {
  const startAnalysis = async (dataRange: [Date, Date], xlsx: any, signal?: AbortSignal): Promise<AIAnalysisResult> => {
    const startTime = Date.now(); // 시작 시간 기록
    console.log("🔍 Analysis started");

    try {
      if (signal?.aborted) {
        throw new Error("Analysis cancelled");
      }

      // 1. 파일 업로드
      const uploadResponse = await postOpenAIFileUploadAsync(axiosInstanceNoLoading, { file: xlsx });
      const fileId = uploadResponse.data.data.id;

      // 병렬 처리를 위한 함수
      const processPrompt = async (promptKey: keyof typeof ANALYSIS_PROMPTS_MAP, prompt: string, index: number): Promise<AIAnalysisData> => {
        let retryCount = 0;
        if (signal?.aborted) throw new Error("Analysis cancelled");

        console.log(`🚀 시작: ${promptKey}`);

        // 인덱스에 따른 딜레이 추가
        await new Promise((resolve) => setTimeout(resolve, index * 100));

        // 각 프롬프트마다 새로운 스레드 생성
        const newThreadResponse = await postOpenAIThreadAsync(axiosInstanceNoLoading, {
          fileId,
          message: `Ctrlroom CS from ${moment(dataRange[0]).format(YmdFormat.YYYY_MM_DD)} to ${moment(dataRange[1]).format(YmdFormat.YYYY_MM_DD)}`,
        });
        const newThreadId = newThreadResponse.data.data.id;

        // 3. Message 생성
        await postOpenAIMessageAsync(axiosInstanceNoLoading, {
          threadId: newThreadId, // 새로운 스레드 ID 사용
          content: prompt,
          attachments: [
            {
              fileId: fileId,
              tools: [{ type: "code_interpreter" }],
            },
          ],
        });

        // 4. Run 생성
        const runResponse = await postOpenAIExecutionAsync(axiosInstanceNoLoading, {
          threadId: newThreadId, // 새로운 스레드 ID 사용
          assistantId: process.env.REACT_APP_ASSISTANT! || "asst_6Uy89yh1yE4ix8bGJWuANCoH",
          tools: [{ type: "code_interpreter" }],
        });
        const runId = runResponse.data.data.id;

        // 5. Run 상태 확인 (개선된 폴링 로직)
        const checkStatus = async (): Promise<void> => {
          if (signal?.aborted) throw new Error("Analysis cancelled");
          retryCount++;
          const statusResponse = await getOpenAIExecutionAsync(axiosInstanceNoLoading, {
            threadId: newThreadId,
            runId,
          });

          const status = statusResponse.data.data.status;
          console.log(`${promptKey}: ${retryCount}번째 시도 - ${status}`);

          if (status === "failed") {
            throw new Error(`Run status check failed prompt: ${promptKey}
              statusResponse:${JSON.stringify(statusResponse.data)}
              `);
          }
          if (status !== "completed") {
            if (signal?.aborted) throw new Error("Analysis cancelled");
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return checkStatus();
          }

          console.log(`✅ 완료: ${promptKey} (총 ${retryCount}회 시도)`);
        };

        await checkStatus();

        // 6. 결과 메시지 조회
        const messagesResponse = await getOpenAIMessagesAsync(axiosInstanceNoLoading, {
          threadId: newThreadId,
          query: { limit: 1, runId },
        });

        return {
          promptKey,
          content: messagesResponse?.data?.data?.content?.[0]?.content?.[0]?.text?.value,
        };
      };

      // 병렬 처리로 변경하고 인덱스 전달
      const analysisResults = await Promise.all(analysisPrompts.map(([promptKey, prompt], index) => processPrompt(promptKey, prompt, index)));

      console.log("\n🎉 All prompts processed successfully! analysisResults:", analysisResults);
      return {
        success: true,
        data: analysisResults,
      };
    } catch (error) {
      console.error("❌ Error during analysis:", error);
      return {
        success: false,
        error: error as Error,
      };
    } finally {
      const endTime = Date.now(); // 종료 시간 기록
      const duration = (endTime - startTime) / 1000; // 초 단위로 변환

      console.log(`⏱️ Analysis completed in ${duration} seconds`);
    }
  };

  return {
    startAnalysis,
  };
};
