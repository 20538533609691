import { isEmpty } from "lodash";
import moment from "moment";
import { HistoryUpdate } from "src/api/history/history-types";
import { BaseButton } from "src/components";
import { useModal } from "src/recoil/modalState/hook";
import { ViewYmdFormat } from "src/utils";
import { KeyNamesMap } from "./libs";

const HistoryDetailModal = ({ historyUpdate }: { historyUpdate: HistoryUpdate }) => {
  const { setAbstractModalZ1 } = useModal();

  // 객체를 재귀적으로 순회하며 key-value 형태로 표시하는 컴포넌트
  const RenderObject = ({ data }: { data: any }) => {
    if (typeof data !== "object" || data === null) {
      return <span>{String(data)}</span>;
    }

    return (
      <div className="object-content">
        {Object.entries(data).map(([key, value]) => {
          const matchedKeyName = KeyNamesMap[key as keyof typeof KeyNamesMap] || key;

          if (value === null || value === "" || value === undefined || value === " " || isEmpty(value)) {
            return null;
          }

          return (
            <div key={key} className="object-item">
              <span className="object-key">{matchedKeyName}: </span>
              <span className="object-value">
                <RenderObject data={value} />
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  // 변경 내용을 표시하는 컴포넌트
  const ChangeItem = ({ item, index }: { item: any; index: number }) => {
    const matchedPathName = item.path
      .split("/")
      .map((pathKey: string) => {
        if (!isNaN(Number(pathKey))) return pathKey;
        return KeyNamesMap[pathKey as keyof typeof KeyNamesMap] || pathKey;
      })
      .join("/");

    return (
      <div className="change-item">
        <div className="change-header">
          <span className={`index`}>{index + 1}</span>
          <span className={`change-type ${item.op === "replace" ? "type-replace" : "type-add"}`}>{item.op}</span>
          <div className="change-path">
            <span className="matched-path">{matchedPathName}</span>
            <span className="original-path">{item.path}</span>
          </div>
        </div>
        <div className="change-content">
          <div className="change-origin">
            <span className="label">변경 전: </span>
            {typeof item.origin === "object" ? <RenderObject data={item.origin} /> : <span>{String(item.origin ?? "-")}</span>}
          </div>
          <div className="change-value">
            <span className="label">변경 후: </span>

            {item.op !== "remove" ? (
              <>{typeof item.value === "object" ? <RenderObject data={item.value} /> : <span>{String(item.value ?? "-")}</span>}</>
            ) : (
              <span>삭제됨</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="base-abstract-modal__title">
        <h1>변경내용 상세</h1>
      </section>

      <section className="base-abstract-modal__contents-minmax-height px30">
        <div className="history-detail-container">
          {/* 공통 정보 영역 */}
          <div className="common-info">
            <div className="info-item">
              <span className="label">변경자</span>
              <span>{historyUpdate.user?.displayName}</span>
            </div>
            <div className="info-item">
              <span className="label">변경일시</span>
              <span>{moment(historyUpdate.revisionTime).format(ViewYmdFormat.YYYY_MM_DD_HH_MM)}</span>
            </div>
            <div className="info-item">
              <span className="label">타입</span>
              <span>{historyUpdate.message?.type}</span>
            </div>
            <div className="total-changes">총 {historyUpdate.message?.data?.data?.length || 0}개의 변경 사항</div>
          </div>

          {/* 변경 내용 목록 */}
          <div className="changes-list">
            {historyUpdate.message?.data?.data?.map((item, index) => (
              <ChangeItem key={index} item={item} index={index} />
            ))}
          </div>
        </div>
      </section>

      <section className="base-abstract-modal__btn-wrap">
        <BaseButton title="닫기" className="color-white" onClick={() => setAbstractModalZ1({ isOpen: false })} />
      </section>
    </>
  );
};

export default HistoryDetailModal;
