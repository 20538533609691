import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Popover } from "react-tiny-popover";

/**
 * 클릭시 띄워지는 툴팁
 */
type Props = {
  touchIcon?: "QUESTION" | "CIRCLE" | "INFO" | ReactNode; // 터치 아이콘을 정의합니다 (없을 경우는 leftLabel,rightLabel 모두 클릭가능)
  leftLabel?: React.ReactNode; // 좌측 라벨이 있을경우
  rightLabel?: React.ReactNode; // 우측 라벨이 있을경우
  status?: "ACTIVE" | "INACTIVE" | "CANNOT_USE" | "CAUTION" | "CAUTION_RED" | "LIGHT_TRAFFIC"; // touchIcon이 'CIRCLE' 이고, 컬러를 부모에서 직접 정의해야할 경우. 파랑, 회색, 검정색 순
  title?: string; // 툴팁제목
  children?: React.ReactNode; // 툴팁내용 (무조건 있어야 팝업이 뜹니다. 필수는 아님)
  className?: string;
  firstActionLabel?: string; //리치 툴팁의 첫번째 버튼 명칭
  secondActionLabel?: string; //리치 툴팁의 두번째 버튼 명칭
  firstActionFunction?: () => void; //리치 툴팁의 첫번째 액션함수
  secondActionFunction?: () => void; //리치 툴팁의 두번째 액션함수
};
const colorStatus = {
  ACTIVE: "--active",
  INACTIVE: "--inactive",
  CAUTION: "--caution",
  CANNOT_USE: "--cannot-use",
  CAUTION_RED: "--caution-red",
  LIGHT_TRAFFIC: "--light-traffic",
};
const BaseRichTooltip = ({
  touchIcon,
  leftLabel,
  rightLabel,
  status,
  className,
  title,
  children,
  firstActionLabel,
  firstActionFunction,
  secondActionLabel,
  secondActionFunction,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const touchAreaRef = useRef<HTMLDivElement>(null);

  // 스크롤 시 툴팁 닫기
  useEffect(() => {
    if (isPopoverOpen) {
      const tableMonitoringElement = document.querySelector(".table-monitoring");
      tableMonitoringElement?.addEventListener("scroll", handleScrollEvent);
      function handleScrollEvent() {
        setIsPopoverOpen(false);
        tableMonitoringElement?.removeEventListener("scroll", handleScrollEvent);
      }
    }
  }, [isPopoverOpen]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Escape" && isPopoverOpen) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isPopoverOpen]);

  // 툴팁의 위치값 계산
  useLayoutEffect(() => {
    if (touchAreaRef.current && tooltipRef.current) {
      const tooltipHeight = tooltipRef.current.clientHeight;
      const tooltipWidth = tooltipRef.current.clientWidth;
      const gap = 10;
      const iconSize = 20;
      if (tooltipRef?.current?.parentNode) {
        const tooltipParent: HTMLElement | null = tooltipRef.current.parentNode as HTMLElement;
        const icTooltipRect = touchAreaRef?.current?.getBoundingClientRect();
        const topPosition = Number(icTooltipRect?.y.toFixed(1));
        const leftPosition = Number(icTooltipRect?.x.toFixed(1));

        const adjustTooltipPosition = () => {
          const windowWidth = window.innerWidth;
          const tooltipRightEdge = leftPosition + tooltipWidth;
          const tooltipTopEdge = topPosition - (tooltipHeight + 10);
          const isNearTop = tooltipTopEdge < 0;
          const isNearRight = tooltipRightEdge + 30 > windowWidth;

          if (isNearTop && isNearRight) {
            // 툴팁이 윈도우 탑과 오른쪽에 닿으면 bottom-left 변경
            tooltipParent.style.top = `${topPosition + iconSize + gap}px`;
            tooltipParent.style.left = `${leftPosition - tooltipWidth + iconSize + gap}px`;
          } else if (isNearTop && !isNearRight) {
            // 툴팁이 윈도우 탑에만 닿으면 bottom-right 변경
            tooltipParent.style.top = `${topPosition + iconSize + gap}px`;
            tooltipParent.style.left = `${leftPosition - gap}px`;
          } else if (!isNearTop && isNearRight) {
            // 툴팁이 윈도우 오른쪽에만 닿으면 top-left 변경
            tooltipParent.style.top = `${topPosition - (tooltipHeight + gap)}px`;
            tooltipParent.style.left = `${leftPosition - tooltipWidth + iconSize + gap}px`;
          } else if (!isNearTop && !isNearRight) {
            // 기본 top-center
            tooltipParent.style.top = `${topPosition - (tooltipHeight + gap)}px`;
            tooltipParent.style.left = touchIcon
              ? `${leftPosition - tooltipWidth / 2 + iconSize / 2}px`
              : `${leftPosition - tooltipWidth / 2 + icTooltipRect.width / 2}px`;
          }
        };

        adjustTooltipPosition();

        // 윈도우 리사이징이 일어날때도 실시간으로 감지
        const handleResize = () => {
          adjustTooltipPosition();
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
      }
    }
  }, [isPopoverOpen]);

  return (
    <Popover
      isOpen={!!children && isPopoverOpen}
      onClickOutside={() => {
        setIsPopoverOpen(false);
      }}
      content={
        <>
          <div ref={tooltipRef} className={"pre-formatted popover-gray rich-tooltip"}>
            {title && <p className="popover-gray__title">{title}</p>}
            {children}
            {(firstActionFunction || secondActionFunction) && (
              <div className="popover-gray__btn-wrap">
                {firstActionFunction && <p onClick={firstActionFunction}>{firstActionLabel ? firstActionLabel : "Action"}</p>}
                {secondActionFunction && <p onClick={secondActionFunction}>{secondActionLabel ? secondActionLabel : "Action"}</p>}
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={`popover-gray-touch ${className ? className : ""}`}>
        {leftLabel &&
          (!touchIcon ? (
            <div
              className="cursor-pointer"
              ref={touchAreaRef}
              onClick={() => {
                setIsPopoverOpen(!isPopoverOpen);
              }}
            >
              {leftLabel}
            </div>
          ) : (
            leftLabel
          ))}
        {/* Question mark 타입인 경우 */}
        {touchIcon === "QUESTION" && (
          <div
            ref={touchAreaRef}
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
            className={`ic-question-touch ${!!children && "cursor-pointer"}`}
          >
            <div className="ic-question-mark"></div>
          </div>
        )}

        {/* CIRCLE 타입인 경우 (공간 모니터링) */}
        {touchIcon === "CIRCLE" && status && (
          <div
            ref={touchAreaRef}
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
            className={`circle__chip ${colorStatus[status]} ${children && "cursor-pointer"}`}
          ></div>
        )}

        {touchIcon === "INFO" && (
          <div
            ref={touchAreaRef}
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
            style={{ height: 20 }}
            className={` ${!!children && "cursor-pointer"}`}
          >
            <div style={{ width: 20, height: 20 }} className="flex-center error-icon ic-info"></div>
          </div>
        )}

        {typeof touchIcon === "object" && (
          <div
            ref={touchAreaRef}
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
            className={` ${!!children && "cursor-pointer flex-center"}`}
          >
            {touchIcon}
          </div>
        )}

        {rightLabel &&
          (!touchIcon ? (
            <div
              className="cursor-pointer"
              ref={touchAreaRef}
              onClick={() => {
                setIsPopoverOpen(!isPopoverOpen);
              }}
            >
              {rightLabel}
            </div>
          ) : (
            rightLabel
          ))}
      </div>
    </Popover>
  );
};

export default BaseRichTooltip;
