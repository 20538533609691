import { Ac2ProductAccessGroupModel } from "src/api/access/productac-types";
import { BuildingDeviceControl, BuildingDeviceLocation, IotLabel, UnionPlatformAccountCategory } from "src/api/iot/iot-types";
import { CctvDeviceType } from "./SpaceMonitoring";

export const IndoorUnitMode = {
  auto: "자동",
  Auto: "자동",
  heat: "난방",
  Heat: "난방",
  cool: "냉방",
  Cool: "냉방",
  wind: "송풍",
  dry: "제습",
} as const;

export type IndoorUnitKeyTypes = keyof typeof IndoorUnitMode;

export type UnionIndoorUnitMode = (typeof IndoorUnitMode)[keyof typeof IndoorUnitMode];

export type MonitoringIotDevice = {
  access?: Ac2ProductAccessGroupModel[]; // 출입기기
  sensor: ExecuteResultType[]; // 재실
  indoorUnit: ExecuteResultType[]; // 실내기
  temperature: ExecuteResultType[]; // 온도
  light: ExecuteResultType[]; // 조명
  mensToilet: ExecuteResultType[]; // 남자 화장실
  womensToilet: ExecuteResultType[]; // 여자 화장실
  cctv?: CctvDeviceType[]; // CCTV
};

export type MonitoringType = {
  floorNum: number;
  floorValue: string;
  floorId: string;
  floorDevices: MonitoringIotDevice;
  roomIds?: string[];
  facilityList: {
    facilityDevices: MonitoringIotDevice;
    id: string;
    facilityName: string;
    floorValue: string;
  }[];
};
// export type DeviceType = "출입기기" | "재실" | "실내기" | "온도" | "조명" | "남자화장실" | "여자화장실" | "";

export const DeviceType = {
  ACCESS: "출입기기",
  SENSOR: "재실",
  INDOOR_UNIT: "실내기",
  TEMPERATURE: "온도",
  LIGHT: "조명",
  MENS_TOILET: "남자화장실",
  WOMEN_TOILET: "여자화장실",
  CCTV: "CCTV",
} as const;

export type UnionDeviceType = (typeof DeviceType)[keyof typeof DeviceType];

export const DeviceStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  CANNOT: "CANNOT_USE",
  CAUTION: "CAUTION",
} as const;

export type UnionDeviceStatus = (typeof DeviceStatus)[keyof typeof DeviceStatus];

// 기기의 종류가 출입기기인지 iot 인지 구분
export const DeviceCategory = {
  ACCESS: "ACCESS",
  IOT: "IOT",
  CCTV: "CCTV",
} as const;

export type UnionDeviceCategory = (typeof DeviceCategory)[keyof typeof DeviceCategory];

// 타입
export type DeviceControlType = BuildingDeviceControl & {
  location: BuildingDeviceLocation;
  platform: UnionPlatformAccountCategory;
  labelType: UnionDeviceType;
};

export type ExecuteResultType = {
  buildingDeviceId: number;
  // control: RegisteredDeviceControl | null;
  location: BuildingDeviceLocation;
  labels: IotLabel[] | undefined;
  platform: UnionPlatformAccountCategory;
  // labelType: DeviceType | string;
  labelType: UnionDeviceType | null;
  internalDisplayName: string;
  result: any;
  // isLoading: "control" | "execute";
  isLoading: boolean;
};

export type IotMonitoringDevice = {
  floor: MonitoringIotDevice;
  facility: MonitoringIotDevice;
};
