export const BuildingParkingDataMap = {
  // id: "건물 주차장 ID",
  selfPropelledParkingLotNums: "자주식주차장수",
  selfPropelledParkingLotTotalNums: "자주식주차장대수",
  mechanicalParkingLotNums: "기계식주차장수",
  mechanicalParkingLotTotalNums: "기계식주차장대수",
  description: "설명",
} as const;

export const BuildingRoomDataMap = {
  // id: "건물_층_호실_ID",
  roomNum: "호실",
  roomName: "호실 명칭",
  leasableAreaNet: "전용면적",
  leasableArea: "임대면적",
  rent: "임대료",
  isPrimary: "대표여부",
  ownerId: "소유주 ID",
  ownerName: "소유주 명",
  managementUsingCount: "관리비상품 사용건수",
  isDeleted: "삭제여부",
} as const;

export const BuildingFloorDataMap = {
  // id: "건물_층_ID",
  floorNum: "층",
  floorName: "층 명칭",
  buildingRoomList: "호실",
  isDeleted: "삭제여부",
} as const;

export const BuildingDataMap = {
  // id: "건물_ID",
  partnerId: "파트너ID",
  serviceId: "공간상품-건물 매핑 ID",
  buildingName: "건물이름",
  locationCode: "Location Code",
  description: "설명",
  feature: "지리적특징",
  floorNums: "지상층수",
  undergroundFloorNums: "지하층수",
  completionYmd: "사용승인일",
  remodelingYmd: "리모델링 년도",
  buildingStructureType: "건축물 용도",
  entryStartTime: "출입시작시간",
  entryEndTime: "출입종료시간",
  isHoliday: "공휴일여부",
  leasableArea: "임대면적",
  leasableAreaNet: "전용면적",
  exitDirection: "주출입구방향",
  buildingToilet: "화장실",
  passengerElev: "승객엘리베이터",
  freightElev: "화물엘리베이터",
  heatingType: "냉난방타입",
  heatingTypeDesc: "냉난방타입설명",
  isHeating: "냉난방장비제공여부",
  heatingFacilityType: "냉난방장비타입",
  heatingFacilityTypeDesc: "냉난방장비타입설명",
  heatingFacilityTypeAddDesc: "냉난방방식추가설명",
  addressList: "다음 지도에서 돌려받은 주소정보",
  buildingFloorList: "건물 층/호실",
  buildingCommonFacility: "주차/회의실/좌석/편의시설",
  productNums: "해당 건물을 사용중인 상품 수",
  mgmtOfficeId: "관리처 ID",
  mgmtProviderId: "정산정보_관리처_ID",
  mapUrlNaver: "네이버맵 URL",
  mapUrlKakako: "카카오맵 URL",
  mapUrlT: "TMAP URL",
  mapUrlGoogle: "구글맵 URL",
} as const;

export const AddressDataMap = {
  // id: "주소 id",
  zonecode: "우편번호",
  address: "기본주소",
  addressDetail: "상세주소",
  addressEnglish: "기본영문주소",
  addressType: "기본주소타입",
  userSelectedType: "사용자선택주소타입",
  noSelected: "연관주소선택여부",
  userLanguageType: "사용자선택언어타입",
  roadAddress: "도로명주소",
  roadAddressEnglish: "도로명영문주소",
  jibunAddress: "지번주소",
  jibunAddressEnglish: "영문지번주소",
  autoRoadAddress: "자동매핑도로명주소",
  autoRoadAddressEnglish: "자동매핑도로명영문주소",
  autoJibunAddress: "자동매핑지번주소",
  autoJibunAddressEnglish: "자동매핑영문지번주소",
  buildingCode: "건물관리번호",
  buildingName: "건물명",
  apartment: "공동주택여부",
  sido: "도시이름",
  sidoEnglish: "도시영문이름",
  sigungu: "시군구이름",
  sigunguEnglish: "시군구영문이름",
  sigunguCode: "시군구코드",
  roadnameCode: "도로명코드",
  bcode: "법정동코드",
  roadname: "도로명값",
  roadnameEnglish: "도로명영문값",
  bname: "법정동이름",
} as const;

export const BuildingCommonFacilityDeskGroupDataMap = {
  // id: "그룹 ID",
  groupName: "그룹명",
  isGround: "지상여부",
  floorNm: "층",
  description: "설명",
  deskChartDescription: "좌석배치도 설명",
  buildingId: "건물 ID",
  deskList: "좌석 목록",
  desk: "좌석 목록 (삭제 예정)",
  mediaList: "좌석배치도 이미지",
} as const;

export const BuildingCommonFacilityDataMap = {
  parkingLotList: "건물 공용공간 주차장",
  mettingRoomList: "건물 공용공간 회의실",
  deskSpace: "건물 공용공간 좌석",
  deskGroupList: "좌석그룹",
  mediaList: "좌석 이미지",
  refreshRoomList: "건물 공용공간 편의시설",
} as const;

export const ProductDataMap = {
  // id: "공간상품_ID",
  partnerId: "파트너ID",
  buildingList: "공간상품에 등록된 건물정보",
  productType: "상품타입",
  productBuildingList: "상품 건물 리스트",
  productName: "공간상품이름",
  introduce: "한줄소개",
  status: "공개상태",
  description: "설명",
  productIconTypeList: "상품 아이콘 타입",
  minLeasePeriod: "최소계약기간",
  maxLeasePeriod: "최대계약기간",
  isRightAwayMoveIn: "즉시입주가능여부",
  moveInAvailDate: "입주가능일자",
  vocUrl: "vocurl",
  colorName: "색상명",
  productNotice: "상품 유의사항",
  deposit: "보증금",
  earnest: "계약금",
  balanceFullPaymentDate: "잔금일자",
  costType: "요금타입",
  productionCost: "원가",
  rentalCostList: "이용료",
  productOptionalFacilityList: "추가 시설/서비스",
  etcService: "기타시설서비스",
  parkingLotFreeDescription: "무료 주차 설명",
  parkingLotPayDescription: "유료 주차 설명",
  meetingRoomMaxReservTimeMonth: "회의실 한달 최대 예약가능 시간",
  meetingRoomMaxReservTimeDay: "회의실 하루 최대 예약가능 시간",
  isUnlimitedMeetingRoom: "회의실 무제한 여부",
  isAllowedMeetingRoomOverReservation: "회의실 초과예약 허용 여부",
  isLimitedMeetingRoomMonthlyReservation: "회의실 월별 예약제한 사용 여부",
  isLimitedMeetingRoomDailyReservation: "회의실 일별 예약제한 사용 여부",
  meetingRoomDescription: "회의실 추가 설명",
  deskMaxReservTimeMonth: "좌석 한달 최대 예약가능 시간",
  deskMaxReservTimeDay: "좌석 하루 최대 예약가능 시간",
  isUnlimitedDesk: "좌석 무제한 여부",
  isAllowedDeskOverReservation: "좌석 초과예약 허용 여부",
  isLimitedDeskMonthlyReservation: "좌석 월별 예약제한 사용 여부",
  isLimitedDeskDailyReservation: "좌석 일별 예약제한 사용 여부",
  deskDescription: "좌석 추가 설명",
  refreshRoomMaxReservTimeMonth: "편의시설 한달 최대 예약가능 시간",
  refreshRoomMaxReservTimeDay: "편의시설 하루 최대 예약가능 시간",
  isUnlimitedRefreshRoom: "편의시설 무제한 여부",
  isAllowedRefreshRoomOverReservation: "편의시설 초과예약 허용 여부",
  isLimitedRefreshRoomMonthlyReservation: "편의시설 월별 예약제한 사용 여부",
  isLimitedRefreshRoomDailyReservation: "편의시설 일별 예약제한 사용 여부",
  refreshRoomDescription: "편의시설 추가 설명",
  isParkingLot: "주차 사용 여부",
  isMeetingRoom: "회의실 사용 여부",
  isRefreshRoom: "편의시설 사용 여부",
  isDesk: "좌석 사용 여부",
  refundInfo: "결제 및 취소 안내",
  isDeleted: "삭제여부",
  createdDate: "생성일",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
  createdBy: "생성자",
  isUsed: "현재 사용중인지 여부",
} as const;

export const CommonFacilityDataMap = {
  id: "건물_공용시설_ID",
  buildingId: "건물_ID",
  commonFacilityType: "공용시설타입",
  isGround: "지상여부",
  floorNum: "층",
  facilityName: "시설명",
  facilityList: "시설 목록",
  facilityMaxPeopleNums: "시설정원(수)",
  eslAddress: "ESL 매핑주소",
  locationCode: "Location Code",
  description: "설명",
  spaceStyle: "공간유형",
  spaceStyleNum: "공간유형번호",
  mediaList: "시설 이미지",
  centerX: "좌석배치도 X 값",
  centerY: "좌석배치도 Y 값",
  BuildingCommonFacilityDeskGroupId: "좌석 그룹 ID",
  buildingCommonFacilityDeskGroupName: "좌석 그룹명",
  productBuilcingCommonfacilityId: "상품-건물 공용공간ID",
  productId: "상품ID",
  isUnlimitedReservation: "예약 무제한 여부",
  isAllowedOverReservation: "초과예약 허용 여부",
  isLimitedMonthlyReservation: "월별 예약제한 사용 여부",
  monthlyServiceMinutes: "월별 제공시간(분)",
  isLimitedDailyReservation: "일별 예약제한 사용 여부",
  dailyServiceMinutes: "일별 예약제한 시간(분)",
  isDeleted: "삭제여부",
} as const;

export const MediaFileDataMap = {
  id: "PK, 수정시 필요.",
  serviceId: "서비스ID",
  mediaServiceType: "서비스타입",
  category1: "카테고리 1",
  description: "이미지 설명",
  url: "이미지 전체 URL",
  fileStorageType: "파일 저장소 타입",
  key: "물리 파일 키",
  filename: "저장 파일 이름",
  contentType: "저장 파일 타입",
  fileSize: "저장 파일 사이즈",
  isPrimary: "대표미디어여부",
  orderNums: "정렬순서",
  cmdType: "요청타입",
} as const;

export const MetaItemDataMap = {
  id: "메타아이템 id",
  metaGroup: "그룹타입",
  metaItem: "아이템타입",
  orderNums: "정렬순서",
  value1: "기본값1",
  value2: "기본값2",
  value3: "기본값3",
} as const;

export const ProductBuildingDataMap = {
  id: "건물_ID",
  productBuildingFloorList: "건물 층",
  productBuildingCommonFacility: "공용공간",
  isDeleted: "삭제여부",
} as const;

export const ProductBuildingFloorDataMap = {
  id: "건물_층_ID",
  productBuildingRoomList: "건물 호실",
} as const;

export const ProductBuildingRoomDataMap = {
  id: "건물_호실_ID",
  isPrimary: "대표 건물/호실 여부",
} as const;

export const PrinterDataMap = {
  isPrinterProvided: "복합기 제공여부",
  printerDescription: "복합기 추가 설명",
  printerOptionList: "복합기 옵션",
} as const;

export const FreeInternetDataMap = {
  isFreeInternetProvided: "무료인터넷 제공여부",
  freeInternetDescription: "무료인터넷 추가 설명",
  freeInternetOptionList: "무료인터넷 옵션",
} as const;

export const AirConditionerDataMap = {
  isAirConditionerProvided: "공조기제공여부",
  airConditionerDescription: "공조기 추가 설명",
  airConditionerInfoList: "공조기 기기정보",
} as const;

export const ProductBuildingCommonFacility = {
  meetingRoomList: "건물 회의실 ID",
  refreshRoomList: "건물 휴공간 ID",
  deskList: "건물 좌석 ID",
};

export const ProductAccessGroup = {
  id: "상품 출입그룹 ID",
  productId: "상품 ID",
  accessGroupId: "접근그룹 ID",
  createdDate: "생성일",
  createBy: "생성자",
  modifiedDate: "수정일",
  modifiedBy: "수정자",
};

export const KeyNamesMap = {
  // Address 관련 키
  ...AddressDataMap,
  // Building 관련 키
  ...BuildingDataMap,
  // BuildingFloor 관련 키
  ...BuildingFloorDataMap,
  // BuildingRoom 관련 키
  ...BuildingRoomDataMap,
  // BuildingParking 관련 키
  ...BuildingParkingDataMap,
  // BuildingCommonFacility 관련 키
  ...BuildingCommonFacilityDataMap,
  // BuildingCommonFacilityDeskGroup 관련 키
  ...BuildingCommonFacilityDeskGroupDataMap,

  // Product 관련 키
  ...ProductDataMap,

  // CommonFacility 관련 키
  ...CommonFacilityDataMap,

  // MediaFile 관련 키
  ...MediaFileDataMap,

  // MetaItem 관련 키
  ...MetaItemDataMap,

  // ProductBuilding 관련 키
  ...ProductBuildingDataMap,

  // ProductBuildingFloor 관련 키
  ...ProductBuildingFloorDataMap,

  // ProductBuildingRoom 관련 키
  ...ProductBuildingRoomDataMap,

  // Printer 관련 키
  ...PrinterDataMap,

  // FreeInternet 관련 키
  ...FreeInternetDataMap,

  // AirConditioner 관련 키
  ...AirConditionerDataMap,

  //ProductBuildingCommonFacility 공용공간
  ...ProductBuildingCommonFacility,
  //ProductAccessGroup 상품 엑세스 그룹
  ...ProductAccessGroup,
} as const;

export type KeyNamesMapKey = keyof typeof KeyNamesMap;
