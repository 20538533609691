import { atom, selector } from "recoil";

export const modalSelectItem = atom<any>({
  key: "modalSelectItem",
  default: [],
});

export const modalSelectedOptions = selector<any>({
  key: "modalSelectedOptions",
  get: ({ get }) => {
    const values = get(modalSelectItem);

    const filter = values.filter((item: any, idx: number, self: any) => {
      return idx === self.findIndex((obj: any) => obj.id === item.id);
    });

    return filter;
  },
  // set: ({ set }, newValue) => {
  //   set(modalSelectedOptions, (item: any) => {
  //     console.log(`item`, item);
  //     console.log(`newValue`, newValue);
  //   });
  // },
});
