import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { BuildingParkingLotModel } from "src/api/building/building-types";
import { BaseButton, BaseInput, BaseSelect, BaseTextarea } from "src/components";
import { BaseAbstractModal } from "src/components/BaseAbstractModal";
import { parkingLotNumOptions } from "src/pages/building/building-types";

type Props = {
  parkingLots: BuildingParkingLotModel[];
  fetchBuilding: (buildingId: number) => void;
  onClose: () => void;
  onSaveParking: (data: BuildingParkingLotModel) => void;
};

const ParkingFormModal = ({ parkingLots, onClose, onSaveParking }: Props) => {
  // useForm 초기값 설정

  const { control, handleSubmit, getValues, setValue, reset } = useForm<BuildingParkingLotModel>({
    defaultValues: {
      selfPropelledParkingLotNums: 0,
      selfPropelledParkingLotTotalNums: 0,
      mechanicalParkingLotNums: 0,
      mechanicalParkingLotTotalNums: 0,
      description: "",
    },
  });

  // 모달이 열릴 때마다 폼 데이터 초기화
  useEffect(() => {
    if (parkingLots.length > 0) {
      const parkingObj = parkingLots[0];
      Object.entries(parkingObj).forEach(([name, value]: any) => {
        setValue(name, value);
      });
    } else {
      reset();
      // reset(defaultValues);
    }
  }, [parkingLots, setValue, reset]);

  // 폼 제출
  const onSubmit = useCallback(
    (data: BuildingParkingLotModel, e?: any) => {
      e?.preventDefault();
      onSaveParking(data);
    },
    [onSaveParking],
  );

  return (
    <BaseAbstractModal isOpen>
      <form onSubmit={handleSubmit(onSubmit)} className="pb10">
        <section className="base-abstract-modal__contents">
          <section className="base-abstract-modal__title">
            <h1>주차 설정</h1>
          </section>
          <div className="contents-container__wrap px30">
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>자주식</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* 자주식 주차 입력 폼 */}
                <Controller
                  control={control}
                  name="selfPropelledParkingLotNums"
                  render={({ field: { onChange, value, name } }) => (
                    <div className="flex-row flex-center-start">
                      <BaseSelect
                        placeholder="설비 수량"
                        className="mr10 minmax240"
                        value={String(value)}
                        name={name}
                        stateOptions={parkingLotNumOptions}
                        setStateValue={(v: string) => onChange(v ? parseInt(v) : null)}
                      />
                      <Controller
                        control={control}
                        name="selfPropelledParkingLotTotalNums"
                        render={({ field: { onChange, value, name } }) => (
                          <BaseInput
                            type="number"
                            placeholder="주차 대수"
                            onChange={(v: string) => onChange(v ? parseInt(v) : null)}
                            value={value}
                            name={name}
                            className="minmax240"
                            readonly={Number(getValues("selfPropelledParkingLotNums") || 0) === 0}
                          />
                        )}
                      />
                    </div>
                  )}
                />
              </div>
            </section>
            {/* 기계식 주차 입력 폼 */}

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>기계식</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* 자주식 주차 입력 폼 */}
                <Controller
                  control={control}
                  name="mechanicalParkingLotNums"
                  render={({ field: { onChange, value, name } }) => (
                    <div className="flex-row flex-center-start">
                      <BaseSelect
                        placeholder="설비 수량"
                        className="mr10 minmax240"
                        value={String(value)}
                        name={name}
                        stateOptions={parkingLotNumOptions}
                        setStateValue={(v: string) => onChange(v ? parseInt(v) : null)}
                      />
                      <Controller
                        control={control}
                        name="mechanicalParkingLotTotalNums"
                        render={({ field: { onChange, value, name } }) => (
                          <BaseInput
                            className="minmax240"
                            type="number"
                            placeholder="주차 대수"
                            onChange={(v: string) => onChange(v ? parseInt(v) : null)}
                            value={value}
                            name={name}
                            readonly={Number(getValues("mechanicalParkingLotNums") || 0) === 0}
                          />
                        )}
                      />
                    </div>
                  )}
                />
              </div>
            </section>

            {/* 설명 입력 폼 */}
            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>설명</p>
              </div>
              <div className="contents-container__grid-contents">
                {/* 자주식 주차 입력 폼 */}
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value, name } }) => (
                    <BaseTextarea
                      placeholder={`규정 정보, 사용조건 및 비용정보를 입력해주세요\n예시)\n무료주차 : 임대 50py 당 1대\n유료주차 : 월 150,000원(vat 별도)`}
                      className="w-100"
                      onChange={onChange}
                      maxLength={1000}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </div>
            </section>
          </div>

          {/* 버튼 영역 */}
          <div className="contents-container__btn-wrap">
            <div className="w-100 d-flex justify-contents-end">
              <BaseButton className="color-white mr10" title="취소" onClick={onClose} />
              <BaseButton type="submit" title="저장" />
            </div>
          </div>
        </section>
      </form>
    </BaseAbstractModal>
  );
};

export default ParkingFormModal;
