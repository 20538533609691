import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../public-types";
import { CctvDevice, CctvDeviceQueryParams, CctvDevicePartner } from "./cctv-types";
import qs from "qs";
import { ApiPath } from "..";

export async function getCctvDeviceListAsync(
  axios: AxiosInstance,
  params?: CctvDeviceQueryParams,
): Promise<AxiosResponse<ApiResponse<{ content: CctvDevice[] }>>> {
  return await axios.get(ApiPath.cctv.list, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true }),
    },
  });
}

export async function updateCctvDeviceAsync(
  axios: AxiosInstance,
  data: CctvDevicePartner,
): Promise<AxiosResponse<ApiResponse<{ content: CctvDevice }>>> {
  const { cctvDeviceId, id, ...rest } = data;

  return await axios.post(ApiPath.cctv.update.replace("{id}", String(cctvDeviceId)).replace("{cctvDevicePartnerId}", String(id)), {
    cctvDevicePartner: rest,
  });
}
