import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { getBuildingsAsync } from "src/api/building/building-api";
import { BuildingListParams, BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import useNavigate from "src/hooks/usePartnerNavigate";
import { PagePath } from "src/pages/product/details";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { qsParse } from "src/utils";
import { buildingColumns } from "./columns";

// 공개 상태
const searchStatuses = [
  { value: "", label: "전체" },
  { value: "ENABLED", label: "공개" },
  { value: "DISABLED", label: "비공개" },
];

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  status?: string;
  searchTarget?: string;
  keyword?: string;
  isDeleted?: string;
  sort?: {
    orders?: Array<Order>;
  };
};

/* 
  건물 > 목록 화면
 */
const BuildingList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qsParse(location.search);
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (_queryParams.isDeleted !== "true" && _queryParams.isDeleted !== "false") {
      _queryParams.isDeleted = "false";
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }
    if (_queryParams?.keyword) {
      _queryParams.keyword = decodeURIComponent(_queryParams.keyword);
    }
    return _queryParams;
  }, [location]);

  // 건물 목록 조회
  const [buildings, setBuildings] = useState<BuildingModel[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [pageNum, setPageNum] = useState(0);

  const { isAuthority } = usePartnerAuthority();

  const { executeAsync: getBuildings } = useApiOperation(getBuildingsAsync);
  useEffect(() => {
    setParams({ ...queryParams });
    async function fetchBuildings(buildingListParams: BuildingListParams) {
      //

      const { data } = await getBuildings(buildingListParams);
      if (data.data) {
        setBuildings(data?.data?.content || []);
        setPageMeta(data?.meta?.pageMeta);
      }

      //
    }

    // 건물 목록 조회후 데이터 바인딩
    fetchBuildings(queryParams);
  }, [queryParams, getBuildings, location.search]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  // 쿼리파라미터 정보와 함께 네비게이션
  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams) => {
      const newQueryParams = { ...params, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      setPageNum(Number(newQueryParams.page));
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, params],
  );

  return (
    <div className="page-product-list">
      <div className="contents-container__table">
        {/* <h1>{i18n.t("product")}</h1> */}
        <div className="contents-container__search-wrap">
          <div className="left-area">
            <section>
              <div className="left-area__index">
                <span>조건검색</span>
              </div>
              <div className="left-area__contents">
                <div className="minmax120">
                  <BaseSelect
                    placeholder="공개 상태"
                    value={params?.status}
                    stateOptions={searchStatuses}
                    setStateValue={(status: string) => {
                      setParams({ ...params, status });
                      navigateWithQueryParams({ ...params, page: 0, status });
                    }}
                  />
                </div>
                <div>
                  <BaseInput
                    value={params?.keyword || ""}
                    className="mr8"
                    type="text"
                    placeholder="건물명을 입력해주세요"
                    onChange={(keyword: string) => setParams({ ...params, keyword })}
                    onKeyUp={() => navigateWithQueryParams({ page: 0 })}
                    onSearchClick={() => navigateWithQueryParams({ page: 0 })}
                    onClearClick={() => {
                      const editParams = { ...params };
                      delete editParams.keyword;
                      // setParams(editParams);
                      console.log(`params`, params);
                      navigateWithQueryParams({ ...params, page: 0, keyword: "" });
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
          {isAuthority("w") && (
            <div className="right-area">
              <BaseButton title="+ 건물 등록" onClick={() => navigate(PagePath.building.form)} />
            </div>
          )}
        </div>
        {buildings && (
          <BaseTable
            data={buildings}
            columns={buildingColumns}
            currentSize={Number(queryParams.size) || 20}
            sizeOption={(sizeValue) => {
              navigateWithQueryParams({ ...params, size: sizeValue, page: 0 });
            }}
            // pageIndex={Number(pageNum || 0)}
            pageIndex={pageMeta?.pageRequest.page || 0}
            totalPages={pageMeta?.totalPages || 0}
            totalElements={pageMeta?.totalElements}
            goPage={(page: number) => {
              navigateWithQueryParams({ page });
            }}
            orders={queryParams?.sort?.orders}
            disabledSortHeaders={["size"]}
            setOrders={(orders?: Array<Order>) => {
              if (orders) {
                navigateWithQueryParams({ sort: { orders } });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default BuildingList;
