import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import { ContractDetailBasicInfo, ContractFacility, ContractOperationDetail, CtProductFacilityType, ProductManage } from "./contract-types";
import { BuildingManageModel, CommonFacilityListModel, CommonFacilityListParams } from "../building/building-types";

/**
 * 기본정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractDetailSync = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailBasicInfo>>> => {
  const path = ApiPath.contract.detail.basicInfo.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<ContractDetailBasicInfo>>(path, { params });
};

/**
 * RCA31. 계약 - 운영정보 상세 조회(공용공간 전체)
 * @param axios
 * @param params
 * @returns
 */
export const getContractOperationDetailSync = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<
  AxiosResponse<
    ApiResponse<{
      content: ContractOperationDetail;
    }>
  >
> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.contractId));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * RCA34. 계약 - 운영정보 공용시설 조회(공용공간 개별)
 */
export const getContractFacilityListSync = (
  axios: AxiosInstance,
  body?: {
    contractId: string;
  },
): Promise<
  AxiosResponse<
    ApiResponse<{
      content?: { ctProductFacility: CtProductFacilityType[] };
    }>
  >
> => {
  let path = ApiPath.contract.detail.findContractFacilityList.replace("{contractId}", String(body!.contractId));

  return axios.get<ApiResponse<any>>(path);
};

/**RCN20.건물 운영정보 조회 (신청계약 공용공간) */
export async function getManageBuildingAsync(
  axios: AxiosInstance,
  params?: { contractManageId: string },
): Promise<AxiosResponse<ApiResponse<{ buildingManageList: BuildingManageModel[] }>>> {
  const path = ApiPath.contractBuilding.manageBuilding.replace("{contractManageId}", String(params?.contractManageId));
  return await axios.get(path, {
    params,
  });
}

/* 
  RPB20. 건물 공용공간 상세 조회 목록(신청계약 공용공간)
*/
export async function getCommonFacilityListAsync(
  axios: AxiosInstance,
  params?: CommonFacilityListParams,
): Promise<AxiosResponse<ApiResponse<{ content: CommonFacilityListModel[] }>>> {
  const path = ApiPath.building.commonfacilityList;
  return await axios.get<ApiResponse<{ content: CommonFacilityListModel[] }>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
}

/** RCA33b. 계약 - 운영정보 공용시설 삭제 */
export const deleteOperationFacilityAsync = (
  axios: AxiosInstance,
  params?: {
    contractId: string | number;
    facilityId: string | number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.RCA33b_DeleteOperationFacility.replace("{contractId}", String(params!.contractId)).replace(
    "{facilityId}",
    String(params!.facilityId),
  );

  return axios.patch<ApiResponse<any>>(path);
};
/**RCA33. 계약 - 운영정보 수정 */
const saveContractOperationAsync = (
  axios: AxiosInstance,
  params?: {
    id: number;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.id));

  return axios.patch<ApiResponse<any>>(path, params?.body);
};

/**RCA32. 계약 - 운영정보 수정 */
export const editContractOperationAsync = (
  axios: AxiosInstance,
  params?: {
    contractId: string;
    productManage?: ProductManage;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.contractId));
  return axios.post<ApiResponse<any>>(path, { productManage: params?.productManage! });
};

/**RCA37. 계약 - 운영정보 공용시설 등록/수정
 * request body
 * { productManage:{}, productFacility: [{}] }
 */

export const postOperationFacilityAsync = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
    productFacilityList: ContractFacility[];
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.findContractFacilityList.replace("{contractId}", String(params?.contractId));

  return axios.post<ApiResponse<any>>(path, { productFacility: params?.productFacilityList });
};
/** 공용공간 월 사용량 조회 */
// RCA92_GetUsageFacilities;
export const getFacilityUsageAsync = (
  axios: AxiosInstance,
  params?: {
    id: number;
    facilityType: string;
    yearMonth: Array<string>;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.RCA92_GetUsageFacilities.replace("{contractId}", String(params!.id));

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params: any) => qs.stringify(params, { allowDots: true, encode: true, arrayFormat: "repeat" }),
    },
  });
};

/**
 * RIR01. 요금 룰 목록
 * GET
 * providerId, buildingCommonFacilityId
 * getBaseFacilityPricies_RIR01
 * @param axios
 * @param body
 * @returns
 */
export const getBaseFacilityPriciesAsync = (
  axios: AxiosInstance,
  params?: {
    providerId: string;
    // buildingCommonFacilityId: string;
    page: number;
    size: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.getBaseFacilityPricies_RIR01;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};

/**
 * RIM02. 건물-공용공간 요금 룰 등록/수정/삭제
 * POST
 * * serviceId = contractId, serviceType = 'SERVICE_CONTRACT, buildingCommonFacilityPriceRuleList cmdType: C, U, D
 * @param axios
 * @param body
 * @returns
 */
// getFacilityPricieSetting_RIM01
export const setFacilityPriceSettingAsync = (
  axios: AxiosInstance,
  params?: {
    buildingCommonFacilityPriceRuleList: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.setFacilityPriceSetting_RIM02;
  return axios.post<ApiResponse<any>>(path, { buildingCommonFacilityPriceRuleList: params?.buildingCommonFacilityPriceRuleList! });
};
/**
 * RIM01. 건물-공용공간 요금 룰 목록
 * GET
 * serviceId = contractId, serviceType = 'SERVICE_CONTRACT
 * @param axios
 * @param body
 * @returns
 */
export const getFacilityPricieSettingAsync = (
  axios: AxiosInstance,
  params?: {
    serviceId: string;
    serviceType: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.getFacilityPricieSetting_RIM01;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { allowDots: true, encode: true }),
    },
  });
};
