import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getBuildingAsync } from "src/api/building/building-api";
import { getCctvDeviceListAsync } from "src/api/cctv/cctv-api";
import { useApiOperation } from "src/api/hooks";
import { BaseButton } from "src/components";
import AdminMemoV3 from "src/components/AdminMemoV3/AdminMemoV3";
import GoToListButton from "src/components/GoToListButton";
import BaseSectionTitle from "src/components/layout/BaseSectionTitle";
import MetaTag from "src/components/layout/MetaTag";
import RegistrationInformation from "src/components/RegistrationInformation";
import { usePartnerAuthority } from "src/hooks/usePartnerAuthority";
import { useModal } from "src/recoil/modalState/hook";
import { axiosInstance, commaizePrice } from "src/utils";
import SettingModal from "./SettingModal";
import { CctvStreamingDataList } from "src/api/cctv/cctv-types";
import BaseNewTabLink from "src/components/BaseNewTabLink";

const BasicInfoDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { isAuthority } = usePartnerAuthority();
  const { executeAsync: getCctvDeviceList } = useApiOperation(getCctvDeviceListAsync);
  const { setAbstractModalZ1 } = useModal();

  const { data: cctvDevice } = useQuery({
    queryKey: ["cctvDevice", id],
    queryFn: () => getCctvDeviceList({ id: id! }).then((res) => res?.data?.data?.content?.[0]),
    enabled: !!id,
  });

  const { data: building } = useQuery({
    queryKey: ["buildingDetail", String(cctvDevice?.cctvDevicePartnerList?.[0]?.buildingId)],
    queryFn: () =>
      getBuildingAsync(axiosInstance, { buildingId: Number(cctvDevice?.cctvDevicePartnerList?.[0]?.buildingId) }).then(
        (res) => res?.data?.data?.content.building,
      ),
    enabled: !!cctvDevice?.cctvDevicePartnerList?.[0]?.buildingId,
  });

  const buildingFloor = building?.buildingFloorList?.find(
    (data) => Number(data.id) === Number(cctvDevice?.cctvDevicePartnerList?.[0]?.buildingFloorId),
  );

  const streamingData = CctvStreamingDataList.find((data) => data.cameraGroup === cctvDevice?.cameraGroup)?.cameraList.find(
    (camera) => camera.cameraId === cctvDevice?.cameraId,
  );

  return (
    <>
      <MetaTag subTitle={cctvDevice?.name || ""} />
      <div className="contents-container__scroll">
        <div className="contents-container__wrap price-page">
          <article className="contents-container__wrap-article">
            <BaseSectionTitle title="기본 정보" />

            <div className="d-flex flex-row">
              <section className="contents-container__grid flex1 mr20">
                <div className="contents-container__grid-index">
                  <p>Camera Group</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{cctvDevice?.cameraGroup || "-"}</span>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid flex1">
                <div className="contents-container__grid-index">
                  <p>Camera ID</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{cctvDevice?.cameraId || "-"}</span>
                  </div>
                </div>
              </section>
            </div>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>장치명</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  {streamingData?.streamingUrl ? (
                    <div>
                      <BaseNewTabLink value={cctvDevice?.name || "-"} path={streamingData?.streamingUrl || ""} />
                    </div>
                  ) : (
                    <span>{cctvDevice?.name || "-"}</span>
                  )}
                </div>
              </div>
            </section>
          </article>
          <article className="contents-container__wrap-article">
            <div className="flex-center-between">
              <BaseSectionTitle title="설정" />
              {isAuthority("w") && (
                <>
                  <div className="my10 flex-center">
                    <BaseButton
                      title="수정"
                      className="size-small"
                      onClick={() => {
                        setAbstractModalZ1({ isOpen: true, size: "medium", children: <SettingModal cctvDevice={cctvDevice} /> });
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="d-flex flex-row">
              <section className="contents-container__grid flex1 mr20">
                <div className="contents-container__grid-index">
                  <p>건물명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{building?.buildingName || "-"}</span>
                  </div>
                </div>
              </section>

              <section className="contents-container__grid flex1">
                <div className="contents-container__grid-index">
                  <p>층</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <span>{buildingFloor?.floorNum ? `${buildingFloor.floorNum > 0 ? "지상" : "지하"} ${buildingFloor.floorNum}층` : "-"}</span>
                  </div>
                </div>
              </section>
            </div>

            <section className="contents-container__grid">
              <div className="contents-container__grid-index">
                <p>혼잡도 CAPA</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="minmax400">
                  <span>
                    {cctvDevice?.cctvDevicePartnerList?.[0]?.congestionCapacity
                      ? commaizePrice(cctvDevice?.cctvDevicePartnerList?.[0]?.congestionCapacity)
                      : "-"}
                  </span>
                </div>
              </div>
            </section>
          </article>
          <RegistrationInformation data={cctvDevice} />

          <AdminMemoV3 serviceId={Number(id)} serviceType={"SERVICE_CCTV_DEVICE"} />
        </div>
      </div>

      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <GoToListButton />
        </div>
      </div>
    </>
  );
};

export default BasicInfoDetail;
