import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { BuildingModel } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { createProductAsync, productStatusAsync, updateProductAsync } from "src/api/product/product-api";
import { ProductAddModel, ProductEditModel, ProductModel } from "src/api/product/product-types";
import { BaseButton, BaseInput, BaseModal, BaseRadio } from "src/components";

import useNavigate from "src/hooks/usePartnerNavigate";
import PagePath from "src/pagePath.json";
import { useToast } from "src/recoil/toast/hook";

import { objectKeys } from "@toss/utils";
import { getProviderList } from "src/api/provider/provider-api";
import { isString } from "src/utils";
import { getProductDetailPath } from "src/utils/route-util";
import BuildingFloorHoModal from "../../components/BuildingFloorHoModal";
import ProductBuildingListModal from "../../components/ProductBuildingListModal";
import BuildingAndHoTable from "../../modalPopup/table/BuildingAndHoTable";
import { BuildingFloorAndHo, Modal, ProductStatusLabels, ProductTypeLabels, ProductTypes } from "../../product-types";
import ProvidersModal from "../productInfoForm/ProvidersModal";
import { changeBuildings, getErrorData, getProviderName, isPrimaryBuildingChecked, makeBuilding, onDrawViewTable, ProductErrorDataMap } from "./libs";
import { useErrorModal } from "src/recoil/errorModal/hook";
import DetailLink from "src/components/DetailLink";
import Link from "src/components/PartnerLink";
import { globalPartnerState } from "src/recoil/partners/atom";
import { useRecoilValue } from "recoil";
import { useModal } from "src/recoil/modalState/hook";
import { isObject } from "lodash";

export type ProductFormData = {
  providerId: ProductAddModel["providerId"];
  productName: ProductAddModel["productName"];
  introduce: ProductAddModel["introduce"];
  buildingList: Array<BuildingFloorAndHo>;
  productType: ProductAddModel["productType"];
  status?: ProductAddModel["status"];
};

interface IProps {
  product?: ProductModel;
  productId?: string;
}

/* 
  공간상품 등록 > 등록 or 수정 > 기본 정보
 */
const BasicInfoForm = ({ product: productDetail, productId }: IProps) => {
  const { openToast } = useToast();
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const { openErrorModal } = useErrorModal();
  const { setBaseModal } = useModal();
  const partner = useRecoilValue(globalPartnerState);
  /** 파트너의 S2 타입 유형 활성화 여부  */
  const isActiveTimeCourt = partner?.features?.filter((PaPartner) => PaPartner.name === "CTRLROOM_PRODUCT_S2")?.[0]?.active;
  /** 파트너의 공개/비공개/링크공개 라디오 버튼 활성화 여부  */
  const isActiveProductPublic = partner?.features?.filter((PaPartner) => PaPartner.name === "CTRLROOM_PRODUCT_PUBLIC")?.[0]?.active;

  const isEdit = isString(productId);

  const [selectedBuilding, setSelectedBuilding] = useState<BuildingModel | null>(null); // 모달에서 선택한 빌딩
  const [selectedRow, setSelectedRow] = useState({});
  const [buildingAndHo, setBuildingAndHo] = useState<Array<BuildingFloorAndHo>>([]); // 테이블용
  const [submitData, setSubmitData] = useState<ProductFormData>();

  // 모달 useState
  const [isOpenProvidersModal, setIsOpenProvidersModal] = useState(false);
  const [buildingModal, setBuildingModal] = useState<Modal>({ isOpen: false }); //빌딩 팝업 모달
  const [floorHoModal, setFloorHoModal] = useState<Modal>({ isOpen: false });
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  const [cancelModal, setCancelModal] = useState<Modal>({
    isOpen: false,
  });
  const [productStatusModal, setProductStatusModal] = useState<Modal>({
    isOpen: false,
  });

  const [selectedProvider, setSelectedProvider] = useState<{
    providerId: string;
    uid: string;
    providerName: string;
  }>();

  // 공간상품 등록 Api
  const { executeAsync: createProduct } = useApiOperation(createProductAsync);
  //
  // 공간상품 수정 Api

  const { executeAsync: updateProduct } = useApiOperation(updateProductAsync);

  //  프로바이더 목록 api
  const { executeAsync: getProviers } = useApiOperation(getProviderList);

  // 공개 가능 여부 검사

  const { executeAsync: getProductStatus } = useApiOperation(productStatusAsync, { noHandleError: true });

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors, defaultValues, dirtyFields, isDirty },
  } = useForm<ProductFormData>({
    defaultValues: {
      providerId: "",
      productType: "FULL_COURT",
      productName: "",
      introduce: "",
      buildingList: [],
      status: "DISABLED",
    },
    mode: "onChange",
  });

  /** 수정모드에서 디폴트 벨류 초기화, 기본 정보 셋팅 */
  useEffect(() => {
    if (isEdit && productDetail?.buildingList) {
      const newBuilding = makeBuilding(productDetail["buildingList"]);

      reset({
        providerId: productDetail?.providerId ?? "",
        productType: productDetail?.productType ?? "FULL_COURT",
        productName: productDetail?.productName ?? "",
        introduce: productDetail?.introduce ?? "",
        buildingList: productDetail?.buildingList ? newBuilding : [],
        status: (productDetail?.status as ProductAddModel["status"]) ?? "DISABLED",
      });

      setBuildingAndHo(newBuilding);
      isPrimaryBuildingChecked(newBuilding, setSelectedRow, productId);
    }
  }, [productDetail, isEdit]);

  /** 수정모드에서 프로바이더명 조회 후 setState */
  useEffect(() => {
    if (isEdit && productDetail?.providerId) {
      getProviderName(productDetail.providerId, getProviers).then((data) => setSelectedProvider(data));
    }
  }, [productDetail, isEdit]);

  //  유효성 검사
  const formValidation = useCallback(() => {
    const requiredMessage = "필수입력 항목입니다";
    register("providerId", {
      required: { value: true, message: requiredMessage },
    });
    register("productType", {
      required: { value: true, message: requiredMessage },
    });
    register("productName", {
      required: { value: true, message: requiredMessage },
      maxLength: { value: 30, message: "30자 이상 입력 불가능 합니다." },
    });

    register("introduce", {
      required: { value: true, message: requiredMessage },
      maxLength: { value: 100, message: "30자 이상 입력 불가능 합니다." },
    });
    register("buildingList", {
      required: { value: true, message: requiredMessage },
    });
    register("status", {
      validate: async (value) => {
        if (value && value !== "DISABLED" && isEdit) {
          const response = await getProductStatus({ productId: +productId, status: value });
          console.log("response :>> ", response);

          // 상태 변경조건
          if (response.status !== 200) {
            setValue("status", "DISABLED");
          }

          if (response.data.meta.errorCode === "ePR0511") {
            try {
              const errorData = isObject(response.data.meta.errorData) ? response.data.meta.errorData : JSON.parse(response.data.meta.errorData);

              if (errorData.find((val: string) => val === "mediaList")) {
                setBaseModal({
                  isOpen: true,
                  title: `공간상품 / 사무공간 이미지를 등록해 주세요`,
                  children: `최소 1장의 이미지를 등록해 주세요`,
                  btnRightTitle: "확인",
                });

                return true;
              }

              if (errorData.find((val: string) => val === "isBuildingCommonFacilityToggleActive")) {
                setBaseModal({
                  isOpen: true,
                  title: `공용공간이 비활성화 상태입니다`,
                  children: ` 공용공간을 사용하려면 각 항목의 토글을 활성화해 주세요`,
                  btnRightTitle: "확인",
                });
                return true;
              }

              if (
                errorData.find((val: string) => val === "meetingRoomList") ||
                errorData.find((val: string) => val === "deskList") ||
                errorData.find((val: string) => val === "refreshRoomList")
              ) {
                let spaceTypeArr: string[] = [];
                errorData.forEach((element: string) => {
                  if (element === "meetingRoomList") {
                    spaceTypeArr.push("회의실");
                  }
                  if (element === "deskList") {
                    spaceTypeArr.push("좌석");
                  }
                  if (element === "refreshRoomList") {
                    spaceTypeArr.push("편의시설");
                  }
                });

                const spaceTypeArrText = spaceTypeArr.join("/");

                setBaseModal({
                  isOpen: true,
                  title: `${spaceTypeArrText}이 아직 등록되지 않았습니다`,
                  children: `새로운 ${spaceTypeArrText}을 추가해 주세요`,
                  btnRightTitle: "확인",
                });
                return true;
              }

              //필수 파라미터 누락
              setBaseModal({
                isOpen: true,
                title: `필수 항목을 입력해 주세요`,
                btnRightTitle: "확인",
                children: (
                  <ul className="base-list">
                    {errorData.map((data: keyof typeof ProductErrorDataMap) => (
                      <li className="font14">{getErrorData(data)}</li>
                    ))}
                  </ul>
                ),
              });
            } catch (error) {
              //필수 파라미터 누락
              setBaseModal({
                isOpen: true,
                title: `필수 항목을 입력해 주세요`,
                btnRightTitle: "확인",
              });
            }
          }

          if (response.data.meta.errorCode === "ePR0518") {
            //상품에 등록된 건물 중 활성화가 되지 않은 건물이 있을 경우
            setBaseModal({
              isOpen: true,
              title: `건물을 공개 상태로 변경해 주세요`,
              children: "건물 > 기본 정보를 확인해 주세요",
              btnRightTitle: "확인",
            });
          }

          if (response.data.meta.errorCode === "ePR0561") {
            setBaseModal({
              isOpen: true,
              title: `공용공간에 적용된 요금이 없습니다`,
              btnRightTitle: "확인",
              children: "공용공간 요금 정책 > 기본 요금을 등록해 주세요",
            });
          }

          if (response.data.meta.errorCode === "ePR0560") {
            setBaseModal({
              isOpen: true,
              title: `페이레터를 연동해 주세요`,
              btnRightTitle: "확인",
              children: "정산 정보 > 연동 정보를 확인해 주세요",
            });
          }

          if (response.data.meta.errorCode === "ePR0559") {
            setBaseModal({
              isOpen: true,
              title: `CS 담당자를 등록해 주세요`,
              btnRightTitle: "확인",
              children: "정산 정보 > 연락처 정보를 확인해 주세요",
            });
          }

          return true;
        }
      },
    });
  }, [register]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    formValidation();
  }, [formValidation, productDetail]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = useCallback(async (data: ProductFormData, e?: any) => {
    e.preventDefault();

    setSubmitData(data);

    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  }, []);

  // 저장 / 수정
  const onClickConfirmModal = async (data: ProductFormData) => {
    // viewTable 데이터 -> 서버 productBuildingList 용으로 변환
    const productBuildingList = changeBuildings(data.buildingList);

    const basicInfoData: ProductAddModel | ProductEditModel = {
      productName: data.productName,
      introduce: data.introduce,
      providerId: data.providerId,
      productType: data.productType,

      ...(data.productType !== "DIGITAL_PRODUCT" && { productBuildingList: productBuildingList }),
      ...(isEdit && { id: productId }),
      ...(isEdit && { status: data.status }),
    };

    const response = isEdit
      ? await updateProduct({ product: basicInfoData as unknown as ProductEditModel })
      : await createProduct({ product: basicInfoData });

    const result = response.data?.data?.content;
    if (result) {
      openToast({
        content: `정상적으로 ${isEdit ? "수정" : "등록"}되었습니다.`,
      });
      navigate(PagePath.product.detail.replace(":id", `${result}`));
    }
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any) => {
    console.log("errors :>> ", errors);
  };

  return (
    <>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)} id="productForm">
            <div className="contents-container__wrap-contents">
              <div className="contents-container__sub-title">
                <div className="minmax140 pb4">
                  <h2>기본정보</h2>
                </div>
              </div>

              {/* 프로바이더 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">프로바이더</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start">
                    <BaseButton title="선택" className="color-white" onClick={() => setIsOpenProvidersModal(true)} />
                    {selectedProvider?.providerName && (
                      <Link
                        to={PagePath.provider.detail.replace(":id", isEdit ? productDetail?.providerId! : selectedProvider?.providerId!)}
                        target="_blank"
                        className={"text-underline"}
                      >
                        <span className="ml16">{selectedProvider?.providerName}</span>
                        <span className="ic-target-blank"></span>
                      </Link>
                    )}

                    {errors.providerId?.message && <p className="validation-text">{errors.providerId?.message}</p>}
                  </div>
                </div>
              </section>

              {/* 상품 유형 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">상품 유형</p>
                  {errors.productType?.message && <p className="validation-text">{errors.productType?.message}</p>}
                </div>
                <div className="contents-container__grid-contents">
                  <Controller
                    control={control}
                    name="productType"
                    render={({ field }) => {
                      return (
                        <div className="flex-row flex-center-start">
                          {objectKeys(ProductTypeLabels).map((value) => (
                            <BaseRadio
                              key={value}
                              id={value}
                              name={value}
                              label={ProductTypeLabels[value]}
                              onChange={(e) => {
                                if (e) {
                                  setValue("productType", value, { shouldDirty: true });
                                }
                              }}
                              checked={field.value === value}
                              value={value}
                              className="mr16"
                              disabled={
                                isEdit ||
                                value === "DIGITAL_PRODUCT" ||
                                value === "MAINTENANCE_FEE" ||
                                (value === "TIME_COURT" && isActiveTimeCourt === false)
                              }
                            />
                          ))}
                        </div>
                      );
                    }}
                  />
                </div>
              </section>
              {watch("productType") !== ProductTypes.DIGITAL_PRODUCT && (
                <>
                  {/* 건물/호실 */}
                  <section className="contents-container__grid">
                    <div className="contents-container__grid-index">
                      <p className="required">건물/호실</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <BaseButton title="선택" className="color-white" onClick={() => setBuildingModal({ isOpen: true })} />
                    </div>
                  </section>
                  {/*기본정보 페이지 내에 건물/호실 테이블 */}
                  <div className="contents-container__grid-contents">
                    <div>
                      <Controller
                        control={control}
                        name="buildingList"
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                          return (
                            <BuildingAndHoTable
                              viewTable={value || []}
                              setViewTable={onChange}
                              errorText={error?.message}
                              selectedRow={selectedRow}
                              setSelectedRow={setSelectedRow}
                              onSelectedRowsChange={setSelectedRow}
                              setBuildingAndHo={setBuildingAndHo}
                              isPrimaryBuildingChecked={isPrimaryBuildingChecked}
                              setValue={setValue}
                              getValues={getValues}
                            />
                          );
                        }}
                      ></Controller>
                    </div>
                  </div>
                </>
              )}

              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">상품명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="productName"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <BaseInput
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                            placeholder="등록하실 상품명을 30자 이내로 입력해 주세요."
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                </div>
              </div>

              <div className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p className="required">1줄 소개</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="minmax400">
                    <Controller
                      control={control}
                      name="introduce"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <BaseInput
                          className="mr8"
                          type="text"
                          onChange={onChange}
                          value={value}
                          name={name}
                          errorText={error?.message}
                          placeholder="등록하실 상품의 소개를 30자 이내로 입력해 주세요."
                        />
                      )}
                    ></Controller>
                  </div>
                </div>
              </div>
              {isEdit && (
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">공개 여부</p>
                    {errors.productType?.message && <p className="validation-text">{errors.productType?.message}</p>}
                  </div>
                  <div className="contents-container__grid-contents">
                    <Controller
                      control={control}
                      name="status"
                      render={({ field }) => {
                        return (
                          <div className="flex-row flex-center-start">
                            {objectKeys(ProductStatusLabels).map((value) => (
                              <BaseRadio
                                key={value}
                                id={value}
                                name={value}
                                label={ProductStatusLabels[value]}
                                onChange={(e) => {
                                  if (e) {
                                    setValue("status", value, { shouldDirty: true, shouldValidate: true });
                                  }
                                }}
                                checked={field.value === value}
                                value={value}
                                className="mr16"
                                disabled={!isActiveProductPublic}
                              />
                            ))}
                          </div>
                        );
                      }}
                    />
                  </div>
                </section>
              )}
              {/* 상품 유형 */}
            </div>
          </form>

          <>
            {/* 프로바이더 선택 모달 팝업 */}
            {isOpenProvidersModal && (
              <ProvidersModal
                close={(selectedProvider?) => {
                  setIsOpenProvidersModal(false);
                  if (selectedProvider) {
                    setSelectedProvider(selectedProvider);
                    setValue("providerId", selectedProvider.providerId, { shouldDirty: true });
                  }
                }}
                selectedProvider={selectedProvider}
              />
            )}
            {/* 컨펌 모달 */}
            <BaseModal
              isOpen={confirmModal.isOpen}
              btnLeftTitle="취소"
              btnRightTitle="확인"
              onClose={() => setConfirmModal({ isOpen: false })}
              onClick={() => submitData && onClickConfirmModal(submitData)}
              title={confirmModal.message}
            ></BaseModal>

            {/* 공개 여부 모달 */}
            <BaseModal
              isOpen={productStatusModal.isOpen}
              btnRightTitle="확인"
              onClick={() => setProductStatusModal({ isOpen: false })}
              children={productStatusModal.message ? <p>{productStatusModal.message}</p> : null}
              title={productStatusModal.title}
            ></BaseModal>

            {/*  빌딩 선택 팝업 */}
            {buildingModal.isOpen && (
              <ProductBuildingListModal
                setSelectedBuilding={setSelectedBuilding}
                partnerId={partnerId!}
                onClose={() => setCancelModal({ isOpen: true, message: "건물/호실 선택을 취소하시겠습니까?" })}
                onConfirm={() => {
                  if (selectedBuilding?.id) {
                    setFloorHoModal({ isOpen: true });
                  }
                }}
              />
            )}

            {/*건물의  층 / 호실 선택 팝업*/}
            {floorHoModal.isOpen && (
              <BuildingFloorHoModal
                selectedBuildingId={selectedBuilding?.id}
                floorHoTable={getValues("buildingList")} // was checked 확인
                selectOptions={(Propertys: any[]) => {
                  setFloorHoModal({ isOpen: false });
                  setBuildingModal({ isOpen: false });
                  onDrawViewTable(Propertys, setSelectedRow, setValue, selectedBuilding, setBuildingAndHo, buildingAndHo);
                }}
                onClose={() => {
                  setFloorHoModal({ isOpen: false });
                }}
              />
            )}

            {/* 건물 선택 팝업 취소 */}
            {cancelModal.isOpen && (
              <BaseModal
                isOpen={true}
                btnLeftTitle="취소"
                btnRightTitle="확인"
                onClick={() => {
                  setBuildingModal({ isOpen: false });
                  setCancelModal({ isOpen: false });
                }}
                onClose={() => setCancelModal({ isOpen: false })}
                title={cancelModal.message}
              ></BaseModal>
            )}

            {/* 건물 대표여부 체크 경고 */}
            {alertModal.isOpen && (
              <BaseModal
                isOpen={true}
                btnRightTitle="확인"
                onClick={() => {
                  setAlertModal({ isOpen: false });
                }}
              >
                <div>
                  {alertModal.message?.split("\n").map((text) => (
                    <p key={text} className="mb8">
                      {text}
                    </p>
                  ))}
                </div>
              </BaseModal>
            )}
          </>
        </div>
      </div>
      {/* 버튼영역 */}
      <div className="contents-container__btn-wrap">
        <div className="left-area"></div>
        <div className="right-area">
          {isEdit && (
            <BaseButton
              title="수정취소"
              className="mr10 color-white size-large"
              onClick={() => {
                navigate(
                  getProductDetailPath({
                    productId: productId ? String(productId) + "?tab=basicInfo" : "",
                  }),
                );
              }}
            />
          )}
          <BaseButton type="submit" title="저장" className="size-large" form="productForm" />
        </div>
      </div>
    </>
  );
};

export default BasicInfoForm;
