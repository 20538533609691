import { TranslateOptions } from "i18n-js";
import { Scope } from "i18n-js";
import i18n from "src/i18n";
// import i18n from "src/i18n";

export const useI18n = () => {
  const t = (key: Scope, options?: TranslateOptions) => i18n.t(key, options); // t 함수를 정의합니다.

  return { t };
};
