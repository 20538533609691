import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PartnerFeature } from "src/api/public-types";
import { Menu } from "src/components/layout/SideBar";
import { globalPartnerState } from "src/recoil/partners/atom";
import { usePartnerAuthority } from "./usePartnerAuthority";
import useNavigate from "./usePartnerNavigate";

export function useValidRoutes(routeList?: any[] | Menu[]) {
  const location = useLocation();
  const navigate = useNavigate();
  const partner = useRecoilValue(globalPartnerState);

  const query = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true, allowDots: true }), [location]);

  const { partnerPrivileges } = usePartnerAuthority();

  // 메뉴에 영향을 주는 파트너 feature 맵핑
  const useMenuPartnerFeature = {
    WORKORDER: "CTRLROOM_NAVIGATION_WORKORDER", // 워크오더
    CS: "CTRLROOM_NAVIGATION_CS", // CS / VoC 항목 (메뉴), 건물 > VoC 항목
    RESERVATION: "CTRLROOM_NAVIGATION_RESERVATION", // 공용공간 예약 / 공용공간 요금
    MANAGEMENT_FEE: "CTRLROOM_NAVIGATION_MAINTENANCE_FEE", // 관리비-부과항목관리 / 관리비-부과관리
    CCTV: "FEATURE_CCTV", // CCTV
  } as const;

  // routeList / sideBar 노출 관련 목록
  const validRoutes = useMemo(() => {
    // 파트너 featrue 목록에서 컨트롤룸 데이터만 filter
    const partnerFeatures = partner?.features?.filter((item) => {
      const feature = Object.values(useMenuPartnerFeature).find((feature) => feature === item.name);
      return feature ? true : false;
    });

    // routeList에 필터링한 파트너 feature 맵핑
    const addFeaturesActive = routeList?.map((menuItem: any) => {
      if (menuItem.feature) {
        const find = partnerFeatures?.find((paPartner) => paPartner.name === menuItem.feature);
        if (find) {
          menuItem.active = find.active; // find가 true인 경우 active에 find.active 값을 할당
        }
      }
      if (menuItem.twoDepth) {
        menuItem.twoDepth = menuItem.twoDepth.map((subMenuItem: any) => {
          if (subMenuItem.feature) {
            const findSub = partnerFeatures?.find((paPartner) => paPartner.name === subMenuItem.feature);
            if (findSub) {
              subMenuItem.active = findSub.active; // find가 true인 경우 active에 find.active 값을 할당
            }
          }

          return subMenuItem;
        });
      }

      if (menuItem.twoDepth?.every((data: any) => data.active === false)) {
        // twoDepth가 모두 비노출이면 menuItem 비노출
        menuItem.active = false;
      }

      return menuItem;
    });
    // 파트너 feature 가 active인 것만 filter
    const featuresActiceList =
      addFeaturesActive
        ?.filter((item) => item.active !== false)
        .map((item2) => ({ ...item2, twoDepth: item2.twoDepth?.filter((two: any) => two.active !== false) })) || [];

    return featuresActiceList?.map((data, index) => {
      if (index === featuresActiceList.length - 1) {
        // 마지막 menuItem 에 divider는 비노출
        data.divider = false;
      }
      return data;
    });
  }, [partner?.features, partnerPrivileges, routeList]);

  //
  // CS active가 (접근) false 일때, 건물 > voc 항목을 filter하기 위해 boolean 리턴
  const isCsActive = useMemo(() => {
    const csFeature = partner?.features?.find((feature) => feature.name === PartnerFeature.CS);
    return csFeature?.active;
  }, [partner?.features]);

  //isCsActive false 일 때 ,건물 > 상세/수정 > voc 진입시 404 페이지 이동
  const goToPageNotFound = () => {
    if (isCsActive === false && query.tab === "voc") {
      navigate("/error/page-not-found");
    }
  };

  return { validRoutes, isCsActive, goToPageNotFound };
}
